import React, { useState } from 'react';
import { Button } from "../../components/Button";
import { Checkbox } from "../../components/Checkbox";
import { Input } from "../../components/Input";
import { VuesaxBoldCloseCircle } from "../../icons/VuesaxBoldCloseCircle";
import { VuesaxOutlineDocumentUpload } from "../../icons/VuesaxOutlineDocumentUpload";
import "./style.css";

export const DocumentUpload = ({ onClose, onUpload }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [autofill, setAutofill] = useState(false);
  const [error, setError] = useState(null);
  const [description, setDescription] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    validateAndSetFile(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer.files[0];
    validateAndSetFile(file);
  };

  const validateAndSetFile = (file) => {
    const allowedTypes = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf'
    ];

    const maxSize = 20 * 1024 * 1024; // 20 MB

    // Check if file type is allowed
    if (file && !allowedTypes.includes(file.type)) {
    setError(`Invalid file type. Please select a .doc, .docx, or .pdf file.`);
    setSelectedFile(null);
    return;
    }

    // Check if file size exceeds limit
    if (file && file.size > maxSize) {
    setError('File size should not exceed 20MB.');
    setSelectedFile(null);
    return;
    }

    // Valid file
    setSelectedFile(file);
    setError(null);
    };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const triggerFileInput = () => {
    document.getElementById('file-input').click();
  };

  const clearFileSelection = () => {
    setSelectedFile(null);
    setError(null);
  };

  const toggleAutofill = () => {
    setAutofill((prevAutofill) => !prevAutofill);
  };

  const handleSave = () => {
    if (selectedFile) {
      onUpload(selectedFile, autofill, description);
    }
  };

  return (
    <div className="upload-file-base">
      <div className="title-3">
        <div className="text-wrapper-10">Upload file</div>
        <Button
          className="button-2"
          override={<VuesaxBoldCloseCircle className="vuesax-bold-close-circle" color="#7E92A2" />}
          style="white"
          type="icon-only"
          onClick={onClose}
        />
      </div>
      <div
        className={`content-SCROLL ${dragging ? 'dragging' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="frame-2" onClick={triggerFileInput}>
          <VuesaxOutlineDocumentUpload className="vuesax-outline" color="#7E92A2" />
          <p className="upload-CTA-text">
            <span className="span">Drag and drop or </span>
            <span className="text-wrapper-11" style={{ cursor: 'pointer', textDecoration: 'underline' }}>
              Choose file
            </span>
            <span className="span"> to upload </span>
          </p>
          <div className="upload-CTA-text-2">Doc, Docx, or PDF</div>
        </div>

        {selectedFile && (
          <div className="selected-file-info">
            <span className="selected-file-name">{selectedFile.name}</span>
          </div>
        )}

        {error && <div className="error-message">{error}</div>}

        <input
          type="file"
          id="file-input"
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept=".doc,.docx,.pdf"
        />
        <div className="form-2">
          <div className="add-image">
          <Input
              className="design-component-instance-node"
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div className="frame-3">
              <Checkbox
                className="checkbox-instance"
                property1={autofill ? "ticked-box" : "empty-box"}
                onClick={toggleAutofill} // Handle checkbox toggle
              />
              <p className="p">Use file as intake to autofill patient and episode details</p>
            </div>
          </div>
        </div>
      </div>
      <div className="action-wrapper">
        <div className="action-2">
          <Button className="button-3" divClassName="button-4" style="white" text="Cancel" type="default" onClick={onClose} /> {/* Handle close modal */}
          <Button className="button-5" style="primary" text="Save" type="default" onClick={handleSave} />
        </div>
      </div>
    </div>
  );
};
