/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const VuesaxOutlineWarning2 = ({ color = "#292D32", className }) => {
  return (
    <svg
      className={`vuesax-outline-warning-2 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 15 16"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7.5 9.09375C7.24375 9.09375 7.03125 8.88125 7.03125 8.625V5.34375C7.03125 5.0875 7.24375 4.875 7.5 4.875C7.75625 4.875 7.96875 5.0875 7.96875 5.34375V8.625C7.96875 8.88125 7.75625 9.09375 7.5 9.09375Z"
        fill={color}
      />
      <path
        className="path"
        d="M7.5 11.2813C7.33125 11.2813 7.17499 11.2188 7.05624 11.1C6.99999 11.0375 6.95625 10.9688 6.91875 10.8938C6.8875 10.8188 6.875 10.7375 6.875 10.6563C6.875 10.4938 6.94374 10.3312 7.05624 10.2125C7.28749 9.98125 7.71251 9.98125 7.94376 10.2125C8.05626 10.3312 8.125 10.4938 8.125 10.6563C8.125 10.7375 8.10624 10.8188 8.07499 10.8938C8.04374 10.9688 8.00001 11.0375 7.94376 11.1C7.82501 11.2188 7.66875 11.2813 7.5 11.2813Z"
        fill={color}
      />
      <path
        className="path"
        d="M7.50011 14.7187C7.08136 14.7187 6.65636 14.6124 6.28136 14.3937L2.56886 12.2499C1.81886 11.8124 1.3501 11.0062 1.3501 10.1374V5.86245C1.3501 4.9937 1.81886 4.18745 2.56886 3.74995L6.28136 1.6062C7.03136 1.1687 7.96262 1.1687 8.71887 1.6062L12.4314 3.74995C13.1814 4.18745 13.6501 4.9937 13.6501 5.86245V10.1374C13.6501 11.0062 13.1814 11.8124 12.4314 12.2499L8.71887 14.3937C8.34387 14.6124 7.91886 14.7187 7.50011 14.7187ZM7.50011 2.21869C7.24386 2.21869 6.98136 2.28744 6.75011 2.41869L3.03761 4.56244C2.57511 4.83119 2.2876 5.32495 2.2876 5.86245V10.1374C2.2876 10.6687 2.57511 11.1687 3.03761 11.4374L6.75011 13.5812C7.21261 13.8499 7.78762 13.8499 8.24387 13.5812L11.9564 11.4374C12.4189 11.1687 12.7064 10.6749 12.7064 10.1374V5.86245C12.7064 5.3312 12.4189 4.83119 11.9564 4.56244L8.24387 2.41869C8.01887 2.28744 7.75636 2.21869 7.50011 2.21869Z"
        fill={color}
      />
    </svg>
  );
};

VuesaxOutlineWarning2.propTypes = {
  color: PropTypes.string,
};
