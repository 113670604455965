import React, { useState, useEffect } from "react";
import { Outlet, NavLink, useParams } from "react-router-dom";
import { Activity } from "../../components/Activity";
import { Base } from "../../components/Base";
import { Button } from "../../components/Button";
import { DetailPage } from "../../components/DetailPage";
import { DetailPageHeader } from "../../components/DetailPageHeader";
import { SidebarNavigation } from "../../components/SidebarNavigation";
import { TopNavigationBar } from "../../components/TopNavigationBar";
import { ModalAddNewActivity } from "../../components/ModalAddNewActivity";
import { IconAdd } from "../../icons/IconAdd";
import { IconQna } from "../../icons/IconQna";
import { IconOpenNewTab3 } from "../../icons/IconOpenNewTab3";
import { VuesaxOutlineNoteText } from "../../icons/VuesaxOutlineNoteText";
import { VuesaxOutlineWarning2 } from "../../icons/VuesaxOutlineWarning2";
import { NotificationPopUp } from "../../components/NotificationPopUp";
import { formatDateTimeToReadableDateTimeWithoutTime } from "../../Utils/utils";
import { getActivityById, saveActivityById } from "../../services/referralApi";
import "./style.css";

export const ReferralActivity = () => {
  const { referralId } = useParams();
  const [activity, setActivity] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchReferralActivity = async () => {
      try {
        const data = await getActivityById(referralId);
       setActivity(data.activities);

      } catch (error) {
        setNotificationStatus("fail");
        setNotificationMessage("Error fetching activity details");
        setShowNotification(true);
      } finally {
        setLoading(false);
      }
    };
    fetchReferralActivity();
  }, [referralId]);

  const saveActivity = async (newActivity) => {
    try {
     const data = await saveActivityById(referralId, newActivity);
     const updatedData = await getActivityById(referralId);
      setActivity(updatedData.activities);
      setIsModalOpen(false);
      setNotificationStatus("success");
      setNotificationMessage("Successfully saved the activity!");
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage("Error saving activity details");
    } finally {

      setShowNotification(true);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="scrollable-content-history">
    <div className="content-3">
      <div className="left-column-3">
        <div className="activity-4">
          <Button
            className="button-4"
            override={<IconAdd className="icon-add-instance" color="#ECECFE" />}
            style="primary"
            type="icon-only"
            onClick={openModal}
          />
        </div>
        {loading ? (
            <div className="spinner"></div>
          ) : (
            <>
              {activity && activity.length > 0 ? (
                activity.map((a, index) => (
                  <Activity
                    key={index}
                    className="design-component-instance-node-6"
                    event={a.event}
                    name={a.contact_name}
                    description={a.description}
                    date={formatDateTimeToReadableDateTimeWithoutTime(a.event_date)}
                  />
                ))
              ) : (
                <div className="no-activities-found">No activities yet</div>
              )}
            </>
          )}
      </div>
      </div>
      {isModalOpen && (
        <div className="modal-add-new-wrapper">
          <ModalAddNewActivity onClose={closeModal} onSave={saveActivity} />
        </div>
      )}
      {showNotification && (
          <NotificationPopUp
            message={notificationMessage}
            status={notificationStatus}
            iconColor="#2DC8A8"
            closeIconColor="white"
            duration={3000}  // Display for 3 seconds
            onClose={() => setShowNotification(false)}
          />
        )}
    </div>
  );
};
