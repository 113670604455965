/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Base } from "../Base";
import "./style.css";

export const DetailPage = ({
  className,
  emailFieldClassName,
  text,
  text1,
  text2,
  text3,
  text4,
  hasEmailField = true,
  hasDiv = true,
}) => {
  return (
    <div className={`detail-page ${className}`}>
      <div className="div-2">
        {text && <div className={`email-field ${emailFieldClassName}`}>{text}</div>}
        {text1 && <div className="email-field-2">{text1}</div>}
        {text2 && <div className="email-field-2">{text2}</div>}
        {text3 && <div className="email-field-2">{text3}</div>}
        {text4 && <div className="email-field-2">{text4}</div>}
      </div>
      <Base className="base-3" />
    </div>
  );
};

DetailPage.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  hasEmailField: PropTypes.bool,
  hasDiv: PropTypes.bool,
};
