import React, { useState } from "react";
import PropTypes from "prop-types";
import { InputForActivity } from "../../components/InputForActivity";
import "./style.css";

export const RecordActivityArea = ({ className, onSaveActivity, onAskedQuestion }) => {
  const [activeTab, setActiveTab] = useState("notes");
  const [description, setDescription] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const handleSaveClick = () => {
    if (activeTab === "notes" && description.trim() !== "") {
      onSaveActivity({ description });
      setDescription("");
    } else if (activeTab === "qa" && question.trim() !== "") {
      onAskedQuestion({ question }).then((response) => {
        setAnswer(response.answer);
      });
      setQuestion("");
    }
  };

  return (
    <div className={`record-activity-area ${className}`}>
      <div className="record-activity-form">
        <div className="title">
          <div className="text-wrapper-6">Activity Center</div>
        </div>
        <div className="tabs">
          <div
            className={`text-wrapper-7 tab-link ${activeTab === "notes" ? "active" : ""}`}
            onClick={() => setActiveTab("notes")}
          >
            Capture notes
          </div>
          <div
            className={`q-a tab-link ${activeTab === "qa" ? "active" : ""}`}
            onClick={() => setActiveTab("qa")}
          >
            Q&amp;A
          </div>
        </div>
        <div className="form">
          {activeTab === "notes" ? (
            <InputForActivity
              className="input-for-activity-instance"
              text="Capture your notes"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onSubmit={handleSaveClick}
            />
          ) : (
            <InputForActivity
              className="input-for-activity-instance"
              text="Ask a question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              onSubmit={handleSaveClick}
            />
          )}
          {answer && (
            <div className="input-3">
              <p className="p">{answer}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

RecordActivityArea.propTypes = {
  className: PropTypes.string,
  onSaveActivity: PropTypes.func.isRequired,
  onAskedQuestion: PropTypes.func.isRequired,
};
