import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { VuesaxOutlineMore } from "../../icons/VuesaxOutlineMore";
import "./style.css";

export const Contacts = ({
  className,
  name = "",
  dateClassName,
  role = "",
  email = "",
  phoneNumber = "",
  org = "",
  date,
  contactId,
  onArchive,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = (e) => {
    e.preventDefault(); // Prevent link navigation
    e.stopPropagation(); // Stop event from bubbling up to Link
    setIsMenuOpen((prev) => !prev);
  };

  const handleClickOutside = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <Link to={`/contact/${contactId}`} className={`contacts ${className}`}>
      <div className="cells-3">
        <div className="big-space-item">{name}</div>
        <div className="big-space-item">{org}</div>
        <div className="big-space-item">{role}</div>
        <div className="big-space-item">{email}</div>
        <div className="small-space-item">{date}</div>

        {/* Menu button inside cells-3 */}
        <div className="menu-container" ref={menuRef}>
          <button
            className="menu-button"
            onClick={(e) => toggleMenu(e)}
          >
            <VuesaxOutlineMore color="#7E92A2" />
          </button>
          {isMenuOpen && (
            <div className="dropdown-menu">
              <div
                className="menu-item"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default link behavior
                  e.stopPropagation(); // Stop event from bubbling to Link
                  onArchive(contactId); // Call archive function
                  setIsMenuOpen(false); // Close menu
                }}
              >
                Archive Contact
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="border-3" />
    </Link>
  );
};

Contacts.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  dateClassName: PropTypes.string,
  role: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  org: PropTypes.string,
  date: PropTypes.string,
  contactId: PropTypes.string.isRequired,
  onArchive: PropTypes.func.isRequired,
};
