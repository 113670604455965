import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import { VuesaxOutlineElement4 } from "../../icons/VuesaxOutlineElement4";
import { Button } from "../Button";
import "./style.css";

export const SideNavButton = ({
  className,
  buttonIcon,
  text = "Dashboard",
  buttonText,
  dropdown,
  onClick,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    if (dropdown) {
      setIsDropdownOpen((prev) => !prev);
    }
    if (onClick) {
      onClick();
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`side-nav-button ${className}`} ref={dropdownRef}>
      <div className="side-nav-button-inner" onClick={handleToggleDropdown}>
        {buttonIcon && (
          <>
            <Button override={buttonIcon} style="white" type="icon-only" />
            <div className="name">{text}</div>
          </>
        )}
        {buttonText && (
          <Button override={buttonText} style="white" type="icon-only" />
        )}
      </div>
      {isDropdownOpen && dropdown && (
        <div className="side-nav-button-dropdown-menu">
          {dropdown.map((item, index) => (
            <div
              key={index}
              className="side-nav-button-dropdown-item"
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'var(--greygrey-10)')}
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
              onClick={() => {
                item.onClick();
                setIsDropdownOpen(false);
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

SideNavButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  dropdown: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

SideNavButton.defaultProps = {
  dropdown: null,
};
