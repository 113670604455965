import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "../../components/Button";
import { InfoAndFilters } from "../../components/InfoAndFilters";
import { PatientRow } from "../../components/PatientRow";
import { PatientsTable } from "../../components/PatientsTable";
import { SidebarNavigation } from "../../components/SidebarNavigation";
import { TopNavigationBar } from "../../components/TopNavigationBar";
import { Icon16 } from "../../icons/Icon16";
import { VuesaxOutlineElement4 } from "../../icons/VuesaxOutlineElement4";
import { getPatients, searchPatients, savePatient as savePatientApi } from "../../services/patientApi";
import { ModalAddNew } from "../../components/ModalAddNew";
import { NotificationPopUp } from "../../components/NotificationPopUp";
import "./style.css";

export const PatientsList = () => {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPatients();
        if (data.patient) {
          setPatients(data.patient);
        } else {
          setError("No patient data!");
        }
      } catch (error) {
        setNotificationStatus("fail");
        setNotificationMessage(error.message);
        setShowNotification(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSearch = async (searchTerm) => {
    try {
      setLoading(true);
      const data = await searchPatients(searchTerm);
      setPatients(data.patient);
    } catch (error) {
        setNotificationStatus("fail");
        setNotificationMessage(error.message);
        setShowNotification(true);
      } finally {
        setLoading(false);
      }
  };

  const savePatient = async (newPatient) => {
    try {
      const response = await savePatientApi(newPatient);
      setPatients((prevPatients) => [response.patient, ...prevPatients]);
      setIsModalOpen(false);
      setNotificationStatus("success");
      setNotificationMessage("Successfully created!");
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage(error.message);
    }
    finally {
      setShowNotification(true);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="patients-list">
      <TopNavigationBar
        baseBaseClassName="top-navigation-bar-instance"
        buttonIcon={<Icon16 className="vuesax-outline-add" color="#ECECFE" />}
        buttonText="Add Patient"
        className="top-navigation-bar-v3"
        hasDiv
        text="Patients"
        onButtonClick={openModal}
      />
      <div className="side-nav-and-body">
        <SidebarNavigation className="sidebar-navigation-v3" />
        <div className="body-patients-view">
          <InfoAndFilters
            className="info-and-filters-instance"
            hasCreatedInFilter={false}
            hasDiv={false}
            text={patients.length}
            text1="patients"
            onSearch={handleSearch}
          />
          <div className="scrollable-content">
            <div className="customer-list">
              {loading ? (
                <div className="spinner"></div>
              ) : (
                <>
                  <PatientsTable className="design-component-instance-node-2" />
                  {patients.length > 0 ? (
                    patients.map((patient, index) => (
                      <PatientRow
                        key={index}
                        className="design-component-instance-node-2"
                        text={patient.firstname}
                        text1={patient.lastname}
                        text2={patient.email_id}
                        text3={patient.address}
                        text4={patient.city}
                        text5={patient.state}
                        text6={patient.zip}
                        text7={patient.phone_number}
                        patientId={patient.id}
                      />
                    ))
                  ) : (
                    <div className="no-patients-found">No patients found</div>
                  )}
                  {/* patients.length > 0 && (
                    <div className="load-more">
                      <Button className="button-load-more" style="white" text="Load More" type="default" />
                    </div>
                  ) */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-add-new-wrapper">
          <ModalAddNew onClose={closeModal} onSave={savePatient} />
        </div>
      )}
      {showNotification && (
          <NotificationPopUp
            message={notificationMessage}
            status={notificationStatus}
            iconColor="#2DC8A8"
            closeIconColor="white"
            duration={3000}  // Display for 3 seconds
            onClose={() => setShowNotification(false)}
          />
        )}
    </div>
  );
};
