/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const VuesaxOutlineArrowUp3 = ({ color = "#526477", className }) => {
  return (
    <svg
      className={`vuesax-outline-arrow-up-3 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill={color}
      />
      <path
        className="path"
        d="M12 16.25C11.59 16.25 11.25 15.91 11.25 15.5V9.5C11.25 9.09 11.59 8.75 12 8.75C12.41 8.75 12.75 9.09 12.75 9.5V15.5C12.75 15.91 12.41 16.25 12 16.25Z"
        fill={color}
      />
      <path
        className="path"
        d="M14.9999 12.2514C14.8099 12.2514 14.6199 12.1814 14.4699 12.0314L11.9999 9.56141L9.52994 12.0314C9.23994 12.3214 8.75994 12.3214 8.46994 12.0314C8.17994 11.7414 8.17994 11.2614 8.46994 10.9714L11.4699 7.97141C11.7599 7.68141 12.2399 7.68141 12.5299 7.97141L15.5299 10.9714C15.8199 11.2614 15.8199 11.7414 15.5299 12.0314C15.3799 12.1814 15.1899 12.2514 14.9999 12.2514Z"
        fill={color}
      />
    </svg>
  );
};

VuesaxOutlineArrowUp3.propTypes = {
  color: PropTypes.string,
};
