import React from "react";
import { useOutletContext } from "react-router-dom";
import { Input } from "../../components/Input";
import { DetailDataCell } from "../../components/DetailDataCell";
import "./style.css";

export const SettingsAccount = () => {
  const { account, setAccount, isEditable } = useOutletContext();

  const handleChange = (field, value) => {
    setAccount((prevAccount) => ({ ...prevAccount, [field]: value }));
  };

  return (
    <div className="scrollable-content-history">
      <div className="content-4">
        <div className="left-column-4">
        <div className="div-10">
            {isEditable ? (
              <Input
                className="input-6"
                label="Organization"
                value={account.org_name || ""}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Organization"
                value={account.org_name || "N/A"}
              />
            )}
          </div>
          <div className="div-10">
            {isEditable ? (
              <Input
                className="input-6"
                label="Firstname"
                value={account.firstname || ""}
                onChange={(e) => handleChange("firstname", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Firstname"
                value={account.firstname || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Lastname"
                value={account.lastname || ""}
                onChange={(e) => handleChange("lastname", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Lastname"
                value={account.lastname || "N/A"}
              />
            )}
          </div>
          <div className="div-10">
            {isEditable ? (
              <Input
                className="input-6"
                label="Email"
                value={account.email_id || ""}
                onChange={(e) => handleChange("email_id", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Email"
                value={account.email_id || "N/A"}
              />
            )}
          </div>
          <div className="div-10">
            {isEditable ? (
              <Input
                className="input-6"
                label="Password"
                value={account.password || ""}
                onChange={(e) => handleChange("password", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Password"
                value="********"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
