import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const LogActivityRecord = ({ date, description, imageSrc }) => {
  return (
    <div className="activity-record">
      <div className="icon-wrapper">
        <div className="icon-4" />
      </div>
      <div className="record-details">
        <div className="date-time">{date}</div>
        <div className="description">{description}</div>
        {imageSrc && <img className="image-deal-detail" alt="Activity detail" src={imageSrc} />}
      </div>
    </div>
  );
};

LogActivityRecord.propTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageSrc: PropTypes.string,
};

LogActivityRecord.defaultProps = {
  imageSrc: null,
};
