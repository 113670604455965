import React, { useEffect, useState } from "react";
import { InfoAndFilters } from "../../components/InfoAndFilters";
import { OpportunitiesTable } from "../../components/OpportunitiesTable";
import { OpportunitiesTableWrapper } from "../../components/OpportunitiesTableWrapper";
import { SidebarNavigation } from "../../components/SidebarNavigation";
import { TopNavigationBar } from "../../components/TopNavigationBar";
import { getOpportunities, searchOpportunities } from "../../services/opportunityApi"; // API functions
import "./style.css";

export const OpportunitiesList = () => {
  const [loading, setLoading] = useState(true);
  const [filteredOpportunities, setFilteredOpportunities] = useState([]);

  useEffect(() => {
    const fetchOpportunities = async () => {
      try {
        const data = await getOpportunities();
        setFilteredOpportunities(data.opportunity);
      } catch (error) {
        console.error("Error fetching opportunities:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOpportunities();
  }, []);

  const handleSearch = async (searchTerm) => {
    try {
      setLoading(true);
      const data = await searchOpportunities(searchTerm);
      setFilteredOpportunities(data.opportunity);
    } catch (error) {
       setNotificationStatus("fail");
       setNotificationMessage(error.message);
       setShowNotification(true);
    }
    finally {
        setLoading(false);
     }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(date);
  }

  return (
    <div className="opportunities-list">
      <TopNavigationBar
        baseBaseClassName="top-navigation-bar-instance"
        className="design-component-instance-node-2"
        text="Opportunities"
      />
      <div className="side-nav-and-body">
        <SidebarNavigation className="sidebar-navigation-v3" />
        <div className="body-patients-view">
          <InfoAndFilters
            className="info-and-filters-instance"
            hasCreatedInFilter={false}
            hasDiv={false}
            text={filteredOpportunities.length}
            onSearch={handleSearch}
          />
          <div className="opportunity-list">
            <div className="scrollable-content">
              {loading ? (
                <div className="spinner"></div>
              ) : (
                <>
                  <OpportunitiesTable className="opportunities-table-header" />
                  {filteredOpportunities.length > 0 ? (
                    filteredOpportunities.map((opportunity, index) => (
                      <OpportunitiesTableWrapper
                        key={index}
                        className="design-component-instance-node-2"
                        statusTagBadgeClassName="status-tag-instance"
                        statusTagProperty1={opportunity.status.replace(/ /g, '-')}
                        text={opportunity.opportunity_name}
                        text1={opportunity.staff}
                        text2={opportunity.condition}
                        text3={opportunity.source}
                        text4={formatDate(opportunity.created_at)}
                        text5={opportunity.facility}
                        text6={opportunity.priority}
                        opportunityId={opportunity.id}
                      />
                    ))
                  ) : (
                    <div className="no-opportunities-found">No opportunities found</div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
