import React, { useState } from "react";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Switch } from "../../components/Switch";
import "./style.css";

export const CardIntegration = ({ onSave }) => {
  const [flipped, setFlipped] = useState(false);
  const [privateKey, setPrivateKey] = useState("");
  const [publicKey, setPublicKey] = useState("");

  const handleToggleKeys = () => {
    setFlipped(!flipped);
  };

  const handleSave = () => {
    const config = {
      "name": "BestNotes",
      "type": "EHR",
      "config": {
      "private_key": privateKey,
      "public_key": publicKey
      }
    };

    onSave(config);
    setFlipped(!flipped);
  };

  return (
    <div className="card-integration">
      <div className={`card ${flipped ? "flipped" : ""}`}>
        {/* Front Side */}
        <div className="card-front">
          <div className="title">
            <div className="text-wrapper-2">BestNotes</div>
          </div>
          <div className="form">
            <img
              className="image"
              alt="Image"
              src="https://cdn.animaapp.com/projects/66df33172e69765bb418c9d5/releases/66df333a758f0481468fe6f6/img/image-1.png"
            />
            <div className="description">
              <p className="label">
                <span className="span">BestNotes</span>
                <span className="text-wrapper-3"> - </span>
                <span className="span">a cloud-based EHR platform used in behavioral health</span>
              </p>
            </div>
          </div>
          <div className="actions-2">
            <div className="buttons-3">
              <Button className="button-save-3" style="primary" text="Edit API Keys" type="default" onClick={handleToggleKeys} />
            </div>
          </div>
        </div>

        {/* Back Side */}
        <div className="card-back">
          <div className="form">
           <div className="integration-input-2">
            <Input
                className="input-instance"
                inputClassName="input-2"
                label="Public Key"
                value={publicKey}
                onChange={(e) => setPublicKey(e.target.value)}
              />
           </div>
           <div className="integration-input-2">
            <Input
                className="input-instance"
                inputClassName="input-2"
                label="Private Key"
                value={privateKey}
                onChange={(e) => setPrivateKey(e.target.value)}
              />
          </div>
          </div>
           <div className="actions-2">
            <div className="buttons-3">
              <Button className="button-instance" style="white" text="Cancel" type="default" onClick={handleToggleKeys} />
              <Button className="button-save-2" style="primary" text="Save" type="default" onClick={handleSave} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};