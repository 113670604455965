import { axiosInstance, setAuthHeaders, handleResponse, handleError } from './apiUtils';
import { useNavigate } from 'react-router-dom';

export const getOpportunities = async () => {
    setAuthHeaders();
    const response = await axiosInstance.get('/api/opportunities');
    return handleResponse(response);
};

export const searchOpportunities = async (query) => {
    setAuthHeaders();

    var payload = {
        "query": query
      }

    const response = await axiosInstance.get('/api/opportunities', { params: payload });
    return handleResponse(response);
};

export const getOpportunityById = async (opportunity_id) => {
  try {
    setAuthHeaders();
    var payload = {
        "opportunity_id": opportunity_id
      }

    const response = await axiosInstance.get('/api/opportunity_details', { params: payload });
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const saveOpportunity = async (opportunity) => {
  setAuthHeaders();
  const response = await axiosInstance.post('/api/opportunities', opportunity);
  return handleResponse(response);
};

export const updateOpportunity = async (opportunityId, updatedData) => {
  setAuthHeaders();
    var payload = {
        "opportunity_id": opportunityId,
        "opportunity": updatedData
      }
  const response = await axiosInstance.put('/api/opportunity_details', payload);
  return response.data;
};

export const updateFacility = async (opportunityId, updatedData) => {
  setAuthHeaders();
    var payload = {
        "opportunity_id": opportunityId,
        "opportunity": updatedData
      }
  const response = await axiosInstance.post('/api/update_facility', payload);
  return response.data;
};

export const sendToEMR = async (opportunityId) => {
  setAuthHeaders();
    var payload = {
        "opportunity_id": opportunityId,
      }
  const response = await axiosInstance.post('/api/send_to_emr', payload);
  return response.data;
};

export const uploadDocument = async (opportunityId, file, autofill, fileDescription) => {
  try {
    setAuthHeaders();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('opportunity_id', opportunityId);
    formData.append('autofill', autofill);
    formData.append('file_name', file.name);
    formData.append('file_type', file.type);
    formData.append('file_description', fileDescription);

    const response = await axiosInstance.post('/api/file_upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const getDocuments = async (opportunity_id) => {
  try {
    setAuthHeaders();
    var payload = {
        "opportunity_id": opportunity_id
      }

    const response = await axiosInstance.get('/api/file_upload', { params: payload });
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};