import React, { useState, useEffect, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import { SearchDropdown } from "../../components/SearchDropdown";
import { Input } from "../../components/Input";
import { DetailDataCell } from "../../components/DetailDataCell";
import { searchContacts, searchReferrals } from "../../services/referralApi";
import "./style.css";

export const OpportunityReferralDetails = () => {
  const { opportunity, setOpportunity, isEditable } = useOutletContext();

  const handleContactSearch = async (searchTerm) => {
    try {
      const data = await searchContacts(searchTerm);
      return data.contacts.map(contact => `${contact.firstname} ${contact.lastname}`);
    } catch (error) {
      console.error("Error fetching contacts:", error);
      return [];
    }
  };

  const handleOrganizationSearch = async (searchTerm) => {
    try {
      const data = await searchReferrals(searchTerm);
      return data.referral.map(referral => `${referral.referral_name}`);
    } catch (error) {
      console.error("Error fetching organizations:", error);
      return [];
    }
  };

  const handleChange = (field, value) => {
    setOpportunity((prevOpportunity) => ({ ...prevOpportunity, [field]: value }));
  };

  return (
    <div className="scrollable-content-referral-details">
      <div className="content-5">
        <div className="left-column-5">
          <div className="row-3">
            {isEditable ? (
              <Input
                className="input-6"
                label="Source"
                value={opportunity.source || ""}
                onChange={(e) => handleChange("source", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v2-instance"
                label="Source"
                value={opportunity.source || "N/A"}
              />
            )}
            {isEditable ? (
              <SearchDropdown
                className="input-6"
                label="Referrer organization"
                placeholder={opportunity.referral_org ? opportunity.referral_org : "Select organization"}
                onSearch={handleOrganizationSearch}
                onSelect={(selectedOption) => handleChange("referral_org", selectedOption)}
                value={opportunity.referral_org || ""}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v2-instance"
                label="Referrer organization"
                value={opportunity.referral_org || "N/A"}
              />
            )}
            {isEditable ? (
              <SearchDropdown
                className="input-6"
                label="Referrer contact name"
                placeholder={opportunity.referral_name ? opportunity.referral_name : "Select contact"}
                onSearch={handleContactSearch}
                onSelect={(selectedOption) => handleChange("referral_name", selectedOption)}
                value={opportunity.referral_name || ""}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v2-instance"
                label="Referrer contact name"
                value={opportunity.referral_name || "N/A"}
              />
            )}
          </div>
          <div className="row-3">
            {isEditable ? (
              <Input
                className="input-6"
                label="Referrer contact phone"
                value={opportunity.referral_phone_number || ""}
                onChange={(e) => handleChange("referral_phone_number", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v2-instance"
                label="Referrer contact phone"
                value={opportunity.referral_phone_number || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Referrer contact email"
                value={opportunity.referral_contact_email || ""}
                onChange={(e) => handleChange("referral_contact_email", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v2-instance"
                label="Referrer contact email"
                value={opportunity.referral_contact_email || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Marketing contact"
                value={opportunity.marketing_contact || ""}
                onChange={(e) => handleChange("marketing_contact", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v2-instance"
                label="Marketing contact"
                value={opportunity.marketing_contact || "N/A"}
              />
            )}
          </div>
          <div className="row-3">
            {isEditable ? (
              <SearchDropdown
                className="input-6"
                label="Referred out to organization"
                placeholder={opportunity.referred_out_org ? opportunity.referred_out_org : "Select organization"}
                onSearch={handleOrganizationSearch}
                onSelect={(selectedOption) => handleChange("referred_out_org", selectedOption)}
                value={opportunity.referred_out_org || ""}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v2-instance"
                label="Referred out to organization"
                value={opportunity.referred_out_org || "N/A"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
