import PropTypes from "prop-types";
import React from "react";
import { VuesaxOutlineArrowUp3 } from "../../icons/VuesaxOutlineArrowUp3";
import "./style.css";

export const InputForActivity = ({
  className,
  text = "Description",
  text1 = "",
  onSubmit,
  value,
  onChange,
}) => {
  return (
    <div className={`input-for-activity ${className}`}>
      <div className="label">{text}</div>
      <div className="input">
        <input
          type="text"
          className="placeholder"
          placeholder={text1}
          value={value}
          onChange={onChange}
          style={{ border: 'none', outline: 'none', background: 'transparent' }}
          onKeyPress={(e) => e.key === 'Enter' && onSubmit()} // Trigger submit on Enter key
        />
        <div
          className="icon-wrapper"
          onClick={onSubmit}
          role="button"
          tabIndex="0"
          onKeyPress={(e) => e.key === 'Enter' && onSubmit()}
        >
          <VuesaxOutlineArrowUp3
            className="vuesax-outline-arrow"
            color="#526477"
          />
        </div>
      </div>
    </div>
  );
};

InputForActivity.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
