import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const CensusDataRow = ({
  className,
  imageClassName,
  days = [],
  metric = "Error",
  onNumberClick,
}) => {
  return (
    <div className={`census-data-row ${className}`}>
      <div className="cells">
        <div className="opportunity-name">
          <div className="address-concat">
            <div className="street">{metric}</div>
          </div>
        </div>
        <div className="image">
          <img
            className={`img ${imageClassName}`}
            alt="Image"
            src="https://cdn.animaapp.com/projects/66b83d207b925a830dedca25/releases/66b83d2e4d8f7eb28bb37f13/img/image@2x.png"
          />
        </div>
        {days.map((day, index) => (
          <div className="day" key={index}>
            <div className="room-area-concat">
              <div className="room-area" onClick={() => onNumberClick(index, metric)}>{day}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="border" />
    </div>
  );
};

CensusDataRow.propTypes = {
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  days: PropTypes.arrayOf(PropTypes.string),
  metric: PropTypes.string,
  onNumberClick: PropTypes.func.isRequired,
};
