import React, { useState } from "react";
import { Button } from "../../components/Button";
import { Dropdown } from "../../components/Dropdown";
import { Input } from "../../components/Input";
import { VuesaxBoldCloseCircle } from "../../icons/VuesaxBoldCloseCircle";
import "./style.css";

export const ModalAddNewReferral = ({ onClose, onSave }) => {
  const [name, setName] = useState("");
  const [types, setTypes] = useState("");
  const [specializations, setSpecializations] = useState("");
  const [payment, setPayment] = useState("");
  const [ruleOut, setRuleOut] = useState("");

  const handleSave = () => {
    const newReferral = {
      referral_name: name,
      type: types,
      specializations: specializations,
      payment_type: payment,
      rule_out: ruleOut,
    };

    console.log(newReferral);
    onSave(newReferral);
  };

  return (
    <div className="modal-add-new-referral">
      <div className="title">
        <div className="text-wrapper-3">Add New Organization</div>
        <Button
          className="button-2"
          override={<VuesaxBoldCloseCircle className="vuesax-bold-close-circle" color="#7E92A2" />}
          style="white"
          type="icon-only"
          onClick={onClose}
        />
      </div>
      <div className="content-SCROLL">
        <div className="form">
          <div className="div-4">
            <Input
              className="design-component-instance-node"
              hasDiv={false}
              inputClassName="input-instance"
              label="Name *"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Dropdown
              className="design-component-instance-node"
              label="Type *"
              placeholder="Select Type"
              options={["Treatment center", "Hospital", "Interventionist", "Social worker", "Education consultant", "Other"]}
              onSelect={(selectedTypes) => setTypes(selectedTypes)}
            />
          </div>
          <div className="div-4">
            <Dropdown
              className="design-component-instance-node"
              label="Accepted payment type *"
              placeholder="Select one"
              options={["Private pay", "Insurance", "Medicare"]}
              onSelect={(option) => setPayment(option)}
            />
            <Dropdown
              className="design-component-instance-node"
              label="Specialization(s)"
              placeholder=""
              options={["OCD", "SUD", "Eating disorder", "Personality disorder", "Trauma"]}
              onSelect={(option) => setSpecializations(option)}
              multiselect={true}
            />
          </div>
          <div className="div-4">
            <Input
              className="design-component-instance-node"
              inputClassName="input-instance"
              label="Rule-outs"
              value={ruleOut}
              onChange={(e) => setRuleOut(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="actions">
        <div className="buttons">
          <Button className="button-cancel" style="white" text="Cancel" type="default" onClick={onClose} />
          <Button className="button-save" style="primary" text="Save Organization" type="default" onClick={handleSave} />
        </div>
      </div>
    </div>
  );
};
