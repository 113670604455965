/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const PatientsTable = ({ className }) => {
  return (
    <div className={`patients-table ${className}`}>
      <div className="columns">
        <div className="div-wrapper">
          <div className="text-wrapper-4">Name</div>
        </div>
        <div className="div-wrapper">
          <div className="text-wrapper-4">Email</div>
        </div>
        <div className="phone-column">
          <div className="text-wrapper-5">Phone</div>
        </div>
        <div className="address-column">
          <div className="text-wrapper-5">Address</div>
        </div>
      </div>
      <div className="border" />
    </div>
  );
};
