import React, { useState, useEffect } from "react";
import { Button } from "../../components/Button";
import { Checkbox } from "../../components/Checkbox";
import { Input } from "../../components/Input";
import { InputWithDatePicker } from "../../components/InputWithDatePicker";
import { Dropdown } from "../../components/Dropdown";
import { SearchDropdown } from "../../components/SearchDropdown";
import { Icon34 } from "../../icons/Icon34";
import { Icon36 } from "../../icons/Icon36";
import { VuesaxBoldCloseCircle } from "../../icons/VuesaxBoldCloseCircle";
import { getUsers } from "../../services/taskApi";
import { searchContacts, searchReferrals } from "../../services/referralApi";
import "./style.css";

export const ModalAddTask = ({
  onClose,
  onSaveTask,
}) => {
  const [title, setTitle] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [assignToEmail, setAssignToEmail] = useState("");
  const [orgAssociatedWith, setOrgAssociatedWith] = useState("");
  const [contactAssociatedWith, setContactAssociatedWith] = useState("");
  const [additionalInstructions, setAdditionalInstructions] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const data = await getUsers();
        setUsers(data.users);
      } catch (err) {
        setError("Error fetching users.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    loadUserData();
  }, []);

  const handleUserSelect = (selectedName) => {
    setAssignTo(selectedName);
    const selectedUser = users.find(
      (user) => `${user.firstname} ${user.lastname}` === selectedName
    );
    setAssignToEmail(selectedUser ? selectedUser.email_id : "");
  };

  const handleContactSearch = async (searchTerm) => {
    try {
      const data = await searchContacts(searchTerm);
      return data.contacts.map(contact => `${contact.firstname} ${contact.lastname}`);
    } catch (error) {
      console.error("Error fetching contacts:", error);
      return [];
    }
  };

  const handleOrganizationSearch = async (searchTerm) => {
    try {
      const data = await searchReferrals(searchTerm);
      return data.referral.map(referral => `${referral.referral_name}`);
    } catch (error) {
      console.error("Error fetching organizations:", error);
      return [];
    }
  };

  const handleSaveClick = () => {
    const taskData = {
      title,
      due_date: dueDate,
      additional_instructions: additionalInstructions,
      assigned_to_email_id: assignToEmail,
      additional_instructions: additionalInstructions,
      org_associated_with: orgAssociatedWith,
      contact_associated_with: contactAssociatedWith,
    };
    onSaveTask(taskData);
  };

  if (loading) return <div className="spinner"></div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="modal-add-activity">
      <div className="title-2">
        <div className="text-wrapper-23">Add Task</div>
        <Button
          className="button-2"
          override={<VuesaxBoldCloseCircle className="vuesax-bold-close-circle" color="#7E92A2" />}
          type="icon-only"
          onClick={onClose}
        />
      </div>

      <div className="form-new-deal-form">
        <div className="content-SCROLL">
          <div className="form">
            <div className="row-details">
              <Input
                className="design-component-instance-node"
                label="Title *"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="row-details">
              <Dropdown
                className="design-component-instance-node"
                label="Assign to *"
                placeholder={assignTo || ""}
                options={users.map(user => `${user.firstname} ${user.lastname}`)}
                onSelect={handleUserSelect}
              />

              <InputWithDatePicker
                className="design-component-instance-node"
                icon={<Icon34 className="icon-4" />}
                label="Due date"
                onDateChange={(date) => setDueDate(date)}
              />
            </div>
            <div className="row-details">
             <SearchDropdown
                className="input-6"
                label="Org associated with"
                placeholder={orgAssociatedWith ? orgAssociatedWith : ""}
                onSearch={handleOrganizationSearch}
                onSelect={(selectedOption) => setOrgAssociatedWith(selectedOption)}
                value={orgAssociatedWith || ""}
              />

              <SearchDropdown
                className="input-6"
                label="Contact associated with"
                placeholder={contactAssociatedWith ? contactAssociatedWith : ""}
                onSearch={handleContactSearch}
                onSelect={(selectedOption) => setContactAssociatedWith(selectedOption)}
                value={contactAssociatedWith || ""}
              />
            </div>

            <Input
              className="design-component-instance-node"
              label="Additional Instructions"
              value={additionalInstructions}
              onChange={(e) => setAdditionalInstructions(e.target.value)}
            />

        <div className="action-2">
          <div className="action-3">
            <Button
              className="button-4"
              style="white"
              text="Cancel"
              type="default"
              onClick={onClose}
            />
            <Button
              className="button-5"
              style="primary"
              text="Save Task"
              type="default"
              onClick={handleSaveClick}
            />
          </div>
        </div>
      </div>
    </div>
   </div>
  </div>
  );
};
