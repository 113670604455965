import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

export const InputWithDatePicker = ({ className, label, onDateChange, currentSelection }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(currentSelection);
  const datePickerRef = useRef(null);

  const handleDateChange = (date) => {
    const selectedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    setStartDate(selectedDate);
    setIsOpen(false);
    if (onDateChange) {
      onDateChange(selectedDate);
    }
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`input-with-datepicker ${className}`} ref={datePickerRef}>
      <div className="label">{label}</div>
      <div className="input-and-calendar" onClick={() => setIsOpen(!isOpen)}>
        <div className="div-3">
          <div className="placeholder">
            {startDate ? startDate.toDateString() : "Select a date"}
          </div>
        </div>
        {isOpen && (
          <div className="calendar-dropdown">
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              inline
              todayButton="Today"
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div className="custom-header">
                  <button
                    type="button"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                    className="react-datepicker__navigation react-datepicker__navigation--previous"
                  >
                    {"<"}
                  </button>
                  <select
                    value={date.getMonth()}
                    onChange={({ target: { value } }) => changeMonth(value)}
                    onClick={(e) => e.stopPropagation()} // Prevent closing on dropdown click
                  >
                    {Array.from({ length: 12 }, (_, i) => (
                      <option key={i} value={i}>
                        {new Date(0, i).toLocaleString("en-US", {
                          month: "long",
                        })}
                      </option>
                    ))}
                  </select>

                  <select
                    value={date.getFullYear()}
                    onChange={({ target: { value } }) => changeYear(value)}
                    onClick={(e) => e.stopPropagation()} // Prevent closing on dropdown click
                  >
                    {Array.from(
                      { length: 10 },
                      (_, i) => new Date().getFullYear() - 5 + i
                    ).map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>

                  <button
                    type="button"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                    className="react-datepicker__navigation react-datepicker__navigation--next"
                  >
                    {">"}
                  </button>
                </div>
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

InputWithDatePicker.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onDateChange: PropTypes.func,
  currentSelection: PropTypes.instanceOf(Date),
};
