import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Input = ({
  className,
  label = "Label",
  value = "",
  divClassName = "",
  onChange,
}) => {
  return (
     <div className={`input label-true ${className}`}>
        <>
          <div className="label">{label}</div>
          <div className="div-3">
              <input
                type="text"
                className="placeholder"
                value={value}
                onChange={onChange}
                style={{ border: 'none', outline: 'none', background: 'transparent' }}
              />
          </div>
        </>
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  divClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
