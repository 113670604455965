import PropTypes from "prop-types";
import React from "react";
import { LogActivityRecord } from "../LogActivityRecord";
import { formatDateTimeToReadableDateTime } from "../../Utils/utils"
import "./style.css";

export const LogActivityArea = ({ className, activities }) => {
  return (
    <div className={`log-activity-area ${className}`}>
      <div className="title">
        <div className="text-wrapper-4">Activity Log</div>
      </div>
      <div className="activity-field">
        {activities.map((activity, index) => {
          const description = activity.meta_description
            ? `${activity.meta_description} with description "${activity.description}"`
            : activity.description;

          return (
            <LogActivityRecord
              key={index}
              date={formatDateTimeToReadableDateTime(activity.created_at)}
              description={description}
            />
          );
        })}
        {/* <button className="button-load-more">
          <div className="text-wrapper-6">Load More</div>
        </button> */}
      </div>
    </div>
  );
};

LogActivityArea.propTypes = {
  className: PropTypes.string,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      meta_description: PropTypes.string,
      imageSrc: PropTypes.string,
    })
  ).isRequired,
};
