/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconTasks = ({ className }) => {
  return (
    <svg
      className={`icon-tasks ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M17.1661 16.05H9.60775C9.23275 16.05 8.92441 15.7417 8.92441 15.3667C8.92441 14.9917 9.23275 14.6834 9.60775 14.6834H17.1661C17.5411 14.6834 17.8494 14.9917 17.8494 15.3667C17.8494 15.75 17.5411 16.05 17.1661 16.05Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M17.1661 10.8084H9.60775C9.23275 10.8084 8.92441 10.5 8.92441 10.125C8.92441 9.75002 9.23275 9.44168 9.60775 9.44168H17.1661C17.5411 9.44168 17.8494 9.75002 17.8494 10.125C17.8494 10.5 17.5411 10.8084 17.1661 10.8084Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M17.1661 5.55835H9.60775C9.23275 5.55835 8.92441 5.25002 8.92441 4.87502C8.92441 4.50002 9.23275 4.19168 9.60775 4.19168H17.1661C17.5411 4.19168 17.8494 4.50002 17.8494 4.87502C17.8494 5.25002 17.5411 5.55835 17.1661 5.55835Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M4.59108 6.69168C4.40775 6.69168 4.23275 6.61668 4.10775 6.49168L3.34941 5.73335C3.08275 5.46668 3.08275 5.03335 3.34941 4.76668C3.61608 4.50002 4.04941 4.50002 4.31608 4.76668L4.59108 5.04168L6.37441 3.25835C6.64108 2.99168 7.07441 2.99168 7.34108 3.25835C7.60775 3.52502 7.60775 3.95835 7.34108 4.22502L5.07441 6.49168C4.94108 6.61668 4.77441 6.69168 4.59108 6.69168Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M4.59108 11.9417C4.41608 11.9417 4.24108 11.875 4.10775 11.7417L3.34941 10.9834C3.08275 10.7167 3.08275 10.2833 3.34941 10.0167C3.61608 9.75002 4.04941 9.75002 4.31608 10.0167L4.59108 10.2917L6.37441 8.50835C6.64108 8.24168 7.07441 8.24168 7.34108 8.50835C7.60775 8.77502 7.60775 9.20835 7.34108 9.47502L5.07441 11.7417C4.94108 11.875 4.76608 11.9417 4.59108 11.9417Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M4.59108 16.9417C4.41608 16.9417 4.24108 16.875 4.10775 16.7417L3.34941 15.9834C3.08275 15.7167 3.08275 15.2833 3.34941 15.0167C3.61608 14.75 4.04941 14.75 4.31608 15.0167L4.59108 15.2917L6.37441 13.5083C6.64108 13.2417 7.07441 13.2417 7.34108 13.5083C7.60775 13.775 7.60775 14.2084 7.34108 14.475L5.07441 16.7417C4.94108 16.875 4.76608 16.9417 4.59108 16.9417Z"
        fill="#7E92A2"
      />
    </svg>
  );
};
