import React from "react";
import { useOutletContext } from "react-router-dom";
import { Input } from "../../components/Input";
import { DetailDataCell } from "../../components/DetailDataCell";
import "./style.css";

export const PatientPointOfContact = () => {
  const { patient, setPatient, isEditable } = useOutletContext();

  const handleChange = (field, value) => {
    setPatient((prevPatient) => ({ ...prevPatient, [field]: value }));
  };

  return (
  <div className="scrollable-content-point-of-contact">
    <div className="form-for-POC">
      <div className="div-6">
        {isEditable ? (
          <Input
            className="input-5"
            label="Emergency Contact First Name (Primary)"
            value={patient.emergency_contact_primary_firstname || ""}
            onChange={(e) => handleChange("emergency_contact_primary_firstname", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Emergency Contact First Name (Primary)"
            value={patient.emergency_contact_primary_firstname || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="input-5"
            label="Emergency Contact Last Name (Primary)"
            value={patient.emergency_contact_primary_lastname || ""}
            onChange={(e) => handleChange("emergency_contact_primary_lastname", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Emergency Contact Last Name (Primary)"
            value={patient.emergency_contact_primary_lastname || "N/A"}
          />
        )}
      </div>
      <div className="div-6">
        {isEditable ? (
          <Input
            className="input-5"
            label="Emergency Contact First Name (Secondary)"
            value={patient.emergency_contact_secondary_firstname || ""}
            onChange={(e) => handleChange("emergency_contact_secondary_firstname", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Emergency Contact First Name (Secondary)"
            value={patient.emergency_contact_secondary_firstname || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="input-5"
            label="Emergency Contact Last Name (Secondary)"
            value={patient.emergency_contact_secondary_lastname || ""}
            onChange={(e) => handleChange("emergency_contact_secondary_lastname", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Emergency Contact Last Name (Secondary)"
            value={patient.emergency_contact_secondary_lastname || "N/A"}
          />
        )}
      </div>
      <div className="div-6">
        {isEditable ? (
          <Input
            className="input-5"
            label="Emergency Contact Email (Primary)"
            value={patient.emergency_contact_primary_email || ""}
            onChange={(e) => handleChange("emergency_contact_primary_email", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Emergency Contact Email (Primary)"
            value={patient.emergency_contact_primary_email || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="input-5"
            label="Emergency Contact Phone (Primary)"
            value={patient.emergency_contact_primary_phone || ""}
            onChange={(e) => handleChange("emergency_contact_primary_phone", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Emergency Contact Phone (Primary)"
            value={patient.emergency_contact_primary_phone || "N/A"}
          />
        )}
      </div>
      <div className="div-6">
        {isEditable ? (
          <Input
            className="input-5"
            label="Emergency Contact Email (Secondary)"
            value={patient.emergency_contact_secondary_email || ""}
            onChange={(e) => handleChange("emergency_contact_secondary_email", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Emergency Contact Email (Secondary)"
            value={patient.emergency_contact_secondary_email || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="input-5"
            label="Emergency Contact Phone (Secondary)"
            value={patient.emergency_contact_secondary_phone || ""}
            onChange={(e) => handleChange("emergency_contact_secondary_phone", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Emergency Contact Phone (Secondary)"
            value={patient.emergency_contact_secondary_phone || "N/A"}
          />
        )}
      </div>
      <div className="div-6">
        {isEditable ? (
          <Input
            className="input-5"
            label="Legal Guardianship Status"
            value={patient.legal_guardianship_status || ""}
            onChange={(e) => handleChange("legal_guardianship_status", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Legal Guardianship Status"
            value={patient.legal_guardianship_status || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="input-5"
            label="Legal Guardianship Documents"
            value={patient.legal_guardianship_documents || ""}
            onChange={(e) => handleChange("legal_guardianship_documents", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Legal Guardianship Documents"
            value={patient.legal_guardianship_documents || "N/A"}
          />
        )}
      </div>
      <div className="div-6">
        {isEditable ? (
          <Input
            className="input-5"
            label="Gender"
            value={patient.gender || ""}
            onChange={(e) => handleChange("gender", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Gender"
            value={patient.gender || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="input-5"
            label="Sex at birth"
            value={patient.sex_at_birth || ""}
            onChange={(e) => handleChange("sex_at_birth", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Sex at birth"
            value={patient.sex_at_birth || "N/A"}
          />
        )}
      </div>
    </div>
   </div>
  );
};
