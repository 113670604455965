/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icon2 = ({ className }) => {
  return (
    <svg
      className={`icon-2 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M18.0749 5.81628C17.3665 5.03294 16.1832 4.64128 14.4665 4.64128H14.2665V4.60794C14.2665 3.20794 14.2665 1.47461 11.1332 1.47461H9.86653C6.7332 1.47461 6.7332 3.21628 6.7332 4.60794V4.64961H6.5332C4.8082 4.64961 3.6332 5.04128 2.92487 5.82461C2.09987 6.74128 2.12487 7.97461 2.2082 8.81628L2.21654 8.87461L2.28108 9.55234C2.29297 9.67716 2.36019 9.78989 2.4651 9.85855C2.66493 9.98932 2.99939 10.2049 3.19987 10.3163C3.31654 10.3913 3.44154 10.4579 3.56654 10.5246C4.99154 11.3079 6.5582 11.8329 8.14987 12.0913C8.22487 12.8746 8.56653 13.7913 10.3915 13.7913C12.2165 13.7913 12.5749 12.8829 12.6332 12.0746C14.3332 11.7996 15.9749 11.2079 17.4582 10.3413C17.5082 10.3163 17.5415 10.2913 17.5832 10.2663C17.9138 10.0794 18.2568 9.84917 18.5694 9.62365C18.6636 9.5557 18.7238 9.45065 18.7367 9.33523L18.7499 9.21628L18.7915 8.82461C18.7999 8.77461 18.7999 8.73294 18.8082 8.67461C18.8749 7.83294 18.8582 6.68294 18.0749 5.81628ZM11.4082 11.5246C11.4082 12.4079 11.4082 12.5413 10.3832 12.5413C9.3582 12.5413 9.3582 12.3829 9.3582 11.5329V10.4829H11.4082V11.5246ZM7.92487 4.60794C7.92487 3.19128 7.92487 2.66628 9.86653 2.66628H11.1332C13.0749 2.66628 13.0749 3.19961 13.0749 4.60794V4.64961H7.92487V4.60794Z"
        fill="white"
      />
      <path
        className="path"
        d="M17.8944 11.4448C18.1891 11.3045 18.5285 11.5381 18.499 11.8631L18.1999 15.1579C18.0249 16.8246 17.3415 18.5246 13.6749 18.5246H7.32487C3.6582 18.5246 2.97487 16.8246 2.79987 15.1663L2.51594 12.0431C2.48674 11.7219 2.81837 11.4886 3.11223 11.6215C4.06175 12.0511 5.81436 12.8133 6.89701 13.0968C7.03393 13.1327 7.14468 13.2307 7.20463 13.3589C7.71044 14.4407 8.80746 15.0163 10.3915 15.0163C11.96 15.0163 13.0708 14.4185 13.5783 13.3341C13.6384 13.2058 13.7492 13.1078 13.8862 13.0718C15.0358 12.7693 16.9014 11.9173 17.8944 11.4448Z"
        fill="white"
      />
    </svg>
  );
};
