import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "../../components/Button";
import { Dropdown } from "../../components/Dropdown";
import { Input } from "../../components/Input";
import { InputWithDatePicker } from "../../components/InputWithDatePicker";
import { VuesaxBoldCloseCircle } from "../../icons/VuesaxBoldCloseCircle";
import { getCapacityData } from "../../services/censusApi";
import { v4 as uuidv4 } from "uuid";
import "./style.css";

export const ModalAddNewOpportunity = ({
  opportunityName,
  onClose,
  onSaveOpportunity,
}) => {
  const [condition, setCondition] = useState("");
  const [staff, setStaff] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonPhone, setContactPersonPhone] = useState("");
  const [referralOrganization, setReferralOrganization] = useState("");
  const [marketingContact, setMarketingContact] = useState("");
  const [additionalInstructions, setAdditionalInstructions] = useState("");

  const [clinicians, setClinicians] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadfacilityData = async () => {
      try {
        const data = await getCapacityData();
        setClinicians(data.capacity_clinician);
      } catch (err) {
        setError("Error fetching facility data.");
        console.error(err);
      } finally {
        setLoading(false);
      }

    };
    loadfacilityData();
  }, []);

  const handleSaveClick = () => {
    const opportunityData = {
      "opportunity_name": opportunityName + "-" + uuidv4().slice(0, 4),
      "condition": condition,
      "staff": staff,
      "created_at_date": createdDate,
      "contact_person_name": contactPersonName,
      "contact_person_phone": contactPersonPhone,
      "referral_org": referralOrganization,
      "marketing_contact": marketingContact,
      "additional_instructions": additionalInstructions,
    };
    onSaveOpportunity(opportunityData);
  };

  if (loading) return <div className="spinner"></div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="modal-overlay-opportunity">
      <div className="modal-add-new-deal">
        <div className="title">
          <div className="text-wrapper-2">Add New Opportunity</div>
          <Button
          className="button-2"
          override={<VuesaxBoldCloseCircle className="vuesax-bold-close-circle" color="#7E92A2" />}
          type="icon-only"
          onClick={onClose}
        />
        </div>
        <div className="customer-selected">
          <div className="costumer">
            <div className="details">
              <div className="email">Patient</div>
              <div className="name">
                <div className="first-name">{opportunityName}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-new-deal-form">
          <div className="content-SCROLL">
            <div className="form">
              <div className="row-details">
                <Dropdown
                  className="design-component-instance-node"
                  label="Condition *"
                  placeholder="Select Condition"
                  options={["OCD", "Anxiety", "Depression", "Bipolar Disorder"]}
                  onSelect={(option) => setCondition(option)}
                />
                <Dropdown
                  className="design-component-instance-node"
                  label="Staff"
                  placeholder="Select Staff"
                  options={clinicians.filter(c => c.type === "Staff").map(c => `${c.firstname} ${c.lastname}`)}
                  onSelect={(option) => setStaff(option)}
                />
              </div>
              <div className="row-details">
                <InputWithDatePicker
                  className="design-component-instance-node"
                  label="Created Date"
                  onDateChange={(date) => setCreatedDate(date)}
                />
              </div>
              <div className="row-details">
                <Input
                  className="design-component-instance-node"
                  label="Contact person name"
                  value={contactPersonName}
                  onChange={(e) => setContactPersonName(e.target.value)}
                />
                <Input
                  className="design-component-instance-node"
                  label="Contact person phone"
                  value={contactPersonPhone}
                  onChange={(e) => setContactPersonPhone(e.target.value)}
                />
              </div>
              <div className="row-details">
                <Input
                  className="design-component-instance-node"
                  label="Referral organization"
                  value={referralOrganization}
                  onChange={(e) => setReferralOrganization(e.target.value)}
                />
                <Input
                  className="design-component-instance-node"
                  label="Marketing contact"
                  value={marketingContact}
                  onChange={(e) => setMarketingContact(e.target.value)}
                />
              </div>
              <Input
                className="input-instance"
                label="Additional Instructions"
                value={additionalInstructions}
                onChange={(e) => setAdditionalInstructions(e.target.value)}
              />
            </div>
          </div>
          <div className="actions">
            <div className="buttons">
              <Button className="button-cancel" style="white" text="Cancel" type="default" onClick={onClose} />
              <Button className="button-save" style="primary" text="Save Opportunity" type="default" onClick={handleSaveClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalAddNewOpportunity.propTypes = {
  opportunityName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSaveOpportunity: PropTypes.func.isRequired,
};
