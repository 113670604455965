/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const VuesaxOutlineBriefcase = ({ color = "#292D32", className }) => {
  return (
    <svg
      className={`vuesax-outline-briefcase ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M13.3332 18.9584H6.66653C2.81653 18.9584 2.09986 17.1667 1.91653 15.4251L1.29153 8.75008C1.19986 7.87508 1.17486 6.58341 2.04153 5.61675C2.79153 4.78341 4.0332 4.38341 5.8332 4.38341H14.1665C15.9749 4.38341 17.2165 4.79175 17.9582 5.61675C18.8249 6.58341 18.7999 7.87508 18.7082 8.75842L18.0832 15.4167C17.8999 17.1667 17.1832 18.9584 13.3332 18.9584ZM5.8332 5.62508C4.42486 5.62508 3.4582 5.90008 2.96653 6.45008C2.5582 6.90008 2.42486 7.59175 2.5332 8.62508L3.1582 15.3001C3.29986 16.6167 3.6582 17.7084 6.66653 17.7084H13.3332C16.3332 17.7084 16.6999 16.6167 16.8415 15.2917L17.4665 8.63341C17.5749 7.59175 17.4415 6.90008 17.0332 6.45008C16.5415 5.90008 15.5749 5.62508 14.1665 5.62508H5.8332Z"
        fill={color}
      />
      <path
        className="path"
        d="M13.3332 5.62508C12.9915 5.62508 12.7082 5.34175 12.7082 5.00008V4.33341C12.7082 2.85008 12.7082 2.29175 10.6665 2.29175H9.3332C7.29153 2.29175 7.29153 2.85008 7.29153 4.33341V5.00008C7.29153 5.34175 7.0082 5.62508 6.66653 5.62508C6.32486 5.62508 6.04153 5.34175 6.04153 5.00008V4.33341C6.04153 2.86675 6.04153 1.04175 9.3332 1.04175H10.6665C13.9582 1.04175 13.9582 2.86675 13.9582 4.33341V5.00008C13.9582 5.34175 13.6749 5.62508 13.3332 5.62508Z"
        fill={color}
      />
      <path
        className="path"
        d="M9.99986 13.9584C7.7082 13.9584 7.7082 12.5417 7.7082 11.6917V10.8334C7.7082 9.65841 7.99153 9.37508 9.16653 9.37508H10.8332C12.0082 9.37508 12.2915 9.65841 12.2915 10.8334V11.6667C12.2915 12.5334 12.2915 13.9584 9.99986 13.9584ZM8.9582 10.6251C8.9582 10.6917 8.9582 10.7667 8.9582 10.8334V11.6917C8.9582 12.5501 8.9582 12.7084 9.99986 12.7084C11.0415 12.7084 11.0415 12.5751 11.0415 11.6834V10.8334C11.0415 10.7667 11.0415 10.6917 11.0415 10.6251C10.9749 10.6251 10.8999 10.6251 10.8332 10.6251H9.16653C9.09986 10.6251 9.02486 10.6251 8.9582 10.6251Z"
        fill={color}
      />
      <path
        className="path"
        d="M11.6665 12.3084C11.3582 12.3084 11.0832 12.0751 11.0499 11.7584C11.0082 11.4167 11.2499 11.1001 11.5915 11.0584C13.7915 10.7834 15.8999 9.95008 17.6749 8.65842C17.9499 8.45008 18.3415 8.51675 18.5499 8.80008C18.7499 9.07508 18.6915 9.46675 18.4082 9.67508C16.4582 11.0917 14.1582 12.0001 11.7415 12.3084C11.7165 12.3084 11.6915 12.3084 11.6665 12.3084Z"
        fill={color}
      />
      <path
        className="path"
        d="M8.3332 12.3167C8.3082 12.3167 8.2832 12.3167 8.2582 12.3167C5.97486 12.0584 3.74986 11.2251 1.82486 9.90842C1.54153 9.71675 1.46653 9.32508 1.6582 9.04175C1.84986 8.75842 2.24153 8.68341 2.52486 8.87508C4.2832 10.0751 6.3082 10.8334 8.39153 11.0751C8.7332 11.1167 8.9832 11.4251 8.94153 11.7667C8.91653 12.0834 8.64986 12.3167 8.3332 12.3167Z"
        fill={color}
      />
    </svg>
  );
};

VuesaxOutlineBriefcase.propTypes = {
  color: PropTypes.string,
};
