/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const CapacityFacilityRow = ({ className,
                            cellsClassName,
                            name = "14",
                            type,
                            maxCapacity,
                            opportunities,
                            borderClassName }) => {
  return (
    <div className={`capacity-clinician ${className}`}>
      <div className={`cells ${cellsClassName}`}>
        <div className="address-concat-wrapper">
          <div className="address-concat">
            <div className="street">{name}</div>
          </div>
        </div>
        <div className="room-area-concat-wrapper">
          <div className="room-area-concat">
            <div className="room-area">{type}</div>
          </div>
        </div>
        <div className="room-area-concat-wrapper">
          <div className="room-area-wrapper">
            <div className="room-area-2">{maxCapacity}</div>
          </div>
        </div>
        <div className="appointment-date-wrapper">
          <div className="appointment-date">{opportunities}</div>
        </div>
      </div>
      <div className={`border-2 ${borderClassName}`} />
    </div>
  );
};

CapacityFacilityRow.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  maxCaseload: PropTypes.string,
  opportunities: PropTypes.string,
};
