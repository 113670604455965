import React, { useState, useEffect } from "react";
import { Outlet, NavLink, useParams } from "react-router-dom";
import { Activity } from "../../components/Activity";
import { Base } from "../../components/Base";
import { Button } from "../../components/Button";
import { DetailPage } from "../../components/DetailPage";
import { DetailPageHeader } from "../../components/DetailPageHeader";
import { SidebarNavigation } from "../../components/SidebarNavigation";
import { TopNavigationBar } from "../../components/TopNavigationBar";
import { Input } from "../../components/Input";
import { InputWithDatePicker } from "../../components/InputWithDatePicker";
import { SearchDropdown } from "../../components/SearchDropdown";
import { Dropdown } from "../../components/Dropdown";
import { DetailDataCell } from "../../components/DetailDataCell";
import { NotificationPopUp } from "../../components/NotificationPopUp";
import { IconAdd } from "../../icons/IconAdd";
import { IconQna } from "../../icons/IconQna";
import { IconOpenNewTab3 } from "../../icons/IconOpenNewTab3";
import { VuesaxOutlineNoteText } from "../../icons/VuesaxOutlineNoteText";
import { VuesaxOutlineWarning2 } from "../../icons/VuesaxOutlineWarning2";
import { getTaskById, updateTask, getUsers } from "../../services/taskApi";
import { searchContacts, searchReferrals } from "../../services/referralApi";
import "./style.css";

export const TaskDetail = () => {
  const { taskId } = useParams();
  const [task, setTask] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [buttonText, setButtonText] = useState("Edit Details");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");

  useEffect(() => {
    const fetchTask = async () => {
      try {
        const data = await getTaskById(taskId);
        setTask(data.task);

        const userData = await getUsers();
        setUsers(userData.users);

      } catch (error) {
        setNotificationStatus("fail");
        setNotificationMessage("Error fetching task details");
        setShowNotification(true);
      } finally {
        setLoading(false);
      }
    };
    fetchTask();
  }, [taskId]);


  const handleUserSelect = (selectedName) => {
    const selectedUser = users.find(
      (user) => `${user.firstname} ${user.lastname}` === selectedName
    );
    setTask((prevTask) => ({
      ...prevTask,
      assigned_to_email_id: selectedUser ? selectedUser.email_id : "",
      assigned_to: selectedName
    }));
  };

  const handleContactSearch = async (searchTerm) => {
    try {
      const data = await searchContacts(searchTerm);
      return data.contacts.map(contact => `${contact.firstname} ${contact.lastname}`);
    } catch (error) {
      console.error("Error fetching contacts:", error);
      return [];
    }
  };

  const handleOrganizationSearch = async (searchTerm) => {
    try {
      const data = await searchReferrals(searchTerm);
      return data.referral.map(referral => `${referral.referral_name}`);
    } catch (error) {
      console.error("Error fetching organizations:", error);
      return [];
    }
  };

  const handleEditClick = () => {
    if (isEditable) {
      handleSave();
    } else {
      setIsEditable(true);
      setButtonText("Save Details");
    }
  };

  const handleSave = async () => {
    try {
      await updateTask(taskId, task);
      setIsEditable(false);
      setButtonText("Edit Details");
      setNotificationStatus("success");
      setNotificationMessage("Successfully saved the details!");
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage("Error saving task details");
    }
    finally {
      setShowNotification(true);
    }
  };

   const handleChange = (field, value) => {
    setTask((prevTask) => ({ ...prevTask, [field]: value }));
  };

  if (loading) return <div className="spinner"></div>;
  if (error) return <div className="error">{error}</div>;

  return (
     <div className="opportunity-detail-wrapper">
      <TopNavigationBar
        className="top-navigation-bar-v3"
        text="Task Detail"
        baseBaseClassName="top-navigation-bar-instance"
      />
      <div className="horizontal-body-side-2">
        <SidebarNavigation className="sidebar-navigation-v3" />
        <div className="body-deal-detail-2">
          <div className="left-area-2">
            <DetailPageHeader
              name={task.title}
              buttonTypeIconOnlyStyleClassName="detail-page-header-2"
              className="detail-page-header-instance"
              onEditClick={handleEditClick}
              buttonText={buttonText}
            />
            <DetailPage
              className="design-component-instance-node-3"
              emailFieldClassName="detail-page-navigation-tabs"
              hasDiv={false}
              text={
                <NavLink
                  to={`/task/${taskId}/details`}
                  className={({ isActive }) => (isActive ? "detail-page-link active" : "detail-page-link")}
                >
                  Details
                </NavLink>
              }
            />
            <div className="scrollable-content-history">
             <div className="content-4">
                <div className="left-column-4">
                  <div className="div-10">
                    {isEditable ? (
                      <Input
                        className="input-6"
                        label="Title"
                        value={task.title || ""}
                        onChange={(e) => handleChange("title", e.target.value)}
                      />
                    ) : (
                      <DetailDataCell
                        className="detail-data-cell-v-instance"
                        label="Title"
                        value={task.title || "N/A"}
                      />
                    )}
                    {isEditable ? (
                      <InputWithDatePicker
                          className="input-6"
                          label="Due date"
                          currentSelection={task.due_date ? new Date(task.due_date) : new Date()}
                          onDateChange={(date) => handleChange("due_date", date.toISOString().split('T')[0])}
                        />
                    ) : (
                      <DetailDataCell
                        className="detail-data-cell-v-instance"
                        label="Due date"
                        value={task.due_date || "N/A"}
                      />
                    )}
                  </div>
                  <div className="div-10">
                    {isEditable ? (
                      <Dropdown
                        className="design-component-instance-node"
                        label="Assigned to"
                        placeholder={task.assigned_to || ""}
                        options={users.map(user => `${user.firstname} ${user.lastname}`)}
                        onSelect={handleUserSelect}
                      />
                    ) : (
                      <DetailDataCell
                        className="detail-data-cell-v-instance"
                        label="Assigned to"
                        value={task.assigned_to || "N/A"}
                      />
                    )}
                    {isEditable ? (
                      <Input
                        className="input-6"
                        label="Created by"
                        value={task.created_by || ""}
                      />
                    ) : (
                      <DetailDataCell
                        className="detail-data-cell-v-instance"
                        label="Created by"
                        value={task.created_by || "N/A"}
                      />
                    )}
                  </div>
                  <div className="div-10">
                    {isEditable ? (
                      <SearchDropdown
                        className="input-6"
                        label="Organization associated with"
                        placeholder={task.org_associated_with ? task.org_associated_with : ""}
                        onSearch={handleOrganizationSearch}
                        onSelect={(selectedOption) => handleChange("org_associated_with", selectedOption)}
                        value={task.org_associated_with || ""}
                      />
                    ) : (
                      <DetailDataCell
                        className="detail-data-cell-v-instance"
                        label="Organization associated with"
                        value={task.org_associated_with || "N/A"}
                      />
                    )}
                    {isEditable ? (
                      <SearchDropdown
                        className="input-6"
                        label="Contact associated with"
                        placeholder={task.contact_associated_with ? task.contact_associated_with : ""}
                        onSearch={handleContactSearch}
                        onSelect={(selectedOption) => handleChange("contact_associated_with", selectedOption)}
                        value={task.contact_associated_with || ""}
                      />

                    ) : (
                      <DetailDataCell
                        className="detail-data-cell-v-instance"
                        label="Contact associated with"
                        value={task.contact_associated_with || "N/A"}
                      />
                    )}
                  </div>
                  <div className="div-10">
                    {isEditable ? (
                      <Input
                        className="input-6"
                        label="Additional instructions"
                        value={task.additional_instructions || ""}
                        onChange={(e) => handleChange("additional_instructions", e.target.value)}
                      />
                    ) : (
                      <DetailDataCell
                        className="detail-data-cell-v-instance"
                        label="Additional instructions"
                        value={task.additional_instructions || "N/A"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
         </div>
        </div>
        {showNotification && (
          <NotificationPopUp
            message={notificationMessage}
            status={notificationStatus}
            iconColor="#2DC8A8"
            closeIconColor="white"
            duration={3000}  // Display for 3 seconds
            onClose={() => setShowNotification(false)}
          />
        )}
      </div>
  );
};
