/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const OpportunitiesTable = ({ className, inputType = "text" }) => {
  return (
    <div className={`opportunities-table ${className}`}>
      <div className="columns">
        <div className="opportunity-column">
        <div className="opportunity-name">Opportunity Name</div>
        </div>
        <div className="condition-column">
          <div className="text-wrapper-4">Condition</div>
        </div>
        <div className="div-wrapper">
          <div className="text-wrapper-5">Staff</div>
        </div>
        <div className="div-wrapper">
          <div className="text-wrapper-5">Facility</div>
        </div>
        <div className="status-column">
          <div className="text-wrapper-5">Status</div>
        </div>
        <div className="priority-column">
          <div className="text-wrapper-4">Priority</div>
        </div>
         <div className="date-created-column">
          <div className="text-wrapper-5">Date created</div>
        </div>
      </div>
      <div className="border" />
    </div>
  );
};

OpportunitiesTable.propTypes = {
  inputType: PropTypes.string,
};
