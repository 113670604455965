import PropTypes from "prop-types";
import React from "react";
import { StatusTag } from "../StatusTag";
import { Link } from "react-router-dom";
import "./style.css";

export const OpportunitiesTableWrapper = ({
  className,
  text = "",
  statusTagProperty1 = "",
  statusTagBadgeClassName,
  text1 = "",
  text2 = "",
  text3 = "",
  text4 = "",
  text5 = "",
  text6 = "",
  opportunityId,
}) => {
  return (
    <Link to={`/opportunity/${opportunityId}`} className={`opportunities-table-wrapper ${className}`}>
      <div className="cells">
        <div className="address-concat-wrapper">
          <div className="address-concat">
            <div className="text-wrapper-6">{text}</div>
          </div>
        </div>
        <div className="issue">
          <div className="room-area-concat">
            <div className="room-area">{text2}</div>
          </div>
        </div>
        <div className="appointment-date-wrapper">
          <div className="appointment-date">{text1}</div>
        </div>
        <div className="appointment-date-wrapper">
          <div className="appointment-date">{text5}</div>
        </div>
        <div className="progress">
        <StatusTag badgeClassName={statusTagBadgeClassName} property={statusTagProperty1} />
        </div>
        <div className="price-cell">
          <div className="price-concat">
            <div className="price">{text6}</div>
          </div>
        </div>
        <div className="date-cell">
          <p className="appointment-date">{text4}</p>
        </div>
      </div>
      <div className="border-2" />
    </Link>
  );
};

OpportunitiesTableWrapper.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  statusTagProperty1: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  text5: PropTypes.string,
  opportunityId: PropTypes.string.isRequired,
};
