import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Logo3 } from "../../icons/Logo3";
import { handleResetPassword, checkTokenValidity } from "../../services/loginApi";
import "./style.css";

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const [validToken, setValidToken] = useState(false);
  const [loading, setLoading] = useState(true); // New loading state
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      validateToken(token);
    } else {
      setError("Invalid or missing token.");
      setLoading(false);
    }
  }, [searchParams]);

  const validateToken = async (token) => {
    try {
      const response = await checkTokenValidity(token);
      setValidToken(response);
    } catch (error) {
      setError("Invalid or expired token.");
    } finally {
      setLoading(false);
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setIsLoading(true);
    setShowModal(true);
    setModalMessage("Changing password...");

    try {
      const token = searchParams.get("token");
      const data = await handleResetPassword(token, password);
      if (data) {
        setModalMessage("Password changed successfully!");
      } else {
        setModalMessage("Failed to reset password.");
      }
    } catch (error) {
      setModalMessage("An error occurred during password reset.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    if (modalMessage === "Password changed successfully!") {
      navigate("/login");
    }
  };

  return (
    <div className="login-page">
      <div className="frame-wrapper">
        {loading ? (
          <div className="spinner" />
        ) : (
          <form className="frame-4" onSubmit={changePassword}>
            <div className="frame-5">
              <Logo3 className="logo-1" />
              <div className="text-wrapper-14">New Resilience</div>
            </div>

            {validToken ? (
              <>
                <div className="form-group input-2">
                  <input
                    id="password"
                    type="password"
                    placeholder=" "
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <label htmlFor="password">Enter new password</label>
                </div>

                <div className="form-group input-2">
                  <input
                    id="confirmPassword"
                    type="password"
                    placeholder=" "
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <label htmlFor="confirmPassword">Confirm new password</label>
                </div>

                <button className="button-primary" type="submit">
                  Reset password
                </button>
              </>
            ) : (
              <div className="error-message">Invalid or expired token.</div>
            )}

            {error && <div className="error-message">{error}</div>}
          </form>
        )}
        <p className="back-to-login" onClick={() => navigate("/login")}>
          Back to login
        </p>
      </div>

      {showModal && (
        <Modal isLoading={isLoading} message={modalMessage} onClose={handleCloseModal} />
      )}
    </div>
  );
};

// Modal Component
const Modal = ({ isLoading, message, onClose }) => {
  return (
    <div className="modal-overlay-forgot-password">
      <div className="modal-content-forgot-password">
        {isLoading ? (
          <div className="spinner"></div>
        ) : (
          <div className="modal-message">{message}</div>
        )}
        {!isLoading && (
          <button className="modal-close-button-forgot-password" onClick={onClose}>
            Close
          </button>
        )}
      </div>
    </div>
  );
};
