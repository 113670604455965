import React, { useState } from "react";
import axios from "axios"; // Import axios for making API calls
import { Button } from "../../components/Button";
import { VuesaxBoldCloseCircle } from "../../icons/VuesaxBoldCloseCircle";
import { Input } from "../Input";
import { Icon10 } from "../../icons/Icon10";
import { Icon11 } from "../../icons/Icon11";
import "./style.css";

export const ModalAddNew = ({ onClose, onSave }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [dob, setDob] = useState("");

  const handleSave = () => {
    const newPatient = {
      "firstname": firstName,
      "lastname": lastName,
      "email_id": email,
      "phone_number": phone,
      "address": address,
      "city": city,
      "state": state,
      "zip": zip,
      "dob": dob,
    };

    onSave(newPatient);
  };

  return (
    <div className="modal-add-new">
      <div className="title">
        <div className="text-wrapper-2">Add New Patient</div>
        <Button
          className="button-2"
          override={<VuesaxBoldCloseCircle className="vuesax-bold-close-circle" color="#7E92A2" />}
          style="white"
          type="icon-only"
          onClick={onClose}
        />
      </div>
      <div className="content-SCROLL">
        <div className="form">
          <div className="div-4">
            <Input
              className="input-instance"
              inputClassName="input-2"
              label="First Name *"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Input
              className="input-instance"
              inputClassName="input-2"
              label="Last Name *"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="div-4">
            <Input
              className="input-instance"
              divClassName="input-3"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              className="input-instance"
              divClassName="input-3"
              label="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <Input
            className="input-4"
            label="Street address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <div className="div-4">
            <Input
              className="input-instance"
              label="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <Input
              className="state-2"
              divClassNameOverride="state"
              label="State / Province"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
            <Input
              className="zip-code"
              divClassNameOverride="zip-code-2"
              label="Zip Code"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
            />
          </div>
          <div className="div-4">
            <Input
              className="input-instance"
              divClassName="input-3"
              icon={<Icon11 className="icon-11" />}
              label="Date of birth"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="actions">
        <div className="buttons">
          <Button
            className="button-cancel"
            style="white"
            text="Cancel"
            type="default"
            onClick={onClose}
          />
          <Button
            className="add-new"
            style="primary"
            text="Save Patient"
            type="default"
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
};
