import React, { useState } from "react";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Dropdown } from "../../components/Dropdown";
import { StatusTag } from "../../components/StatusTag";
import { VuesaxBoldCloseCircle } from "../../icons/VuesaxBoldCloseCircle";
import "./style.css";

export const ChangeStatus = ({ status, onClose, onSave }) => {
  const [description, setDescription] = useState("");
  const [dropReason, setDropReason] = useState("");

  return (
    <div className="change-status">
      <div className="title-2">
        <div className="text-wrapper-17">Change Status</div>
        <Button
          className="button-2"
          override={<VuesaxBoldCloseCircle className="vuesax-bold-close-circle" color="#7E92A2" />}
          style="white"
          type="icon-only"
          onClick={() => onClose()}
        />
      </div>
      <div className="content-SCROLL">
        {status && (
            <StatusTag
              badgeClassName="status-tag-2"
              className="status-tag-instance"
              property={status}
            />
          )}
       {status === "DROPPED" && (
          <Dropdown
            className="input-instance"
            label="Select drop reason"
            placeholder="Select drop reason"
            options={["Lack of funds", "Not enough capacity", "Processing delays"]}
            onSelect={(option) => setDropReason(option)}
          />
          )}
        <Input
          className="input-2"
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="action-wrapper">
        <div className="action-2">
          <Button
            className="button-7"
            style="primary"
            text="Confirm"
            type="default"
            onClick={() => onSave({ status, description, 'drop_reason': dropReason })}
          />
        </div>
      </div>
    </div>
  );
};
