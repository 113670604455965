import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button";
import { InputWithDatePicker } from "../../components/InputWithDatePicker";
import { Dropdown } from "../../components/Dropdown";
import { formatDateToReadableDate } from "../../Utils/utils";
import { DetailDataCell } from "../../components/DetailDataCell";
import { LeftNavItemsFor } from "../../components/LeftNavItemsFor";
import { VuesaxOutlineFlag4 } from "../../icons/VuesaxOutlineFlag4";
import { VuesaxBoldCloseCircle } from "../../icons/VuesaxBoldCloseCircle";
import { getCapacityData } from "../../services/censusApi";
import "./style.css";

export const MultiStepProcess = ({ onClose, onFinish }) => {
  const [activeStep, setActiveStep] = useState(0);

  const [facilities, setFacilities] = useState([]);
  const [clinicians, setClinicians] = useState([]);
  const [facility, setFacility] = useState("");
  const [duration, setDuration] = useState("");
  const [admitDate, setAdmitDate] = useState("");
  const [dischargeDate, setDischargeDate] = useState("");
  const [md, setMD] = useState("");
  const [therapist, setTherapist] = useState("");
  const [clinicalCounselor, setCC] = useState("");

  const [isFinishDisabled, setIsFinishDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadfacilityData = async () => {
      try {
        const data = await getCapacityData();
        setFacilities(data.capacity_facility);
        setClinicians(data.capacity_clinician);
      } catch (err) {
        setError("Error fetching facility data.");
        console.error(err);
      } finally {
        setLoading(false);
      }

    };
    loadfacilityData();
  }, []);

  useEffect(() => {
    const allStepsCompleted = facility && duration && admitDate;
    setIsFinishDisabled(!allStepsCompleted);
  }, [facility, duration, admitDate]);

  useEffect(() => {
    if (admitDate && duration) {
      if (duration === 'N/A')
      {
        return;
      }
      const dischargeDate = new Date(admitDate);
      dischargeDate.setDate(dischargeDate.getDate() + parseInt(duration, 10));
      setDischargeDate(dischargeDate.toISOString().split('T')[0]); // Format as YYYY-MM-DD
    }
  }, [admitDate, duration]);

  const handleFinish = () => {
    const processData = {
      "facility": facility,
      "duration": duration,
      "scheduled_admit_date": admitDate,
      ...(dischargeDate && { "scheduled_discharge_date": dischargeDate }),
      "md": md,
      "therapist": therapist,
      "cc": clinicalCounselor,
    };

    if (onFinish) {
      onFinish(processData);
    }

    onClose();
  };

  const steps = [
    {
      title: "Basic requirements",
      content: (
        <>
          <Dropdown
            className="input-instance"
            label="Select facility"
            placeholder="Select facility"
            options={facilities.map(facility => facility.name)}
            onSelect={(option) => setFacility(option)}
          />
          <Dropdown
            className="input-instance"
            label="Select duration in days"
            placeholder="Select duration"
            options={["10", "30", "60", "N/A"]}
            onSelect={(option) => setDuration(option)}
          />
          <div className="callout">
            <VuesaxOutlineFlag4 className="vuesax-outline-info" color="#7E92A2" />
            <p className="message">
              <span className="span">Conditions like OCD require more staff. Keep in mind for clinician allocation.&nbsp;&nbsp;</span>
            </p>
          </div>
        </>
      ),
    },
    {
      title: "Select admit date",
      content: (
        <>
          <InputWithDatePicker
            className="input-instance"
            label="Select admit date"
            onDateChange={(date) => setAdmitDate(date.toISOString().split('T')[0])}
          />
          <div className="callout">
            <VuesaxOutlineFlag4 className="vuesax-outline-info" color="#7E92A2" />
            {/* <p className="message">
              <span className="span">Earliest date available is June 21st based on current criteria.&nbsp;&nbsp;</span>
            </p> */}
          </div>
        </>
      ),
    },
    {
      title: "Assign clinicians",
      content: (
        <>
          <Dropdown
            className="input-instance"
            label="Select MD"
            placeholder="Select MD"
            options={clinicians.filter(c => c.type === "MD").map(c => `${c.firstname} ${c.lastname}`)}
            onSelect={(option) => setMD(option)}
          />
          <Dropdown
            className="input-instance"
            label="Select therapist"
            placeholder="Select therapist"
            options={clinicians.filter(c => c.type === "Therapist").map(c => `${c.firstname} ${c.lastname}`)}
            onSelect={(option) => setTherapist(option)}
          />
          <Dropdown
            className="input-instance"
            label="Select clinical counselor"
            placeholder="Select clinical counselor"
            options={clinicians.filter(c => c.type === "CC").map(c => `${c.firstname} ${c.lastname}`)}
            onSelect={(option) => setCC(option)}
          />
        </>
      ),
    },
    {
      title: "Confirm and finish",
      content: (
        <>
          <div className="selection-inputs-4">
            <div className="div-3">
              <DetailDataCell className="detail-data-cell-v" label="Facility" value={facility} visible={false} />
              <DetailDataCell className="detail-data-cell-v" label="Duration" value={duration} visible={false} />
            </div>
            <div className="div-3">
              <DetailDataCell className="detail-data-cell-v" label="Admit Date" value={admitDate} visible={false} />
              <DetailDataCell className="detail-data-cell-v" label="Discharge Date" value={dischargeDate} visible={false} /> {/* Display discharge date */}
            </div>
            <div className="div-3">
              <DetailDataCell className="detail-data-cell-v" label="MD" value={md} visible={false} />
              <DetailDataCell className="detail-data-cell-v" label="Therapist" value={therapist} visible={false} />
              <DetailDataCell
                className="detail-data-cell-v"
                label="Clinical Counselor"
                value={clinicalCounselor}
                visible={false}
              />
            </div>
          </div>
          <Button
            className="button-instance"
            style="primary"
            text="Finish"
            type="default"
            disabled={isFinishDisabled} // Disable the button if not all fields are filled
            onClick={handleFinish} // Call handleFinish on click
          />
        </>
      ),
    },
  ];

  if (loading) return <div className="spinner"></div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="multi-step-process">
      <LeftNavItemsFor
        className="left-nav-items-for"
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={steps.map((step) => step.title)}
      />
      <Button
          className="button-2"
          override={<VuesaxBoldCloseCircle className="vuesax-bold-close-circle" color="#7E92A2" />}
          style="white"
          type="icon-only"
          onClick={() => onClose()}
        />
      <div className="right-section">
        <div className="flag-and-title">
          <VuesaxOutlineFlag4 className="vuesax-outline-flag" />
          <div className="title-text">{steps[activeStep].title}</div>
        </div>
        <div className="selection-inputs">{steps[activeStep].content}</div>
        {activeStep < steps.length - 1 && (
          <Button
            className="button-instance"
            style="primary"
            text="Next"
            type="default"
            onClick={() => setActiveStep(activeStep + 1)}
          />
        )}
      </div>
    </div>
  );
};
