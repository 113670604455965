import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const CapacityTabs = ({ className,
  text,
  text1,
  text2}) => {
  return (
    <div className={`capacity-tabs ${className}`}>
      {text && <div className="email-field">{text}</div>}
      {text1 && <div className="email-field">{text1}</div>}
      {text2 && <div className="email-field">{text2}</div>}
    </div>
  );
};

CapacityTabs.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
};
