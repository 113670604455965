import PropTypes from "prop-types";
import React from "react";
import { Logo1 } from "../../icons/Logo1";
import { Base } from "../Base";
import { Button } from "../Button";
import { Dropdown } from "../Dropdown";
import "./style.css";

export const TopNavigationBar = ({
  className,
  text = "",
  buttonIcon,
  buttonText,
  baseBaseClassName,
  onButtonClick,
  dropdownText,
  callBack,
  processing = false,
  processingStatus,
}) => {
  return (
    <div className={`top-navigation-bar ${className}`}>
      <div className="top-part">
        <div className="logo">
          <Base className="base-instance" />
          <Logo1 className="logo-1" color="black" />
        </div>
        <div className="left-bar">
          <div className="top-nav-title">{text}</div>
        </div>
        <div className="right-bar">
          {buttonText && (
            <Button
              className="add-new"
              style="primary"
              text={buttonText}
              type="right-icon"
              onClick={onButtonClick}
              {...(buttonIcon && { icon: buttonIcon })}
            />
          )}
          {processing && (
            <div className="top-nav-status-bar-container">
              <div className={`top-nav-status-text ${processingStatus.toLowerCase()}`}>
                {processingStatus === "Processing" ? "Processing Document" : processingStatus}
              </div>
              {processingStatus === "Processing" && (
                <div className="top-nav-progress-bar">
                  <div className="top-nav-progress"></div>
                </div>
              )}
            </div>
          )}
          {dropdownText && (
            <Dropdown
              className="input-instance"
              placeholder="Actions"
              options={["Send to EHR"]}
              onSelect={(option) => callBack(option)}
            />
          )}
        </div>
      </div>
      <Base className={baseBaseClassName} />
    </div>
  );
};

TopNavigationBar.propTypes = {
  text: PropTypes.string,
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.element,
  onButtonClick: PropTypes.func,
};
