import React, { useState, useEffect } from "react";
import { Check1 } from "../../icons/Check1";
import { VuesaxOutlineCloseCircle1 } from "../../icons/VuesaxOutlineCloseCircle1";
import "./style.css";

export const NotificationPopUp = ({ message, status, iconColor, closeIconColor, duration = 3000, onClose }) => {
  const [visible, setVisible] = useState(false);

  // Function to trigger notification and ensure it resets properly
  const triggerNotification = () => {
    setVisible(false); // Ensure it's hidden first
    setTimeout(() => setVisible(true), 100); // Then show it after a brief delay
  };

  useEffect(() => {
    if (visible) {
      // Set a timeout to hide the notification after `duration` milliseconds
      const timer = setTimeout(() => {
        setVisible(false); // Hide notification after the duration
        if (onClose) onClose(); // Call onClose callback if passed
      }, duration + 2000); // Keep it on screen for `duration` and allow extra time for sliding out

      // Cleanup the timer on unmount
      return () => clearTimeout(timer);
    }
  }, [visible, duration, onClose]);

  useEffect(() => {
    // Trigger notification when message or status changes
    if (message || status) {
      triggerNotification();
    }
  }, [message, status]);

  return (
    <div className={`notification ${visible ? 'notification-active' : ''}`}>
      {status === "success" ? (
        <Check1 className="icon-instance" color={iconColor} />
      ) : null}
      <div className="text-wrapper">{message}</div>
      <VuesaxOutlineCloseCircle1
        className="icon-close"
        color={closeIconColor || "white"}
        onClick={() => {
          setVisible(false); // Close notification manually
          if (onClose) onClose();
        }}
      />
    </div>
  );
};
