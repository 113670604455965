import React from "react";
import { SidebarNavigation } from "../../components/SidebarNavigation";
import { TopNavigationBar } from "../../components/TopNavigationBar";
import "./style.css";

export const Dashboard = () => {
  return (
    <div className="dashboard-wrapper">
      <TopNavigationBar
        baseBaseClassName="top-navigation-bar-instance"
        className="top-navigation-bar-v3"
        text="Dashboard"
      />
      <div className="side-nav-and-body">
        <SidebarNavigation className="sidebar-navigation-v3" />
        <div className="coming-soon-container">
          <p className="coming-soon-text">Coming Soon!</p>
        </div>
      </div>
    </div>
  );
};
