/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const VuesaxOutlineArrowDown = ({ color = "#292D32", className }) => {
  return (
    <svg
      className={`vuesax-outline-arrow-down ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12.4995 16.7999C11.7995 16.7999 11.0995 16.5299 10.5695 15.9999L4.04953 9.47989C3.75953 9.18989 3.75953 8.70989 4.04953 8.41989C4.33953 8.12989 4.81953 8.12989 5.10953 8.41989L11.6295 14.9399C12.1095 15.4199 12.8895 15.4199 13.3695 14.9399L19.8895 8.41989C20.1795 8.12989 20.6595 8.12989 20.9495 8.41989C21.2395 8.70989 21.2395 9.18989 20.9495 9.47989L14.4295 15.9999C13.8995 16.5299 13.1995 16.7999 12.4995 16.7999Z"
        fill={color}
      />
    </svg>
  );
};

VuesaxOutlineArrowDown.propTypes = {
  color: PropTypes.string,
};
