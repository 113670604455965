
export function formatDateTimeToReadableDateTime(dateTimeString) {
  const date = new Date(dateTimeString);

  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
}

export const formatDateToReadableDate = (date) => {
  if (!date) return "";
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};


export function formatDateTimeToReadableDateTimeWithoutTime(dateTimeString) {
  const date = new Date(dateTimeString);

  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
}