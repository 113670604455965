import { axiosInstance, setAuthHeaders, handleResponse, handleError } from './apiUtils';
import { useNavigate } from 'react-router-dom';

export const getActivityRecord = async (opportunity_id) => {

    setAuthHeaders();
     var payload = {
        "opportunity_id": opportunity_id
      }

    const response = await axiosInstance.get('/api/activity_record', { params: payload });
    return handleResponse(response);
};

export const saveActivity = async (activity) => {
  setAuthHeaders();
  const response = await axiosInstance.post('/api/activity_record', activity);
  return handleResponse(response);
};

export const askQuestion = async (questionData) => {
  setAuthHeaders();
  const response = await axiosInstance.post('/api/ask_neo', questionData);
  return handleResponse(response);
};