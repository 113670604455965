import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import { VuesaxOutlineArrowDown } from "../../icons/VuesaxOutlineArrowDown";
import "./style.css";

export const StatusTag = ({ property, className, badgeClassName, onStatusChange, isADropdown = false, closeDropdown }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleStatusSelect = (status) => {
    setIsDropdownOpen(false);
      onStatusChange(status);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (closeDropdown) {
      setIsDropdownOpen(false);
    }
  }, [closeDropdown]);

  const statusOptions = [
    "PENDING",
    "ADMIN",
    "SCHEDULED-ADMIT",
    "ADMITTED",
    "DROPPED",
    "DISCHARGED",
  ];

  return (
    <div className={`status-tag ${property} ${className}`} onClick={toggleDropdown} ref={dropdownRef}>
      <div className={`badge-4 ${badgeClassName}`}>
        {property.toUpperCase()}
        {isADropdown && <VuesaxOutlineArrowDown className="icon-dropdown" color="#7E92A2" />}
      </div>
      {isADropdown && isDropdownOpen && (
        <div className="status-dropdown-menu">
          {statusOptions.map((status, index) => (
            <div
              key={index}
              className="status-dropdown-item"
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'var(--greygrey-10)')}
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
              onClick={() => handleStatusSelect(status)}
            >
              {status}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

StatusTag.propTypes = {
  property: PropTypes.oneOf([
    "DISCHARGED",
    "ADMITTED",
    "ADMIN",
    "DROPPED",
    "PENDING",
    "SCHEDULED-ADMIT",
  ]),
  onStatusChange: PropTypes.func.isRequired,
  closeDropdown: PropTypes.bool,
};
