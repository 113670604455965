/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconEdit = ({ className }) => {
  return (
    <svg
      className={`icon-edit ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M4.95016 16.2657C4.44183 16.2657 3.96683 16.0907 3.62516 15.7657C3.19183 15.3573 2.9835 14.7406 3.0585 14.074L3.36683 11.374C3.42516 10.8657 3.7335 10.1906 4.09183 9.82398L10.9335 2.58232C12.6418 0.773983 14.4252 0.723983 16.2335 2.43232C18.0418 4.14065 18.0918 5.92398 16.3835 7.73232L9.54183 14.974C9.19183 15.349 8.54183 15.699 8.0335 15.7823L5.35016 16.2407C5.2085 16.249 5.0835 16.2657 4.95016 16.2657ZM13.6085 2.42398C12.9668 2.42398 12.4085 2.82398 11.8418 3.42398L5.00016 10.674C4.8335 10.849 4.64183 11.2657 4.6085 11.5073L4.30016 14.2073C4.26683 14.4823 4.3335 14.7073 4.4835 14.849C4.6335 14.9907 4.8585 15.0407 5.1335 14.999L7.81683 14.5407C8.0585 14.499 8.4585 14.2823 8.62516 14.1073L15.4668 6.86565C16.5002 5.76565 16.8752 4.74898 15.3668 3.33232C14.7002 2.69065 14.1252 2.42398 13.6085 2.42398Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M14.7835 9.12398C14.7668 9.12398 14.7418 9.12398 14.7252 9.12398C12.1252 8.86565 10.0335 6.89065 9.6335 4.30732C9.5835 3.96565 9.81683 3.64898 10.1585 3.59065C10.5002 3.54065 10.8168 3.77398 10.8752 4.11565C11.1918 6.13232 12.8252 7.68232 14.8585 7.88232C15.2002 7.91565 15.4502 8.22398 15.4168 8.56565C15.3752 8.88232 15.1002 9.12398 14.7835 9.12398Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M17.8335 18.9573H2.8335C2.49183 18.9573 2.2085 18.674 2.2085 18.3323C2.2085 17.9907 2.49183 17.7073 2.8335 17.7073H17.8335C18.1752 17.7073 18.4585 17.9907 18.4585 18.3323C18.4585 18.674 18.1752 18.9573 17.8335 18.9573Z"
        fill="#7E92A2"
      />
    </svg>
  );
};
