import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export const PatientRow = ({
  className,
  text = "Deanna",
  text1 = "Annis",
  text2 = "goeorgegamble@gmail.com",
  text3 = "2213 Thorn Street,",
  text4 = "Glenrock,",
  text5 = "WY",
  text6 = "12345",
  inputType = "email",
  text7 = "555",
  patientId,
}) => {
  return (
    <Link to={`/patient/${patientId}`} className={`patient-row ${className}`}>
      <div className="cells">
        <div className="name-cell">
          <div className="name-concat">
            <div className="text-wrapper-6">{text}</div>
            <div className="text-wrapper-6">{text1}</div>
          </div>
        </div>
        <div className="email-cell">
            <div className="phone">{text2}</div>
        </div>
        <div className="phone-cell">
          <div className="phone">{text7}</div>
        </div>
        <div className="address-cell">
          <div className="address-concat">
            <div className="text-wrapper-6">{text3}</div>
            <div className="text-wrapper-6">{text4}</div>
            <div className="text-wrapper-6">{text5}</div>
            <div className="text-wrapper-6">{text6}</div>
          </div>
        </div>
      </div>
      <div className="border-2" />
    </Link>
  );
};

PatientRow.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  text5: PropTypes.string,
  text6: PropTypes.string,
  text7: PropTypes.string,
  inputType: PropTypes.string,
  patientId: PropTypes.string.isRequired,
};
