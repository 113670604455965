import React from "react";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Close } from "../../icons/Close";
import "./style.css";

export const ListOf = ({ onClose, data, facilityCounts }) => {
  return (
    <div className="list-of">
      <div className="opportunity-list">
        <div className="opp-name-and-status">
          <div className="street">{data.metric} on {data.date}</div>
          <Close
            className="icon-instance-node"
            color="#7E92A2"
            onClick={onClose}
          />
        </div>
      </div>
      <div className="street-wrapper">
        <p className="text-wrapper">See opportunities per your selection. Click to see detail view.</p>
      </div>

      {facilityCounts && Object.keys(facilityCounts).length > 0 ? (
        Object.entries(facilityCounts).map(([facility, count], index) => (
          <div key={index} className="opportunity-snapshot-2">
            <div className="opp-name-and-status-2">
              <div className="street-3">{facility}</div>
              <div className="city">
                {data.metric === "Empty"
                  ? data.total_facility_counts.find(facilityItem => facilityItem.name === facility)?.size - count
                  : count}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="street-wrapper">
          <p className="text-wrapper">No facility data available.</p>
        </div>
      )}

      {data && data.details && data.details.length > 0 && data.metric !== "Empty" ? (
        data.details.map((opportunity, index) => (
          <Link key={index} to={`/opportunity/${opportunity.id}`} className="deal-repeat-link">
           <div className="opportunity-snapshot-2">
            <div className="opp-name-and-status-2">
              <p className="street-2">{opportunity.opportunity_name}</p>
            </div>
            <div className="info-2">
              <div className="text-wrapper-2">{opportunity.condition}</div>
              <div className="text-wrapper-2">•</div>
              <div className="issue">
                <div className="text-wrapper-2">{opportunity.facility}</div>
              </div>
            </div>
            </div>
          </Link>
        ))
      ) : (
        <div className="street-wrapper">
          <p className="text-wrapper">No opportunity data available.</p>
        </div>
      )}
    </div>
  );
};

ListOf.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    details: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        opportunity_name: PropTypes.string,
        condition: PropTypes.string,
        facility: PropTypes.string,
      })
    ),
  }),
  facilityCounts: PropTypes.objectOf(PropTypes.number),
};
