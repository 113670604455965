import React from "react";
import { CapacityTabs } from "../../components/CapacityTabs";
import { CardIntegration } from "../../components/CardIntegration";
import { SidebarNavigation } from "../../components/SidebarNavigation";
import { TopNavigationBar } from "../../components/TopNavigationBar";
import { VuesaxOutlineElement4 } from "../../icons/VuesaxOutlineElement4";
import { VuesaxOutlineStatusUp } from "../../icons/VuesaxOutlineStatusUp";
import { saveConfiguration } from "../../services/configurationApi";
import "./style.css";

export const Configurations = () => {

  const handleSave = async (config) => {
    try {
      const payload = await saveConfiguration(config);
    } catch (error) {
      console.error("Error saving configuration", error);
    }
  };

  return (
    <div className="configurations">
      <TopNavigationBar className="top-navigation-bar-v3" text="Configurations" baseBaseClassName="top-navigation-bar-instance" />
      <div className="side-nav-and-body">
        <SidebarNavigation className="sidebar-navigation-v3" />
        <div className="body-patients-view">
          <div className="content">
            <div className="title-and-row">
              <div className="frame">
                <div className="label-wrapper">
                  <div className="label-2">EHRs</div>
                </div>
              </div>
              <div className="row-widgets">
                <CardIntegration onSave={handleSave} />
              </div>
            </div>
            {/*
            <div className="title-and-row">
              <div className="frame">
                <div className="label-wrapper">
                  <div className="label-2">CRMs</div>
                </div>
              </div>
              <div className="row-widgets">
                <CardIntegration />
                <CardIntegration />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
