import { axiosInstance, setAuthHeaders, handleResponse, handleError } from './apiUtils';
import { useNavigate } from 'react-router-dom';

export const login = async (email, password) => {
  try {
    const response = await axiosInstance.post('/auth/login', { email_id : email, password : password });
    if (response.data.status_code === 200 || response.data.status_code === 201) {
        var responseJson = JSON.parse(response.data.result);
        localStorage.setItem('newresiliencetoken', responseJson.result.token);
        localStorage.setItem('initials', responseJson.result.initials);
      } else if (response.data.status_code === 401) {
        handleError('Incorrect email or password');
      } else {
        handleError("Something went wrong");
      }
    return responseJson.result;
  } catch (error) {
    handleError(error);
  }
};

export const sendResetPasswordEmail = async (email) => {
    const response = await axiosInstance.post('/auth/request_password_reset', { email_id : email });
    if (response.data.status_code === 200 || response.data.status_code === 201) {
        var responseJson = JSON.parse(response.data.result);
         return responseJson.result;
      }
     else {
        handleError("Something went wrong");
      }
    return responseJson.result;
};

export const handleResetPassword = async (token, newPassword) => {
      const response = await axiosInstance.post('/auth/change_password', {
        token: token,
        new_password: newPassword,
      });
     if (response.data.status_code === 200 || response.data.status_code === 201) {
        var responseJson = JSON.parse(response.data.result);
         return responseJson.result;
       }
      else {
        handleError('Failed to reset password.');
      }
      return responseJson.result;
  };

export const checkTokenValidity = async (token) => {
      const response = await axiosInstance.get(`/auth/change_password?token=${token}`);
      if (response.data.status_code === 200 || response.data.status_code === 201) {
        return true;
      } else {
        return false;
      }
};

export const testAuth = async () => {
    setAuthHeaders();
    const response = await axiosInstance.get('/api/test');
    return handleResponse(response);
};

export const logOut = () => {
   localStorage.removeItem('newresiliencetoken');
};