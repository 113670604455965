/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const VuesaxOutlineAttachSquare = ({ color = "#292D32", className }) => {
  return (
    <svg
      className={`vuesax-outline-attach-square ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M10.1661 14.4834C9.58281 14.4834 8.99115 14.2584 8.54948 13.8168C8.11615 13.3834 7.87448 12.8084 7.87448 12.2001C7.87448 11.5918 8.11615 11.0084 8.54948 10.5834L9.72446 9.40844C9.96612 9.16678 10.3661 9.16678 10.6078 9.40844C10.8495 9.65011 10.8495 10.0501 10.6078 10.2918L9.43281 11.4668C9.23281 11.6668 9.12448 11.9251 9.12448 12.2001C9.12448 12.4751 9.23281 12.7418 9.43281 12.9334C9.84114 13.3418 10.4995 13.3418 10.9078 12.9334L12.7578 11.0834C13.8161 10.0251 13.8161 8.30845 12.7578 7.25011C11.6995 6.19178 9.98282 6.19178 8.92449 7.25011L6.90779 9.26676C6.48279 9.69176 6.24947 10.2501 6.24947 10.8418C6.24947 11.4334 6.48279 12.0001 6.90779 12.4168C7.14945 12.6584 7.14945 13.0584 6.90779 13.3001C6.66612 13.5418 6.26612 13.5418 6.02445 13.3001C5.36612 12.6418 5.00781 11.7668 5.00781 10.8334C5.00781 9.9001 5.36612 9.02511 6.02445 8.36678L8.04116 6.3501C9.58282 4.80843 12.0995 4.80843 13.6411 6.3501C15.1828 7.89176 15.1828 10.4084 13.6411 11.9501L11.7912 13.8001C11.3412 14.2584 10.7578 14.4834 10.1661 14.4834Z"
        fill={color}
      />
      <path
        className="path"
        d="M12.4993 18.9584H7.49935C2.97435 18.9584 1.04102 17.0251 1.04102 12.5001V7.50008C1.04102 2.97508 2.97435 1.04175 7.49935 1.04175H12.4993C17.0243 1.04175 18.9577 2.97508 18.9577 7.50008V12.5001C18.9577 17.0251 17.0243 18.9584 12.4993 18.9584ZM7.49935 2.29175C3.65768 2.29175 2.29102 3.65841 2.29102 7.50008V12.5001C2.29102 16.3417 3.65768 17.7084 7.49935 17.7084H12.4993C16.341 17.7084 17.7077 16.3417 17.7077 12.5001V7.50008C17.7077 3.65841 16.341 2.29175 12.4993 2.29175H7.49935Z"
        fill="#292D32"
      />
    </svg>
  );
};

VuesaxOutlineAttachSquare.propTypes = {
  color: PropTypes.string,
};
