import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconAnalytics } from "../../icons/IconAnalytics";
import { IconCapacity } from "../../icons/IconCapacity";
import { IconOpportunities } from "../../icons/IconOpportunities";
import { IconPatients } from "../../icons/IconPatients";
import { IconReferrals1 } from "../../icons/IconReferrals1";
import { IconConfiguration } from "../../icons/IconConfiguration";
import { IconTasks } from "../../icons/IconTasks";
import { VuesaxOutlineElement4 } from "../../icons/VuesaxOutlineElement4";
import { Base } from "../Base";
import { logOut } from "../../services/loginApi"
import { SideNavButton } from "../SideNavButton";
import "./style.css";

export const SidebarNavigation = ({
  className,
  sideNavButtonButtonIcon = <IconTasks className="icon-instance-node" />,
  sideNavButtonButtonIcon0 = <IconOpportunities className="icon-instance-node" />,
  sideNavButtonButtonIcon1 = <IconPatients className="icon-instance-node" />,
  sideNavButtonButtonIcon2 = <IconTasks className="icon-instance-node" />,
  sideNavButtonButtonIcon3 = <IconCapacity className="icon-instance-node" color="#7E92A2" />,
  sideNavButtonButtonIcon4 = <IconReferrals1 className="icon-instance-node" color="#7E92A2" />,
  sideNavButtonButtonIcon5 = <IconAnalytics className="icon-instance-node" color="#7E92A2" />,
  sideNavButtonButtonIcon6 = <IconConfiguration className="icon-instance-node" color="#7E92A2" />,

}) => {
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState(null);

  const handleButtonClick = (button, path) => {
    setSelectedButton(button);
    navigate(path);
  };

  const handleLogout = (button, path) => {
    setSelectedButton(button);
    logOut();
    navigate(path);
  };

  return (
    <div className={`sidebar-navigation ${className}`}>
      <div className="vertical-sections">
        <div className="menu">
          <SideNavButton
            buttonIcon={sideNavButtonButtonIcon}
            className={`side-nav-button-instance ${selectedButton === "Tasks" ? "active" : ""}`}
            text="Tasks"
            onClick={() => handleButtonClick("Tasks", "/tasks")}
          />
          <SideNavButton
            buttonIcon={sideNavButtonButtonIcon0}
            className={`side-nav-button-instance ${selectedButton === "Opportunities" ? "active" : ""}`}
            text="Opportunities"
            onClick={() => handleButtonClick("Opportunities", "/opportunities")}
          />
          <SideNavButton
            buttonIcon={sideNavButtonButtonIcon1}
            className={`side-nav-button-instance ${selectedButton === "Patients" ? "active" : ""}`}
            text="Patients"
            onClick={() => handleButtonClick("Patients", "/patients")}
          />
          {/*
          <SideNavButton
            buttonIcon={sideNavButtonButtonIcon2}
            className={`side-nav-button-instance ${selectedButton === "Tasks" ? "active" : ""}`}
            text="Tasks"
            onClick={() => handleButtonClick("Tasks", "/tasks")}
          />
          */}
          <SideNavButton
            buttonIcon={sideNavButtonButtonIcon3}
            className={`side-nav-button-instance ${selectedButton === "Capacity" ? "active" : ""}`}
            text="Capacity"
            onClick={() => handleButtonClick("Capacity", "/capacity")}
          />
          <SideNavButton
            buttonIcon={sideNavButtonButtonIcon4}
            className={`side-nav-button-instance ${selectedButton === "Outreach" ? "active" : ""}`}
            text="Outreach"
            onClick={() => handleButtonClick("Outreach", "/outreach")}
          />
          <SideNavButton
            buttonIcon={sideNavButtonButtonIcon5}
            className={`side-nav-button-instance ${selectedButton === "Analytics" ? "active" : ""}`}
            text="Analytics"
            onClick={() => handleButtonClick("Analytics", "/analytics")}
          />
          {/* <SideNavButton
            buttonIcon={sideNavButtonButtonIcon6}
            className={`side-nav-button-instance ${selectedButton === "Configurations" ? "active" : ""}`}
            text="Configurations"
            onClick={() => handleButtonClick("Configurations", "/configurations")}
          /> */ }
        </div>
        <SideNavButton
            className={`side-nav-button-instance`}
            buttonText={localStorage.getItem('initials') || 'N/A'}
            dropdown={[
                { label: "Logout", onClick: () => handleLogout("", "/login") },
                { label: "Settings", onClick: () => handleButtonClick("Settings", "/settings") },
              ]}

         />
      </div>
      <Base className="design-component-instance-node" />
    </div>
  );
};
