/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icon6 = ({ className }) => {
  return (
    <svg
      className={`icon-6 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 17 16"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M8.79952 11.5867C8.33285 11.5867 7.85952 11.4067 7.50619 11.0534C7.15952 10.7067 6.96619 10.2467 6.96619 9.76004C6.96619 9.27338 7.15952 8.80671 7.50619 8.46671L8.44617 7.5267C8.6395 7.33337 8.9595 7.33337 9.15283 7.5267C9.34617 7.72004 9.34617 8.04004 9.15283 8.23337L8.21285 9.17337C8.05285 9.33337 7.96619 9.54004 7.96619 9.76004C7.96619 9.98004 8.05285 10.1934 8.21285 10.3467C8.53952 10.6734 9.0662 10.6734 9.39286 10.3467L10.8728 8.86669C11.7195 8.02002 11.7195 6.64671 10.8728 5.80004C10.0262 4.95337 8.65286 4.95337 7.80619 5.80004L6.19283 7.41336C5.85283 7.75336 5.66618 8.20004 5.66618 8.67337C5.66618 9.14671 5.85283 9.60003 6.19283 9.93336C6.38616 10.1267 6.38616 10.4467 6.19283 10.64C5.9995 10.8334 5.6795 10.8334 5.48617 10.64C4.9595 10.1134 4.67285 9.41337 4.67285 8.6667C4.67285 7.92003 4.9595 7.22004 5.48617 6.69337L7.09953 5.08003C8.33286 3.8467 10.3462 3.8467 11.5795 5.08003C12.8128 6.31336 12.8128 8.3267 11.5795 9.56003L10.0995 11.04C9.73953 11.4067 9.27285 11.5867 8.79952 11.5867Z"
        fill="#526477"
      />
      <path
        className="path"
        d="M10.6667 15.1667H6.66667C3.04667 15.1667 1.5 13.62 1.5 10V6.00004C1.5 2.38004 3.04667 0.833374 6.66667 0.833374H10.6667C14.2867 0.833374 15.8333 2.38004 15.8333 6.00004V10C15.8333 13.62 14.2867 15.1667 10.6667 15.1667ZM6.66667 1.83337C3.59333 1.83337 2.5 2.92671 2.5 6.00004V10C2.5 13.0734 3.59333 14.1667 6.66667 14.1667H10.6667C13.74 14.1667 14.8333 13.0734 14.8333 10V6.00004C14.8333 2.92671 13.74 1.83337 10.6667 1.83337H6.66667Z"
        fill="#526477"
      />
    </svg>
  );
};
