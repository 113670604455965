/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const IconOpenNewTab3 = ({ color = "#1E88E5", className }) => {
  return (
    <svg
      className={`icon-open-new-tab-3 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 15 16"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M8.12502 7.84377C8.00627 7.84377 7.88752 7.80002 7.79377 7.70627C7.61252 7.52502 7.61252 7.22502 7.79377 7.04377L12.9188 1.91877C13.1 1.73752 13.4 1.73752 13.5813 1.91877C13.7625 2.10002 13.7625 2.40002 13.5813 2.58127L8.45627 7.70627C8.36252 7.80002 8.24377 7.84377 8.12502 7.84377Z"
        fill={color}
      />
      <path
        className="path"
        d="M13.75 5.21875C13.4937 5.21875 13.2812 5.00625 13.2812 4.75V2.21875H10.75C10.4938 2.21875 10.2812 2.00625 10.2812 1.75C10.2812 1.49375 10.4938 1.28125 10.75 1.28125H13.75C14.0062 1.28125 14.2188 1.49375 14.2188 1.75V4.75C14.2188 5.00625 14.0062 5.21875 13.75 5.21875Z"
        fill={color}
      />
      <path
        className="path"
        d="M9.375 14.7188H5.625C2.23125 14.7188 0.78125 13.2688 0.78125 9.875V6.125C0.78125 2.73125 2.23125 1.28125 5.625 1.28125H6.875C7.13125 1.28125 7.34375 1.49375 7.34375 1.75C7.34375 2.00625 7.13125 2.21875 6.875 2.21875H5.625C2.74375 2.21875 1.71875 3.24375 1.71875 6.125V9.875C1.71875 12.7563 2.74375 13.7812 5.625 13.7812H9.375C12.2563 13.7812 13.2812 12.7563 13.2812 9.875V8.625C13.2812 8.36875 13.4937 8.15625 13.75 8.15625C14.0063 8.15625 14.2188 8.36875 14.2188 8.625V9.875C14.2188 13.2688 12.7688 14.7188 9.375 14.7188Z"
        fill={color}
      />
    </svg>
  );
};

IconOpenNewTab3.propTypes = {
  color: PropTypes.string,
};
