import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Checkbox } from "../Checkbox";
import "./style.css";

export const TasksTableRow = ({
  className,
  text,
  text1,
  text2,
  text3,
  date,
  status,
  taskId,
  onCheckboxClick
}) => {
  // Initialize isChecked based on whether the task is complete
  const [isChecked, setIsChecked] = useState(status === "Completed");

  const handleCheckboxClick = (e) => {
    e.preventDefault(); // Prevents navigation
    const newStatus = !isChecked;
    setIsChecked(newStatus);
    onCheckboxClick(taskId, newStatus);
  };

  return (
    <Link to={`/task/${taskId}`} className={`tasks-table-row ${className}`}>
      <div className="cells">
        <div className="room-area-55">
        <Checkbox
          className="checkbox-2"
          property1={isChecked ? "ticked-box" : "empty-box"}
          onClick={handleCheckboxClick}
        />
        <p className="street">{text1}</p>
        </div>
        <div className="room-area">{text2}</div>
        <div className="room-area">{text3}</div>
        <div className="room-area">{text}</div>
        <div className="room-area">{date}</div>
        <div className="date">{isChecked ? "Completed" : "Pending"}</div>
      </div>
      <div className="border-2" />
    </Link>
  );
};

TasksTableRow.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  onCheckboxClick: PropTypes.func.isRequired,
};
