import React from "react";
import { ProcessStep } from "../ProcessStep";
import { VuesaxOutlineClipboardText } from "../../icons/VuesaxOutlineClipboardText";
import "./style.css";

export const LeftNavItemsFor = ({ className, activeStep, setActiveStep, steps }) => {
  return (
    <div className={`left-nav-items-for ${className}`}>
      <div className="frame">
        <div className="title">
          <VuesaxOutlineClipboardText className="vuesax-outline-clipboard-text" color="#7E92A2" />
          <div className="title-text">Schedule admit</div>
        </div>
        <div className="navigation-items">
          {steps.map((step, index) => (
            <ProcessStep
              key={index}
              className="process-step-instance"
              property1={activeStep === index ? "clicked" : "default"}
              text={step}
              onClick={() => setActiveStep(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
