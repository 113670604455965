import React, { useEffect, useState } from "react";
import { Outlet, NavLink, useParams } from "react-router-dom";
import { Activity } from "../../components/Activity";
import { Base } from "../../components/Base";
import { Button } from "../../components/Button";
import { DetailPage } from "../../components/DetailPage";
import { DetailPageHeader } from "../../components/DetailPageHeader";
import { SidebarNavigation } from "../../components/SidebarNavigation";
import { TopNavigationBar } from "../../components/TopNavigationBar";
import { IconAdd } from "../../icons/IconAdd";
import { IconQna } from "../../icons/IconQna";
import { IconOpenNewTab3 } from "../../icons/IconOpenNewTab3";
import { VuesaxOutlineNoteText } from "../../icons/VuesaxOutlineNoteText";
import { VuesaxOutlineWarning2 } from "../../icons/VuesaxOutlineWarning2";
import { getAccount, updateAccount } from "../../services/accountApi";
import "./style.css";

export const Settings = () => {

  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [buttonText, setButtonText] = useState("Edit Details");

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const data = await getAccount();
        setAccount(data.account);
      } catch (error) {
        setError("Error fetching account details");
      } finally {
        setLoading(false);
      }
    };
    fetchAccount();
  }, []);

  const handleEditClick = () => {
    if (isEditable) {
      handleSave();
    } else {
      setIsEditable(true);
      setButtonText("Save Details");
    }
  };

  const handleSave = async () => {
    try {
      await updateAccount(account);
      setIsEditable(false);
      setButtonText("Edit Details");
    } catch (error) {
      console.error("Error saving account details", error);
    }
  };

  if (loading) {
    return <div className="spinner"></div>
  }

  return (
     <div className="opportunity-detail-wrapper">
      <TopNavigationBar
        className="top-navigation-bar-v3"
        text="Settings"
        baseBaseClassName="top-navigation-bar-instance"
      />
      <div className="horizontal-body-side-2">
        <SidebarNavigation className="sidebar-navigation-v3" />
        <div className="body-deal-detail-2">
          <div className="left-area-2">
          <DetailPageHeader
              key={account.firstname}
              name={`${account.firstname} ${account.lastname}`}
              buttonTypeIconOnlyStyleClassName="detail-page-header-2"
              className="detail-page-header-instance"
              onEditClick={handleEditClick}
              buttonText={buttonText}
            />
            <DetailPage
              className="design-component-instance-node-3"
              emailFieldClassName="detail-page-navigation-tabs"
              hasDiv={false}
              text={
                <NavLink
                  to={`/settings`}
                  className={({ isActive }) => (isActive ? "detail-page-link active" : "detail-page-link")}
                >
                  Account
                </NavLink>
              }
            />
            <Outlet context={{ account, setAccount, isEditable }} />
          </div>
      </div>
      <Base className="base-7" />
    </div>
    </div>
  );
};
