import React from "react";
import { useOutletContext } from "react-router-dom";
import { Input } from "../../components/Input";
import { DetailDataCell } from "../../components/DetailDataCell";
import "./style.css";

export const PatientInsurancePayment = () => {
  const { patient, setPatient, isEditable } = useOutletContext();

  const handleChange = (field, value) => {
    setPatient((prevPatient) => ({ ...prevPatient, [field]: value }));
  };

  return (
  <div className="scrollable-content-insurance-payment">
    <div className="form-for-payment">
      <div className="div-4">
        {isEditable ? (
          <Input
            className="input-instance"
            label="Payment method"
            value={patient.payment_method || ""}
            onChange={(e) => handleChange("payment_method", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Payment method"
            value={patient.payment_method || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="input-instance"
            label="Carrier"
            value={patient.carrier || ""}
            onChange={(e) => handleChange("carrier", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Carrier"
            value={patient.carrier || "N/A"}
          />
        )}
      </div>
      <div className="div-4">
        {isEditable ? (
          <Input
            className="input-instance"
            label="Subscriber ID"
            value={patient.subscriber_id || ""}
            onChange={(e) => handleChange("subscriber_id", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Subscriber ID"
            value={patient.subscriber_id || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="input-instance"
            label="Group Number"
            value={patient.group_number || ""}
            onChange={(e) => handleChange("group_number", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Group Number"
            value={patient.group_number || "N/A"}
          />
        )}
      </div>
      <div className="div-4">
        {isEditable ? (
          <Input
            className="input-instance"
            label="Subscriber First Name"
            value={patient.subscriber_firstname || ""}
            onChange={(e) => handleChange("subscriber_firstname", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Subscriber First Name"
            value={patient.subscriber_firstname || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="input-instance"
            label="Subscriber Last Name"
            value={patient.subscriber_lastname || ""}
            onChange={(e) => handleChange("subscriber_lastname", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Subscriber Last Name"
            value={patient.subscriber_lastname || "N/A"}
          />
        )}
      </div>
      <div className="div-4">
        {isEditable ? (
          <Input
            className="input-instance"
            label="Subscriber Date Of Birth"
            value={patient.subscriber_dob || ""}
            onChange={(e) => handleChange("subscriber_dob", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Subscriber Date Of Birth"
            value={patient.subscriber_dob || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="input-instance"
            label="Subscriber Social Security Number"
            value={patient.subscriber_ssn || ""}
            onChange={(e) => handleChange("subscriber_ssn", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Subscriber Social Security Number"
            value={patient.subscriber_ssn || "N/A"}
          />
        )}
      </div>
      <div className="div-4">
        {isEditable ? (
          <Input
            className="input-instance"
            label="Subscriber Employer"
            value={patient.subscriber_employer || ""}
            onChange={(e) => handleChange("subscriber_employer", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Subscriber Employer"
            value={patient.subscriber_employer || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="input-instance"
            label="Subscriber Employment Position"
            value={patient.subscriber_employment_position || ""}
            onChange={(e) => handleChange("subscriber_employment_position", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Subscriber Employment Position"
            value={patient.subscriber_employment_position || "N/A"}
          />
        )}
      </div>
      <div className="div-4">
        {isEditable ? (
          <Input
            className="input-instance"
            label="Authorization number"
            value={patient.authorization_number || ""}
            onChange={(e) => handleChange("authorization_number", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Authorization number"
            value={patient.authorization_number || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="input-2"
            label="Legal Guardianship Documents"
            value={patient.legal_guardianship_documents || ""}
            onChange={(e) => handleChange("legal_guardianship_documents", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Legal Guardianship Documents"
            value={patient.legal_guardianship_documents || "N/A"}
          />
        )}
      </div>
    </div>
   </div>
  );
};
