import React from "react";
import { Link } from "react-router-dom";
import { RecentOpportunityRow } from "../RecentOpportunityRow";
import { formatDateTimeToReadableDateTime } from "../../Utils/utils";
import "./style.css";

export const RightBarRecent = ({ opportunities, className }) => {
  return (
    <div className={`right-bar-recent ${className}`}>
      <div className="title">
        <div className="text-wrapper-3">Recent Opportunities</div>
      </div>
      <div className="customer-deal-list">
        {opportunities.map((opportunity, index) => (
          <Link
            to={`/opportunity/${opportunity.id}`}
            key={index}
            className="deal-repeat-link"
          >
            <RecentOpportunityRow
              title={opportunity.opportunity_name}
              date={formatDateTimeToReadableDateTime(opportunity.created_at)}
              amount={opportunity.opportunity_total_amount}
              status={opportunity.status.replace(/ /g, '-')}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};
