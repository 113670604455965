// LoginPage.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon2 } from "../../icons/Icon2";
import { Logo3 } from "../../icons/Logo3";
import { login } from "../../services/loginApi";
import "./style.css";

export const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const data = await login(email, password);
      if (data.token) {
        navigate("/patients");
      } else {
        setError("Invalid email or password");
      }
    } catch (error) {
      setError("An error occurred during login");
    }
  };

  return (
    <div className="login-page">
      <div className="frame-wrapper">
        <form className="frame-4" onSubmit={handleLogin}>
          <div className="frame-5">
            <Logo3 className="logo-1" />
            <div className="text-wrapper-14">New Resilience</div>
          </div>

          <div className="form-group input-2">
            <input
              id="email"
              type="email"
              placeholder=" "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="email">Email address</label>
          </div>

          <div className="form-group input-2">
            <input
              id="password"
              type="password"
              placeholder=" "
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="password">Password</label>
          </div>

          <div className="frame-7">
            <div className="placeholder-6" onClick={() => navigate("/forgot-password")}>
              Forgot password?
            </div>
          </div>

          <button className="button-primary" type="submit">
            Log in
          </button>
          {error && <div className="error-message">{error}</div>}
        </form>
      </div>
    </div>
  );
};
