import React from "react";
import { Badge } from "../Badge";
import { BadgeGreen } from "../BadgeGreen";
import { StatusTag } from "../StatusTag";
import "./style.css";

export const RecentOpportunityRow = ({ title, date, amount, status, onClick }) => {
  return (
  <div className="recent-opportunity-row">
    <div className="deal-repeat-link" onClick={onClick}>
      <div className="customer-address">
        <div className="address">
          <div className="street">{title}</div>
          {status && <StatusTag
            badgeClassName="status-tag-2"
            className="status-tag-instance"
            property={status}
          />}
        </div>
        <div className="info">
          <div className="text-wrapper-4">{date}</div>
          <div className="text-wrapper-4">•</div>
          <div className="price">
            <div className="text-wrapper-4">$</div>
            <div className="text-wrapper-4">{amount}</div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
