/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconCalendar1 = ({ className }) => {
  return (
    <svg
      className={`icon-calendar-1 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.66667 4.79175C6.325 4.79175 6.04167 4.50841 6.04167 4.16675V1.66675C6.04167 1.32508 6.325 1.04175 6.66667 1.04175C7.00833 1.04175 7.29167 1.32508 7.29167 1.66675V4.16675C7.29167 4.50841 7.00833 4.79175 6.66667 4.79175Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M13.3333 4.79175C12.9917 4.79175 12.7083 4.50841 12.7083 4.16675V1.66675C12.7083 1.32508 12.9917 1.04175 13.3333 1.04175C13.675 1.04175 13.9583 1.32508 13.9583 1.66675V4.16675C13.9583 4.50841 13.675 4.79175 13.3333 4.79175Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M7.08333 12.0834C6.975 12.0834 6.86667 12.0584 6.76667 12.0168C6.65833 11.9751 6.575 11.9167 6.49167 11.8417C6.34167 11.6834 6.25 11.4751 6.25 11.2501C6.25 11.1418 6.275 11.0334 6.31667 10.9334C6.35833 10.8334 6.41667 10.7418 6.49167 10.6584C6.575 10.5834 6.65833 10.5251 6.76667 10.4834C7.06667 10.3584 7.44167 10.4251 7.675 10.6584C7.825 10.8168 7.91667 11.0334 7.91667 11.2501C7.91667 11.3001 7.90833 11.3584 7.9 11.4168C7.89167 11.4668 7.875 11.5168 7.85 11.5668C7.83333 11.6168 7.80833 11.6668 7.775 11.7168C7.75 11.7584 7.70833 11.8001 7.675 11.8417C7.51667 11.9917 7.3 12.0834 7.08333 12.0834Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M10 12.0834C9.89167 12.0834 9.78333 12.0584 9.68333 12.0168C9.575 11.9751 9.49167 11.9167 9.40833 11.8417C9.25833 11.6834 9.16667 11.4751 9.16667 11.2501C9.16667 11.1417 9.19167 11.0334 9.23333 10.9334C9.275 10.8334 9.33333 10.7418 9.40833 10.6584C9.49167 10.5834 9.575 10.5251 9.68333 10.4834C9.98333 10.3501 10.3583 10.4251 10.5917 10.6584C10.7417 10.8168 10.8333 11.0334 10.8333 11.2501C10.8333 11.3001 10.825 11.3584 10.8167 11.4168C10.8083 11.4668 10.7917 11.5167 10.7667 11.5667C10.75 11.6167 10.725 11.6667 10.6917 11.7167C10.6667 11.7584 10.625 11.8001 10.5917 11.8417C10.4333 11.9917 10.2167 12.0834 10 12.0834Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M12.9167 12.0834C12.8083 12.0834 12.7 12.0584 12.6 12.0168C12.4917 11.9751 12.4083 11.9167 12.325 11.8417C12.2917 11.8001 12.2583 11.7584 12.225 11.7167C12.1917 11.6667 12.1667 11.6167 12.15 11.5667C12.125 11.5167 12.1083 11.4668 12.1 11.4168C12.0917 11.3584 12.0833 11.3001 12.0833 11.2501C12.0833 11.0334 12.175 10.8168 12.325 10.6584C12.4083 10.5834 12.4917 10.5251 12.6 10.4834C12.9083 10.3501 13.275 10.4251 13.5083 10.6584C13.6583 10.8168 13.75 11.0334 13.75 11.2501C13.75 11.3001 13.7417 11.3584 13.7333 11.4168C13.725 11.4668 13.7083 11.5167 13.6833 11.5667C13.6667 11.6167 13.6417 11.6667 13.6083 11.7167C13.5833 11.7584 13.5417 11.8001 13.5083 11.8417C13.35 11.9917 13.1333 12.0834 12.9167 12.0834Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M7.08333 15.0001C6.975 15.0001 6.86667 14.9751 6.76667 14.9334C6.66667 14.8918 6.575 14.8334 6.49167 14.7584C6.34167 14.6001 6.25 14.3834 6.25 14.1668C6.25 14.0584 6.275 13.9501 6.31667 13.8501C6.35833 13.7417 6.41667 13.6501 6.49167 13.5751C6.8 13.2668 7.36667 13.2668 7.675 13.5751C7.825 13.7334 7.91667 13.9501 7.91667 14.1668C7.91667 14.3834 7.825 14.6001 7.675 14.7584C7.51667 14.9084 7.3 15.0001 7.08333 15.0001Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M10 15.0001C9.78333 15.0001 9.56667 14.9084 9.40833 14.7584C9.25833 14.6001 9.16667 14.3834 9.16667 14.1668C9.16667 14.0584 9.19167 13.9501 9.23333 13.8501C9.275 13.7417 9.33333 13.6501 9.40833 13.5751C9.71667 13.2668 10.2833 13.2668 10.5917 13.5751C10.6667 13.6501 10.725 13.7417 10.7667 13.8501C10.8083 13.9501 10.8333 14.0584 10.8333 14.1668C10.8333 14.3834 10.7417 14.6001 10.5917 14.7584C10.4333 14.9084 10.2167 15.0001 10 15.0001Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M12.9167 15.0001C12.7 15.0001 12.4833 14.9084 12.325 14.7584C12.25 14.6834 12.1917 14.5918 12.15 14.4834C12.1083 14.3834 12.0833 14.2751 12.0833 14.1668C12.0833 14.0584 12.1083 13.9501 12.15 13.8501C12.1917 13.7418 12.25 13.6501 12.325 13.5751C12.5167 13.3835 12.8083 13.2918 13.075 13.3501C13.1333 13.3584 13.1833 13.3751 13.2333 13.4001C13.2833 13.4168 13.3333 13.4418 13.3833 13.4751C13.425 13.5001 13.4667 13.5418 13.5083 13.5751C13.6583 13.7335 13.75 13.9501 13.75 14.1668C13.75 14.3834 13.6583 14.6001 13.5083 14.7584C13.35 14.9084 13.1333 15.0001 12.9167 15.0001Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M17.0833 8.20005H2.91667C2.575 8.20005 2.29167 7.91672 2.29167 7.57505C2.29167 7.23339 2.575 6.95005 2.91667 6.95005H17.0833C17.425 6.95005 17.7083 7.23339 17.7083 7.57505C17.7083 7.91672 17.425 8.20005 17.0833 8.20005Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M13.3333 18.9584H6.66667C3.625 18.9584 1.875 17.2084 1.875 14.1667V7.08342C1.875 4.04175 3.625 2.29175 6.66667 2.29175H13.3333C16.375 2.29175 18.125 4.04175 18.125 7.08342V14.1667C18.125 17.2084 16.375 18.9584 13.3333 18.9584ZM6.66667 3.54175C4.28333 3.54175 3.125 4.70008 3.125 7.08342V14.1667C3.125 16.5501 4.28333 17.7084 6.66667 17.7084H13.3333C15.7167 17.7084 16.875 16.5501 16.875 14.1667V7.08342C16.875 4.70008 15.7167 3.54175 13.3333 3.54175H6.66667Z"
        fill="#7E92A2"
      />
    </svg>
  );
};
