import React, { useState, useEffect } from "react";
import { Button } from "../../components/Button";
import { Dropdown } from "../../components/Dropdown";
import { Input } from "../../components/Input";
import { InputWithDatePicker } from "../../components/InputWithDatePicker";
import { VuesaxBoldCloseCircle } from "../../icons/VuesaxBoldCloseCircle";
import { Icon10 } from "../../icons/Icon10";
import "./style.css";

export const ModalAddNewActivity = ({ onClose, onSave }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [event, setEvent] = useState("");
  const [eventDate, setEventDate] = useState("");

  const handleSave = () => {
    const newActivity = {
      "contact_name": name,
      "description": description,
      "event": event,
      "event_date": eventDate
    };

    onSave(newActivity);
  };

  return (
    <div className="modal-add-new-referral">
      <div className="title">
        <div className="text-wrapper-3">Add New Activity</div>
        <Button
          className="button-2"
          override={<VuesaxBoldCloseCircle className="vuesax-bold-close-circle" color="#7E92A2" />}
          style="white"
          type="icon-only"
          onClick={onClose}
        />
      </div>
      <div className="content-SCROLL">
        <div className="form">
          <div className="div-4">
            <Dropdown
              className="design-component-instance-node"
              label="Event"
              placeholder="Select one"
              options={["In-person meeting", "Email sent", "Referral received", "Referral sent"]}
              onSelect={(option) => setEvent(option)}
            />
          </div>
          <div className="div-4">
           <InputWithDatePicker
              className="design-component-instance-node"
              label="Date"
              onDateChange={(date) => setEventDate(date)}
            />
          </div>
          <div className="div-4">
            <Input
              className="design-component-instance-node"
              inputClassName="input-instance"
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="div-4">
            <Input
              className="design-component-instance-node"
              divClassName="input-8"
              label="Point of contact name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="actions">
        <div className="buttons">
          <Button className="button-cancel" style="white" text="Cancel" type="default" onClick={onClose} />
          <Button className="button-save" style="primary" text="Save Activity" type="default" onClick={handleSave} />
        </div>
      </div>
    </div>
  );
};
