import React, { useState, useEffect } from "react";
import { Button } from "../../components/Button";
import { InfoAndFilters } from "../../components/InfoAndFilters";
import { Contacts } from "../../components/Contacts";
import { ReferralsContacts } from "../../components/ReferralsContacts";
import { ActionModal } from "../../components/ActionModal";
import { formatDateTimeToReadableDateTimeWithoutTime } from "../../Utils/utils";
import { getContacts, searchContacts, updateContact } from "../../services/referralApi";
import { NotificationPopUp } from "../../components/NotificationPopUp";
import "./style.css";

export const ContactsList = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [contactToArchive, setContactToArchive] = useState(null);
  const [actionModalMessage, setActionModalMessage] = useState("Are you sure you want to archive this contact?");
  const [error, setError] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getContacts();
        setContacts(data.contacts || []);
      } catch (error) {
        setNotificationStatus("fail");
        setNotificationMessage(error.message);
        setShowNotification(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSearch = async (searchTerm) => {
    try {
      setLoading(true);
      const data = await searchContacts(searchTerm);
      setContacts(data.contacts || []);
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage(error.message);
      setShowNotification(true);
    } finally {
      setLoading(false);
    }
  };

  const handleArchive = (contactId) => {
    setContactToArchive(contactId);
    setIsActionModalOpen(true);
  };

  const confirmArchive = async () => {
   try {
      contact = {
      "is_active": false
     }

      await updateContact(contactToArchive, contact);
      setContacts((prevContacts) =>
        prevContacts.filter((contact) => contact.id !== contactToArchive)
      );
      setNotificationStatus("success");
      setNotificationMessage("Contact archived successfully!");
      setShowNotification(true);
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage("Failed to archive contact");
      setShowNotification(true);
    } finally {
      setIsActionModalOpen(false);
      setContactToArchive(null);
    }
  };

  const closeModal = () => {
    setIsActionModalOpen(false);
    setContactToArchive(null);
  };

  return (
    <div className="referrals-list-super">
      <InfoAndFilters
        className="info-and-filters-instance"
        hasCreatedInFilter={false}
        text={contacts.length}
        text1="Contacts"
        onSearch={handleSearch}
      />
      <div className="scrollable-content">
        <div className="contacts-list">
          <ReferralsContacts
            className="design-component-instance-node-3"
            divClassName="design-component-instance-node-4"
          />
          {loading ? (
            <div className="spinner"></div>
          ) : (
            contacts.map((contact) => (
              <Contacts
                key={contact.id}
                className="design-component-instance-node-3"
                name={`${contact.firstname} ${contact.lastname}`}
                role={contact.title}
                org={contact.organization_name}
                email={contact.email}
                phoneNumber={contact.phone_number}
                date={formatDateTimeToReadableDateTimeWithoutTime(contact.created_at)}
                contactId={contact.id}
                onArchive={handleArchive}
              />
            ))
          )}
        </div>
      </div>
      {isActionModalOpen && (
        <div className="modal-overlay-change-status">
          <ActionModal message={actionModalMessage} action={confirmArchive} onClose={closeModal} />
        </div>
      )}
      {showNotification && (
      <NotificationPopUp
        message={notificationMessage}
        status={notificationStatus}
        iconColor="#2DC8A8"
        closeIconColor="white"
        duration={3000}  // Display for 3 seconds
        onClose={() => setShowNotification(false)}
      />
     )}
    </div>
  );
};
