import React from "react";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Close } from "../../icons/Close";

export const ListOfReferrals = ({ onClose, data, title, loading }) => {
  return (
    <div className="list-of">
      <div className="opportunity-list">
        <div className="opp-name-and-status">
          <div className="street">{title}</div>
          <Close
            className="icon-instance-node"
            color="#7E92A2"
            onClick={onClose}
          />
        </div>
      </div>

      <div className="street-wrapper">
        <p className="text-wrapper">See referrals per your selection. Click to see detail view.</p>
      </div>

      {loading ? (
        <div className="spinner" />
      ) : (
        data && data.length > 0 ? (
          <>
            <div className="opportunity-snapshot-2">
              <div className="opp-name-and-status-2">
                <div className="street-3"># of referrals:</div>
                <div className="city">{data.length}</div>
              </div>
            </div>
            {data.map((opportunity, index) => (
              <Link key={index} to={`/opportunity/${opportunity.opportunity_id}`} className="deal-repeat-link">
                <div className="opportunity-snapshot-2">
                  <div className="opp-name-and-status-2">
                    <p className="street-2">{opportunity.opportunity_name}</p>
                  </div>
                  <div className="info-2">
                    <div className="text-wrapper-2">{opportunity.condition}</div>
                    <div className="text-wrapper-2">•</div>
                    <div className="issue">
                      <div className="text-wrapper-2">{opportunity.status}</div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </>
        ) : (
          <div className="street-wrapper">
            <p className="text-wrapper">No referrals available.</p>
          </div>
        )
      )}
    </div>
  );
};

ListOfReferrals.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      opportunity_name: PropTypes.string.isRequired,
      condition: PropTypes.string.isRequired,
      status: PropTypes.string,
    })
  ),
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
