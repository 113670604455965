import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Cells = ({
  className,
  text = "-",
  text1 = "-",
  mainCellClassName,
  text2 = "-",
  secondaryCellClassName,
  text3 = "-",
}) => {
  return (
    <div className={`cells-container ${className}`}>
      <div className="name-cell">
        <div className="name-wrapper">
          <div className="name-text">{text3}</div>
        </div>
      </div>

      <div className="info-cell-wrapper">
        <div className="info-cell">
          <div className="info-text">{text}</div>
        </div>
      </div>

      <div className="info-cell-wrapper">
        <div className="info-text-wrapper">
          <div className="info-text">{text1}</div>
        </div>
      </div>

      <div className="date-container">
        <p className="date-text">{text2}</p>
      </div>

      <div className={`date-wrapper ${mainCellClassName}`}>
        <p className="date-text">{text2}</p>
      </div>

      <div className={`secondary-date-cell ${secondaryCellClassName}`}>
        <p className="date-text">{text2}</p>
      </div>
    </div>
  );
};

Cells.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
};
