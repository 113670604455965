import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IconEdit } from "../../icons/IconEdit";
import { Icon6 } from "../../icons/Icon6";
import { IconPatientProfile } from "../../icons/IconPatientProfile";
import { IconQna } from "../../icons/IconQna";
import { Base } from "../Base";
import { Button } from "../Button";
import { StatusTag } from "../StatusTag";
import "./style.css";

export const DetailPageHeader = ({
  className,
  buttonTypeIconOnlyStyleClassName,
  name,
  status,
  onEditClick,
  onFileUploadClick,
  onProfileClick,
  buttonText,
  onStatusChange,
  closeDropdown,
}) => {
  const handleStatusChange = (newStatus) => {
      onStatusChange(newStatus);
  };

  return (
    <div className={`detail-page-header ${className}`}>
      <div className="name-and-actions">
        <div className="name-2">{name}</div>
        <div className="action">
          {status && (
            <StatusTag
              badgeClassName="status-tag-2"
              className="status-tag-instance"
              property={status}
              isADropdown={true}
              onStatusChange={handleStatusChange}
              closeDropdown={closeDropdown}
            />
          )}
          {onFileUploadClick && (
            <Button
              override={<Icon6 className="icon-instance-node-2" color="#7E92A2" />}
              style="white"
              type="icon-only"
              onClick={onFileUploadClick}
            />
          )}
          {onProfileClick && (
           <Link to={`/patient/${onProfileClick}`}>
            <Button
              override={<IconPatientProfile className="icon-instance-node-2" color="#7E92A2" />}
              style="white"
              type="icon-only"
              onClick={onProfileClick}
            />
            </Link>
          )}
          <Button
            className={buttonTypeIconOnlyStyleClassName}
            divClassName="button-edit"
            style="white"
            text={buttonText}
            type="default"
            onClick={onEditClick}
          />
        </div>
      </div>
      <Base className="base-2" />
    </div>
  );
};

DetailPageHeader.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onFileUploadClick: PropTypes.func,
  buttonText: PropTypes.string.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  closeDropdown: PropTypes.bool.isRequired,
};
