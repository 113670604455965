import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import { Button } from "../../components/Button";
import { VuesaxBoldCloseCircle } from "../../icons/VuesaxBoldCloseCircle";

export const DetailDataCellModalOverview = ({ isOpen, label, value, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay-data-cell">
      <div className="modal-content">
        <Button
          className="modal-close-button"
          override={<VuesaxBoldCloseCircle className="vuesax-bold-close-circle" color="#7E92A2" />}
          style="white"
          type="icon-only"
          onClick={onClose}
        />
        <h2 className="modal-title">{label}</h2>
        <div className="modal-body">
          <p className="modal-value">{value}</p>
        </div>
      </div>
    </div>
  );
};

DetailDataCellModalOverview.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DetailDataCellModalOverview;
