/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const OpportunityDocumentRow = ({
  className,
  text = "",
  text1 = "",
  text2 = "",
  text3 = "",
  text4 = ""
}) => {
  return (
    <div className={`opportunity-document-row ${className}`}>
      <div className="cells">
        <div className="name-cell">
          <div className="name-concat">{text}</div>
        </div>
        <div className="description-cell">
          <div className="email">{text1}</div>
        </div>
        <div className="address-concat-wrapper">
          <div className="address-concat">
            <div className="street">{text4}</div>
          </div>
        </div>
        <div className="address-concat-wrapper">
          <div className="address-concat">
            <div className="street">{text2}</div>
          </div>
        </div>
        <div className="address-concat-wrapper">
          <div className="address-concat">
            <div className="street">{text3}</div>
          </div>
        </div>
      </div>
      <div className="border-2" />
    </div>
  );
};

OpportunityDocumentRow.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
};
