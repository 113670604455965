import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export const Referrals = ({
  className,
  name = "",
  dateClassName,
  services = "",
  specializations = "",
  type = "",
  payment = "",
  date,
  referralId
}) => {
  return (
    <Link className={`referrals ${className}`} to={`/outreach/${referralId}`}>
      <div className="cells-3">
        <div className="room-area-51">{name}</div>
        <div className="room-area-51">{type}</div>
        <div className="room-area-51">{payment}</div>
        <div className="room-area-51">{specializations}</div>
        <div className="room-area-51">{date}</div>
      </div>
      <div className="border-3" />
    </Link>
  );
};

Referrals.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  dateClassName: PropTypes.string,
  services: PropTypes.string,
  specializations: PropTypes.string,
  type: PropTypes.string,
  payment: PropTypes.string,
  date: PropTypes.string,
  referralId: PropTypes.string.isRequired,
};
