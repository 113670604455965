import axios from 'axios';

const API_BASE_URL = '';

// Create an instance of Axios with the base URL
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Security-Policy': "upgrade-insecure-requests", // Enforces HTTPS
    'X-Content-Type-Options': 'nosniff',
    'Strict-Transport-Security': 'max-age=63072000; includeSubDomains; preload', // Forces HTTPS connections
  },
});

// Function to set up authorization headers
const setAuthHeaders = () => {
  const token = localStorage.getItem('newresiliencetoken');
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};

// Function to handle common response logic
const handleResponse = (response) => {
  if (response.data.status_code === 200 || response.data.status_code === 201) {
    var responseJson = JSON.parse(response.data.result);
    return responseJson.result;
  } else if (response.data.status_code === 401 || response.data.status_code === 403) {
    localStorage.removeItem('newresiliencetoken');
    window.location.href = '/';
  } else {
    var responseJson = JSON.parse(response.data.result);
    throw new Error(responseJson.status_msg || 'An unexpected error occurred.');
  }
};

// Function to handle common error logic
const handleError = (error) => {
  console.error("API Error:", error);
  throw error;
};

export { axiosInstance, setAuthHeaders, handleResponse, handleError };
