import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { InfoAndFilters } from "../../components/InfoAndFilters";
import { SidebarNavigation } from "../../components/SidebarNavigation";
import { TasksHeader } from "../../components/TasksHeader";
import { TasksTableRow } from "../../components/TasksTableRow";
import { TopNavigationBar } from "../../components/TopNavigationBar";
import { IconAdd } from "../../icons/IconAdd";
import { Icon16 } from "../../icons/Icon16";
import { VuesaxOutlineNoteText } from "../../icons/VuesaxOutlineNoteText";
import { getTasks, updateTask, searchTasks, saveTask as saveTaskApi } from "../../services/taskApi";
import { ModalAddTask } from "../../components/ModalAddTask";
import { NotificationPopUp } from "../../components/NotificationPopUp";
import "./style.css";

export const TasksList = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTasks, setSelectedTasks] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getTasks();
        if (data.task) {
          setTasks(data.task);
        } else {
          setError("No task data!");
        }
      } catch (error) {
        setNotificationStatus("fail");
        setNotificationMessage(error.message);
        setShowNotification(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const saveTask = async (newTask) => {
    try {
      const response = await saveTaskApi(newTask);
      setTasks((prevTasks) => [response.task, ...prevTasks]);
      setIsModalOpen(false);
      setNotificationStatus("success");
      setNotificationMessage("Successfully created!");
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage(error.message);
    }
    finally {
      setShowNotification(true);
    }
  };

   const handleSearch = async (searchTerm) => {
    try {
      setLoading(true);
      const data = await searchTasks(searchTerm);
      setTasks(data.task);
    } catch (error) {
        setNotificationStatus("fail");
        setNotificationMessage(error.message);
        setShowNotification(true);
      } finally {
        setLoading(false);
      }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(date);
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxClick = async (taskId, newStatus) => {
    try {
      updatedTask = {
        is_complete: newStatus
      }
      await updateTask(taskId, updatedTask);
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId ? { ...task, is_complete: newStatus } : task
        )
      );
    } catch (error) {
      console.error("Failed to update task status:", error);
    }
  };

  return (
    <div className="tasks-list">
      <TopNavigationBar
        baseBaseClassName="top-navigation-bar-instance"
        buttonIcon={<Icon16 className="vuesax-outline-add" color="#ECECFE" />}
        buttonText="Add Task"
        className="top-navigation-bar-v3"
        hasDiv
        text="Tasks"
        onButtonClick={openModal}
      />
      <div className="side-nav-and-body">
        <SidebarNavigation className="sidebar-navigation-v3" />
        <div className="body-patients-view">
          <InfoAndFilters
            className="info-and-filters-instance"
            hasCreatedInFilter={false}
            hasDiv={false}
            text={tasks.length}
            text1="tasks"
            onSearch={handleSearch}
          />
          <div className="opportunity-list">
            <TasksHeader className="design-component-instance-node-3" text="Assigned To" />

            {loading ? (
              <div className="spinner"></div>
            ) : (
              tasks.map((task) => (
                <TasksTableRow
                key={task.id}
                text={task.assigned_to}
                text1={task.title}
                text2={task.org_associated_with}
                text3={task.contact_associated_with}
                date={formatDate(task.due_date)}
                status={task.is_complete ? "Completed" : "Pending"}
                taskId={task.id}
                onCheckboxClick={handleCheckboxClick}
               />
              ))
            )}

            {error && <div className="error-message">{error}</div>}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-add-new-wrapper">
          <ModalAddTask onClose={closeModal} onSaveTask={saveTask} />
        </div>
      )}
      {showNotification && (
        <NotificationPopUp
          message={notificationMessage}
          status={notificationStatus}
          iconColor="#2DC8A8"
          closeIconColor="white"
          duration={3000}  // Display for 3 seconds
          onClose={() => setShowNotification(false)}
        />
      )}
    </div>
  );
};
