import React, { useEffect, useState } from "react";
import { Outlet, NavLink, useParams } from "react-router-dom"; // Import NavLink
import { Base } from "../../components/Base";
import { DetailPage } from "../../components/DetailPage";
import { DetailPageHeader } from "../../components/DetailPageHeader";
import { RightBarRecent } from "../../components/RightBarRecent";
import { SidebarNavigation } from "../../components/SidebarNavigation";
import { TopNavigationBar } from "../../components/TopNavigationBar";
import { IconAnalytics } from "../../icons/IconAnalytics";
import { IconCapacity } from "../../icons/IconCapacity";
import { IconOpportunities } from "../../icons/IconOpportunities";
import { IconPatients } from "../../icons/IconPatients";
import { IconReferrals1 } from "../../icons/IconReferrals1";
import { IconTasks } from "../../icons/IconTasks";
import { VuesaxOutlineElement4 } from "../../icons/VuesaxOutlineElement4";
import { Icon16 } from "../../icons/Icon16";
import { VuesaxOutlineBriefcase } from "../../icons/VuesaxOutlineBriefcase";
import { VuesaxOutlineCallCalling } from "../../icons/VuesaxOutlineCallCalling";
import {ModalAddNewOpportunity} from "../../components/ModalAddNewOpportunity"
import { NotificationPopUp } from "../../components/NotificationPopUp";
import { getPatientById, updatePatient, getOpportunitiesByPatientId } from "../../services/patientApi";
import { saveOpportunity } from "../../services/opportunityApi";
import "./style.css";

export const PatientDetail = () => {
  const { patientId } = useParams();
  const [patient, setPatient] = useState(null);
  const [patientOpportunities, setPatientOpportunities] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [buttonText, setButtonText] = useState("Edit Details");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");

  useEffect(() => {
    const fetchPatient = async () => {
      try {
        const data = await getPatientById(patientId);
        setPatient(data.patient);

        const opportunityData = await getOpportunitiesByPatientId(patientId);
        setPatientOpportunities(opportunityData.opportunities);
      } catch (error) {
        setNotificationStatus("fail");
        setNotificationMessage("Error fetching patient details");
        setShowNotification(true);
      } finally {
        setLoading(false);
      }
    };
    fetchPatient();
  }, [patientId]);

  const handleEditClick = () => {
    if (isEditable) {
      handleSave();
    } else {
      setIsEditable(true);
      setButtonText("Save Details");
    }
  };

  const handleSave = async () => {
    try {
      await updatePatient(patientId, patient);
      setIsEditable(false);
      setButtonText("Edit Details");
      setNotificationStatus("success");
      setNotificationMessage("Successfully saved the details!");
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage("Error saving patient details");
    }
    finally {
      setShowNotification(true);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveOpportunity = async (opportunityData) => {
    try {
      opportunityData["patient_id"] = patientId;

      const response = await saveOpportunity(opportunityData);
      setPatientOpportunities((prevOpportunites) => [response.opportunity, ...prevOpportunites]);
      setIsModalOpen(false);
      setNotificationStatus("success");
      setNotificationMessage("Successfully saved the opportunity!");
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage("Error saving opportunity");
    }
    finally {
      setShowNotification(true);
    }
  };

  if (loading) return <div className="spinner"></div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="patient-detail">
      <TopNavigationBar
        buttonIcon={<Icon16 className="vuesax-outline-add" color="#ECECFE" />}
        buttonText="Add Opportunity"
        className="top-navigation-bar-v3"
        hasDiv
        text="Patient Detail"
        baseBaseClassName="top-navigation-bar-instance"
        onButtonClick={handleOpenModal}
      />
      <div className="side-nav-and-body">
        <SidebarNavigation
          className="sidebar-navigation-v3"
        />
        <div className="body-customer-detail">
          <div className="left-area">
            <DetailPageHeader
              name={`${patient.firstname} ${patient.lastname}`}
              buttonTypeIconOnlyStyleClassName="detail-page-header-2"
              className="detail-page-header-instance"
              onEditClick={handleEditClick}
              buttonText={buttonText}
            />
              <DetailPage
                className="design-component-instance-node-3"
                emailFieldClassName="detail-page-navigation-tabs"
                hasDiv={false}
                hasEmailField={false}
                text={
                  <NavLink
                    to={`/patient/${patientId}/demographics`}
                    className={({ isActive }) => isActive ? "detail-page-link active" : "detail-page-link"}
                  >
                    Demographics
                  </NavLink>
                }
                text1={
                  <NavLink
                    to={`/patient/${patientId}/point-of-contact`}
                    className={({ isActive }) => isActive ? "detail-page-link active" : "detail-page-link"}
                  >
                    Points of Contact
                  </NavLink>
                }
                text2={
                  <NavLink
                    to={`/patient/${patientId}/insurance-payment`}
                    className={({ isActive }) => isActive ? "detail-page-link active" : "detail-page-link"}
                  >
                    Payment and Insurance
                  </NavLink>
                }
              />
              <Outlet context={{ patient, setPatient, isEditable }} />
            </div>
          </div>
          <Base className="base-4" />
          <div className="right-area">
            <RightBarRecent opportunities={patientOpportunities} className="right-bar-recent-items" />
          </div>
        </div>
       {isModalOpen && <ModalAddNewOpportunity
                           opportunityName={`${patient.firstname} ${patient.lastname}`}
                           onClose={handleCloseModal}
                           onSaveOpportunity={handleSaveOpportunity}/>}

       {showNotification && (
          <NotificationPopUp
            message={notificationMessage}
            status={notificationStatus}
            iconColor="#2DC8A8"
            closeIconColor="white"
            duration={3000}  // Display for 3 seconds
            onClose={() => setShowNotification(false)}
          />
        )}
      </div>
  );
};