/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconPatientProfile = ({ className }) => {
  return (
    <svg
      className={`icon-patient-profile ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M10.1004 11.275C10.0837 11.275 10.0587 11.275 10.0421 11.275C10.0171 11.275 9.98372 11.275 9.95872 11.275C8.06706 11.2167 6.65039 9.74165 6.65039 7.92498C6.65039 6.07498 8.15872 4.56665 10.0087 4.56665C11.8587 4.56665 13.3671 6.07498 13.3671 7.92498C13.3587 9.74998 11.9337 11.2167 10.1254 11.275C10.1087 11.275 10.1087 11.275 10.1004 11.275ZM10.0004 5.80832C8.83372 5.80832 7.89206 6.75832 7.89206 7.91665C7.89206 9.05832 8.78372 9.98332 9.91706 10.025C9.94206 10.0167 10.0254 10.0167 10.1087 10.025C11.2254 9.96665 12.1004 9.04998 12.1087 7.91665C12.1087 6.75832 11.1671 5.80832 10.0004 5.80832Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M10 18.9583C7.75836 18.9583 5.61669 18.125 3.95836 16.6083C3.80836 16.475 3.74169 16.275 3.75836 16.0833C3.86669 15.0917 4.48336 14.1667 5.50836 13.4833C7.99169 11.8333 12.0167 11.8333 14.4917 13.4833C15.5167 14.175 16.1334 15.0917 16.2417 16.0833C16.2667 16.2833 16.1917 16.475 16.0417 16.6083C14.3834 18.125 12.2417 18.9583 10 18.9583ZM5.06669 15.9167C6.45003 17.075 8.19169 17.7083 10 17.7083C11.8084 17.7083 13.55 17.075 14.9334 15.9167C14.7834 15.4083 14.3834 14.9167 13.7917 14.5167C11.7417 13.15 8.26669 13.15 6.20003 14.5167C5.60836 14.9167 5.21669 15.4083 5.06669 15.9167Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M10.0003 18.9584C5.05866 18.9584 1.04199 14.9417 1.04199 10.0001C1.04199 5.05841 5.05866 1.04175 10.0003 1.04175C14.942 1.04175 18.9587 5.05841 18.9587 10.0001C18.9587 14.9417 14.942 18.9584 10.0003 18.9584ZM10.0003 2.29175C5.75033 2.29175 2.29199 5.75008 2.29199 10.0001C2.29199 14.2501 5.75033 17.7084 10.0003 17.7084C14.2503 17.7084 17.7087 14.2501 17.7087 10.0001C17.7087 5.75008 14.2503 2.29175 10.0003 2.29175Z"
        fill="#7E92A2"
      />
    </svg>
  );
};
