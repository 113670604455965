/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const VuesaxOutlineDocumentUpload = ({ className }) => {
  return (
    <svg
      className={`vuesax-outline-document-upload ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M8.99994 17.75C8.58994 17.75 8.24994 17.41 8.24994 17V12.81L7.52994 13.53C7.23994 13.82 6.75994 13.82 6.46994 13.53C6.17994 13.24 6.17994 12.76 6.46994 12.47L8.46994 10.47C8.67994 10.26 9.00994 10.19 9.28994 10.31C9.56994 10.42 9.74994 10.7 9.74994 11V17C9.74994 17.41 9.40994 17.75 8.99994 17.75Z"
        fill="#292D32"
      />
      <path
        className="path"
        d="M10.9999 13.75C10.8099 13.75 10.6199 13.68 10.4699 13.53L8.46994 11.53C8.17994 11.24 8.17994 10.76 8.46994 10.47C8.75994 10.18 9.23994 10.18 9.52994 10.47L11.5299 12.47C11.8199 12.76 11.8199 13.24 11.5299 13.53C11.3799 13.68 11.1899 13.75 10.9999 13.75Z"
        fill="#292D32"
      />
      <path
        className="path"
        d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z"
        fill="#292D32"
      />
      <path
        className="path"
        d="M22 10.75H18C14.58 10.75 13.25 9.42 13.25 6V2C13.25 1.7 13.43 1.42 13.71 1.31C13.99 1.19 14.31 1.26 14.53 1.47L22.53 9.47C22.74 9.68 22.81 10.01 22.69 10.29C22.57 10.57 22.3 10.75 22 10.75ZM14.75 3.81V6C14.75 8.58 15.42 9.25 18 9.25H20.19L14.75 3.81Z"
        fill="#292D32"
      />
    </svg>
  );
};
