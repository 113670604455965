import PropTypes from "prop-types";
import React from "react";

export const Check1 = ({ color = "#2DC8A8", className }) => {
  return (
    <svg
      className={`check-1 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 17 16"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.86668 12.0001L3.06668 8.20013L4.01668 7.25013L6.86668 10.1001L12.9833 3.98346L13.9333 4.93346L6.86668 12.0001Z"
        fill={color}
      />
    </svg>
  );
};

Check1.propTypes = {
  color: PropTypes.string,
};
