/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconCapacity = ({ className }) => {
  return (
    <svg
      className={`icon-capacity ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.66699 4.79175C6.32533 4.79175 6.04199 4.50841 6.04199 4.16675V1.66675C6.04199 1.32508 6.32533 1.04175 6.66699 1.04175C7.00866 1.04175 7.29199 1.32508 7.29199 1.66675V4.16675C7.29199 4.50841 7.00866 4.79175 6.66699 4.79175Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M13.333 4.79175C12.9913 4.79175 12.708 4.50841 12.708 4.16675V1.66675C12.708 1.32508 12.9913 1.04175 13.333 1.04175C13.6747 1.04175 13.958 1.32508 13.958 1.66675V4.16675C13.958 4.50841 13.6747 4.79175 13.333 4.79175Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M13.3333 18.9584H6.66667C3.625 18.9584 1.875 17.2084 1.875 14.1667V7.08342C1.875 4.04175 3.625 2.29175 6.66667 2.29175H13.3333C16.375 2.29175 18.125 4.04175 18.125 7.08342V14.1667C18.125 17.2084 16.375 18.9584 13.3333 18.9584ZM6.66667 3.54175C4.28333 3.54175 3.125 4.70008 3.125 7.08342V14.1667C3.125 16.5501 4.28333 17.7084 6.66667 17.7084H13.3333C15.7167 17.7084 16.875 16.5501 16.875 14.1667V7.08342C16.875 4.70008 15.7167 3.54175 13.3333 3.54175H6.66667Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M13.3337 9.79175H6.66699C6.32533 9.79175 6.04199 9.50841 6.04199 9.16675C6.04199 8.82508 6.32533 8.54175 6.66699 8.54175H13.3337C13.6753 8.54175 13.9587 8.82508 13.9587 9.16675C13.9587 9.50841 13.6753 9.79175 13.3337 9.79175Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M10.0003 13.9583H6.66699C6.32533 13.9583 6.04199 13.6749 6.04199 13.3333C6.04199 12.9916 6.32533 12.7083 6.66699 12.7083H10.0003C10.342 12.7083 10.6253 12.9916 10.6253 13.3333C10.6253 13.6749 10.342 13.9583 10.0003 13.9583Z"
        fill="#7E92A2"
      />
    </svg>
  );
};
