import React, { useState, useEffect } from "react";
import { Outlet, NavLink, useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import { CapacityTabs } from "../../components/CapacityTabs";
import { Referrals } from "../../components/Referrals";
import { ReferralsOrgs } from "../../components/ReferralsOrgs";
import { SidebarNavigation } from "../../components/SidebarNavigation";
import { TopNavigationBar } from "../../components/TopNavigationBar";
import { ModalAddNewReferral } from "../../components/ModalAddNewReferral";
import { IconAdd } from "../../icons/IconAdd";
import { formatDateTimeToReadableDateTime } from "../../Utils/utils";
import { getReferrals, saveReferral as saveReferralApi } from "../../services/referralApi";
import "./style.css";

export const ReferralsContactsList = () => {
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");

  const saveReferral = async (newReferral) => {
    try {
      const response = await saveReferralApi(newReferral);
      setReferrals((prevReferrals) => [response.referral, ...prevReferrals]);
      setIsModalOpen(false);
    } catch (error) {
      console.error("There was an error saving the referral!", error);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="referrals-contact-list">
      <TopNavigationBar
        buttonIcon={<IconAdd className="vuesax-outline-add" color="#ECECFE" />}
        buttonText="Add Organization"
        className="top-navigation-bar-v3"
        baseBaseClassName="top-navigation-bar-instance"
        text="Outreach"
        onButtonClick={openModal}
      />
      <div className="side-nav-and-body">
        <SidebarNavigation className="sidebar-navigation-instance" />
        <div className="body-patients-view">
          <CapacityTabs
               className="capacity-tabs-selection"
               text={
                <NavLink
                  to={`/outreach/organizations`}
                  className={({ isActive }) => (isActive ? "detail-page-link active" : "detail-page-link")}
                >
                  Organizations
                </NavLink>
              }
              text1={
                <NavLink
                  to={`/outreach/contacts`}
                  className={({ isActive }) => (isActive ? "detail-page-link active" : "detail-page-link")}
                >
                  Contacts
                </NavLink>
              }
            />
            <Outlet />
           </div>
          </div>
      {isModalOpen && (
        <div className="modal-add-new-wrapper">
          <ModalAddNewReferral onClose={closeModal} onSave={saveReferral} />
        </div>
      )}
    </div>
  );
};
