/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const VuesaxOutlineStatusUp = ({ color = "#292D32", className }) => {
  return (
    <svg
      className={`vuesax-outline-status-up ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.2334 15.7499C5.89173 15.7499 5.6084 15.4666 5.6084 15.1249V13.3999C5.6084 13.0582 5.89173 12.7749 6.2334 12.7749C6.57507 12.7749 6.8584 13.0582 6.8584 13.3999V15.1249C6.8584 15.4749 6.57507 15.7499 6.2334 15.7499Z"
        fill={color}
      />
      <path
        className="path"
        d="M10.5 15.7498C10.1583 15.7498 9.875 15.4665 9.875 15.1248V11.6665C9.875 11.3248 10.1583 11.0415 10.5 11.0415C10.8417 11.0415 11.125 11.3248 11.125 11.6665V15.1248C11.125 15.4748 10.8417 15.7498 10.5 15.7498Z"
        fill={color}
      />
      <path
        className="path"
        d="M14.7666 15.7502C14.4249 15.7502 14.1416 15.4669 14.1416 15.1252V9.94189C14.1416 9.60023 14.4249 9.31689 14.7666 9.31689C15.1083 9.31689 15.3916 9.60023 15.3916 9.94189V15.1252C15.3916 15.4752 15.1166 15.7502 14.7666 15.7502Z"
        fill={color}
      />
      <path
        className="path"
        d="M6.23364 10.9832C5.95031 10.9832 5.70031 10.7915 5.62531 10.5082C5.54197 10.1749 5.74197 9.83318 6.08364 9.74985C9.15031 8.98318 11.8503 7.30818 13.9086 4.91652L14.292 4.46652C14.517 4.20819 14.9086 4.17485 15.1753 4.39985C15.4336 4.62485 15.467 5.01652 15.242 5.28318L14.8586 5.73318C12.6336 8.33318 9.7003 10.1415 6.38364 10.9665C6.33364 10.9832 6.28364 10.9832 6.23364 10.9832Z"
        fill={color}
      />
      <path
        className="path"
        d="M14.7669 7.93333C14.4252 7.93333 14.1419 7.65 14.1419 7.30833V5.5H12.3252C11.9835 5.5 11.7002 5.21667 11.7002 4.875C11.7002 4.53333 11.9835 4.25 12.3252 4.25H14.7669C15.1085 4.25 15.3919 4.53333 15.3919 4.875V7.31667C15.3919 7.65833 15.1169 7.93333 14.7669 7.93333Z"
        fill={color}
      />
      <path
        className="path"
        d="M13.0003 18.9582H8.00033C3.47533 18.9582 1.54199 17.0248 1.54199 12.4998V7.49984C1.54199 2.97484 3.47533 1.0415 8.00033 1.0415H13.0003C17.5253 1.0415 19.4587 2.97484 19.4587 7.49984V12.4998C19.4587 17.0248 17.5253 18.9582 13.0003 18.9582ZM8.00033 2.2915C4.15866 2.2915 2.79199 3.65817 2.79199 7.49984V12.4998C2.79199 16.3415 4.15866 17.7082 8.00033 17.7082H13.0003C16.842 17.7082 18.2087 16.3415 18.2087 12.4998V7.49984C18.2087 3.65817 16.842 2.2915 13.0003 2.2915H8.00033Z"
        fill={color}
      />
    </svg>
  );
};

VuesaxOutlineStatusUp.propTypes = {
  color: PropTypes.string,
};
