/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icon34 = ({ className }) => {
  return (
    <svg
      className={`icon-34 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.66667 4.79199C6.325 4.79199 6.04167 4.50866 6.04167 4.16699V1.66699C6.04167 1.32533 6.325 1.04199 6.66667 1.04199C7.00833 1.04199 7.29167 1.32533 7.29167 1.66699V4.16699C7.29167 4.50866 7.00833 4.79199 6.66667 4.79199Z"
        fill="#7E92A2"
      />

      <path
        className="path"
        d="M13.3333 4.79199C12.9917 4.79199 12.7083 4.50866 12.7083 4.16699V1.66699C12.7083 1.32533 12.9917 1.04199 13.3333 1.04199C13.675 1.04199 13.9583 1.32533 13.9583 1.66699V4.16699C13.9583 4.50866 13.675 4.79199 13.3333 4.79199Z"
        fill="#7E92A2"
      />

      <path
        className="path"
        d="M7.08333 12.0837C6.975 12.0837 6.86667 12.0587 6.76667 12.017C6.65833 11.9753 6.575 11.917 6.49167 11.842C6.34167 11.6837 6.25 11.4753 6.25 11.2503C6.25 11.142 6.275 11.0337 6.31667 10.9337C6.35833 10.8337 6.41667 10.742 6.49167 10.6587C6.575 10.5837 6.65833 10.5253 6.76667 10.4837C7.06667 10.3587 7.44167 10.4253 7.675 10.6587C7.825 10.817 7.91667 11.0337 7.91667 11.2503C7.91667 11.3003 7.90833 11.3587 7.9 11.417C7.89167 11.467 7.875 11.517 7.85 11.567C7.83333 11.617 7.80833 11.667 7.775 11.717C7.75 11.7587 7.70833 11.8003 7.675 11.842C7.51667 11.992 7.3 12.0837 7.08333 12.0837Z"
        fill="#7E92A2"
      />

      <path
        className="path"
        d="M10 12.0837C9.89167 12.0837 9.78333 12.0587 9.68333 12.017C9.575 11.9753 9.49167 11.917 9.40833 11.842C9.25833 11.6836 9.16667 11.4753 9.16667 11.2503C9.16667 11.142 9.19167 11.0337 9.23333 10.9337C9.275 10.8337 9.33333 10.742 9.40833 10.6587C9.49167 10.5837 9.575 10.5253 9.68333 10.4836C9.98333 10.3503 10.3583 10.4253 10.5917 10.6587C10.7417 10.817 10.8333 11.0337 10.8333 11.2503C10.8333 11.3003 10.825 11.3587 10.8167 11.417C10.8083 11.467 10.7917 11.517 10.7667 11.567C10.75 11.617 10.725 11.667 10.6917 11.717C10.6667 11.7587 10.625 11.8003 10.5917 11.842C10.4333 11.992 10.2167 12.0837 10 12.0837Z"
        fill="#7E92A2"
      />

      <path
        className="path"
        d="M12.9167 12.0837C12.8083 12.0837 12.7 12.0587 12.6 12.017C12.4917 11.9753 12.4083 11.917 12.325 11.842C12.2917 11.8003 12.2583 11.7587 12.225 11.717C12.1917 11.667 12.1667 11.617 12.15 11.567C12.125 11.517 12.1083 11.467 12.1 11.417C12.0917 11.3587 12.0833 11.3003 12.0833 11.2503C12.0833 11.0337 12.175 10.817 12.325 10.6587C12.4083 10.5837 12.4917 10.5253 12.6 10.4836C12.9083 10.3503 13.275 10.4253 13.5083 10.6587C13.6583 10.817 13.75 11.0337 13.75 11.2503C13.75 11.3003 13.7417 11.3587 13.7333 11.417C13.725 11.467 13.7083 11.517 13.6833 11.567C13.6667 11.617 13.6417 11.667 13.6083 11.717C13.5833 11.7587 13.5417 11.8003 13.5083 11.842C13.35 11.992 13.1333 12.0837 12.9167 12.0837Z"
        fill="#7E92A2"
      />

      <path
        className="path"
        d="M7.08333 15.0003C6.975 15.0003 6.86667 14.9753 6.76667 14.9337C6.66667 14.892 6.575 14.8337 6.49167 14.7587C6.34167 14.6003 6.25 14.3837 6.25 14.167C6.25 14.0587 6.275 13.9503 6.31667 13.8503C6.35833 13.742 6.41667 13.6503 6.49167 13.5753C6.8 13.267 7.36667 13.267 7.675 13.5753C7.825 13.7337 7.91667 13.9503 7.91667 14.167C7.91667 14.3837 7.825 14.6003 7.675 14.7587C7.51667 14.9087 7.3 15.0003 7.08333 15.0003Z"
        fill="#7E92A2"
      />

      <path
        className="path"
        d="M10 15.0003C9.78333 15.0003 9.56667 14.9087 9.40833 14.7587C9.25833 14.6003 9.16667 14.3837 9.16667 14.167C9.16667 14.0587 9.19167 13.9503 9.23333 13.8503C9.275 13.742 9.33333 13.6503 9.40833 13.5753C9.71667 13.267 10.2833 13.267 10.5917 13.5753C10.6667 13.6503 10.725 13.742 10.7667 13.8503C10.8083 13.9503 10.8333 14.0587 10.8333 14.167C10.8333 14.3837 10.7417 14.6003 10.5917 14.7587C10.4333 14.9087 10.2167 15.0003 10 15.0003Z"
        fill="#7E92A2"
      />

      <path
        className="path"
        d="M12.9167 15.0004C12.7 15.0004 12.4833 14.9087 12.325 14.7587C12.25 14.6837 12.1917 14.592 12.15 14.4837C12.1083 14.3837 12.0833 14.2754 12.0833 14.167C12.0833 14.0587 12.1083 13.9504 12.15 13.8504C12.1917 13.742 12.25 13.6504 12.325 13.5754C12.5167 13.3837 12.8083 13.292 13.075 13.3503C13.1333 13.3587 13.1833 13.3753 13.2333 13.4003C13.2833 13.417 13.3333 13.442 13.3833 13.4754C13.425 13.5004 13.4667 13.542 13.5083 13.5754C13.6583 13.7337 13.75 13.9504 13.75 14.167C13.75 14.3837 13.6583 14.6003 13.5083 14.7587C13.35 14.9087 13.1333 15.0004 12.9167 15.0004Z"
        fill="#7E92A2"
      />

      <path
        className="path"
        d="M17.0833 8.2003H2.91667C2.575 8.2003 2.29167 7.91696 2.29167 7.5753C2.29167 7.23363 2.575 6.9503 2.91667 6.9503H17.0833C17.425 6.9503 17.7083 7.23363 17.7083 7.5753C17.7083 7.91696 17.425 8.2003 17.0833 8.2003Z"
        fill="#7E92A2"
      />

      <path
        className="path"
        d="M13.3333 18.9587H6.66667C3.625 18.9587 1.875 17.2087 1.875 14.167V7.08366C1.875 4.04199 3.625 2.29199 6.66667 2.29199H13.3333C16.375 2.29199 18.125 4.04199 18.125 7.08366V14.167C18.125 17.2087 16.375 18.9587 13.3333 18.9587ZM6.66667 3.54199C4.28333 3.54199 3.125 4.70033 3.125 7.08366V14.167C3.125 16.5503 4.28333 17.7087 6.66667 17.7087H13.3333C15.7167 17.7087 16.875 16.5503 16.875 14.167V7.08366C16.875 4.70033 15.7167 3.54199 13.3333 3.54199H6.66667Z"
        fill="#7E92A2"
      />
    </svg>
  );
};
