import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ReferralsTable } from "../../components/ReferralsTable";
import { Cells } from "../../components/Cells";
import { ListOfReferrals } from "../../components/ListOfReferrals";
import { NotificationPopUp } from "../../components/NotificationPopUp";
import { getReferralAnalytics, getReferralAnalyticsDetails } from "../../services/referralApi";
import "./style.css";

export const ReferralAnalytics = () => {
  const { referralId } = useParams();
  const [referredIn, setReferredIn] = useState();
  const [referredOut, setReferredOut] = useState();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [referralInDetails, setReferralInDetails] = useState([]);
  const [referralOutDetails, setReferralOutDetails] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchReferralAnalytics = async () => {
      try {
        const data = await getReferralAnalytics(referralId);
        setReferredIn(data.referred_in);
        setReferredOut(data.referred_out);
      } catch (error) {
        setNotificationStatus("fail");
        setNotificationMessage("Error fetching referral analytics");
        setShowNotification(true);
      } finally {
        setLoading(false);
      }
    };
    fetchReferralAnalytics();
  }, [referralId]);

  const fetchDetailedReferralData = async (metric) => {
    setLoading(true);
    setSelectedMetric(metric);
    try {
      const data = await getReferralAnalyticsDetails(referralId, metric);
      if (metric === "incoming") {
        setReferralInDetails(data.referred_in_details);
      } else {
        setReferralOutDetails(data.referred_out_details);
      }
      setIsModalOpen(true);
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage("Error fetching detailed referral data");
      setShowNotification(true);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMetric(null);
  };

  const modalData = selectedMetric === "incoming" ? referralInDetails : referralOutDetails;

  return (
    <div className="referral-analytics">
      <div className="content">
        <div className="left-column">
          <div className="activity">
            <div className="frame">
              <div className="frame-2">
                <p className="label">Referral Statistics (last 60 days)</p>
              </div>
            </div>
          </div>

          <div className="referral-analytics">
            <ReferralsTable className="design-component-instance-node-2" text="Admitted" />
            <div className="referrals-table-row" onClick={() => fetchDetailedReferralData("incoming")}>
              <Cells className="cells-instance" dateCellClassName="cells-2" dateCellClassNameOverride="cells-2" text={referredIn} text3="Incoming" />
              <div className="border-2" />
            </div>

            <div className="referrals-table-row" onClick={() => fetchDetailedReferralData("outgoing")}>
              <Cells className="cells-instance" dateCellClassName="cells-2" dateCellClassNameOverride="cells-2" text={referredOut} text3="Outgoing" />
              <div className="border-2" />
            </div>
          </div>
        </div>
      </div>

      {showNotification && (
        <NotificationPopUp message={notificationMessage} status={notificationStatus} iconColor="#2DC8A8" closeIconColor="white" duration={3000} onClose={() => setShowNotification(false)} />
      )}

      {isModalOpen && (
        <div className="modal-overlay">
          <ListOfReferrals onClose={closeModal} data={modalData} title={selectedMetric === "incoming" ? "Incoming Referrals" : "Outgoing Referrals"} loading={loading} />
        </div>
      )}
    </div>
  );
};
