/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Logo1 = ({ color = "#3EA66C", className }) => {
  return (
    <svg
      className={`logo-1 ${className}`}
      fill="none"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" fill={color} height="48" rx="24" width="48" />
      <path
        className="path"
        clipRule="evenodd"
        d="M33.85 32.3451C33.85 33.269 34.5989 34.0179 35.5227 34.0179C36.4465 34.0179 37.1954 33.269 37.1954 32.3451V22.7437C37.1954 15.4042 31.2463 9.45508 23.9068 9.45508C16.5673 9.45508 10.6182 15.4042 10.6182 22.7437V32.3451C10.6182 33.269 11.3671 34.0179 12.2909 34.0179C13.2147 34.0179 13.9636 33.269 13.9636 32.3451V22.7437C13.9636 17.2518 18.4149 12.8005 23.9068 12.8005C29.3987 12.8005 33.85 17.2518 33.85 22.7437V32.3451ZM17.0734 32.5298C17.0734 33.4536 17.8223 34.2025 18.7462 34.2025C19.67 34.2025 20.4189 33.4536 20.4189 32.5298V22.8932C20.4189 20.9672 21.9798 19.4063 23.9057 19.4063C25.8317 19.4063 27.3926 20.9672 27.3926 22.8932C27.3926 23.817 28.1415 24.5659 29.0653 24.5659C29.9891 24.5659 30.738 23.817 30.738 22.8932C30.738 19.1196 27.6793 16.0609 23.9057 16.0609C20.1322 16.0609 17.0734 19.1196 17.0734 22.8932L17.0734 32.5298Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};

Logo1.propTypes = {
  color: PropTypes.string,
};
