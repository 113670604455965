/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const IconAnalytics = ({ color = "#7E92A2", className }) => {
  return (
    <svg
      className={`icon-analytics ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.2334 15.7504C5.89173 15.7504 5.6084 15.4671 5.6084 15.1254V13.4004C5.6084 13.0587 5.89173 12.7754 6.2334 12.7754C6.57507 12.7754 6.8584 13.0587 6.8584 13.4004V15.1254C6.8584 15.4754 6.57507 15.7504 6.2334 15.7504Z"
        fill={color}
      />
      <path
        className="path"
        d="M10.5 15.7493C10.1583 15.7493 9.875 15.466 9.875 15.1243V11.666C9.875 11.3243 10.1583 11.041 10.5 11.041C10.8417 11.041 11.125 11.3243 11.125 11.666V15.1243C11.125 15.4743 10.8417 15.7493 10.5 15.7493Z"
        fill={color}
      />
      <path
        className="path"
        d="M14.7666 15.7497C14.4249 15.7497 14.1416 15.4664 14.1416 15.1247V9.94141C14.1416 9.59974 14.4249 9.31641 14.7666 9.31641C15.1083 9.31641 15.3916 9.59974 15.3916 9.94141V15.1247C15.3916 15.4747 15.1166 15.7497 14.7666 15.7497Z"
        fill={color}
      />
      <path
        className="path"
        d="M6.23339 10.9832C5.95006 10.9832 5.70006 10.7915 5.62506 10.5082C5.54173 10.1749 5.74173 9.83318 6.08339 9.74985C9.15006 8.98318 11.8501 7.30818 13.9084 4.91652L14.2917 4.46652C14.5167 4.20819 14.9084 4.17485 15.1751 4.39985C15.4334 4.62485 15.4667 5.01652 15.2417 5.28318L14.8584 5.73318C12.6334 8.33318 9.70006 10.1415 6.38339 10.9665C6.33339 10.9832 6.28339 10.9832 6.23339 10.9832Z"
        fill={color}
      />
      <path
        className="path"
        d="M14.7666 7.93333C14.425 7.93333 14.1416 7.65 14.1416 7.30833V5.5H12.325C11.9833 5.5 11.7 5.21667 11.7 4.875C11.7 4.53333 11.9833 4.25 12.325 4.25H14.7666C15.1083 4.25 15.3916 4.53333 15.3916 4.875V7.31667C15.3916 7.65833 15.1166 7.93333 14.7666 7.93333Z"
        fill={color}
      />
      <path
        className="path"
        d="M13.0001 18.9577H8.00008C3.47508 18.9577 1.54175 17.0243 1.54175 12.4993V7.49935C1.54175 2.97435 3.47508 1.04102 8.00008 1.04102H13.0001C17.5251 1.04102 19.4584 2.97435 19.4584 7.49935V12.4993C19.4584 17.0243 17.5251 18.9577 13.0001 18.9577ZM8.00008 2.29102C4.15841 2.29102 2.79175 3.65768 2.79175 7.49935V12.4993C2.79175 16.341 4.15841 17.7077 8.00008 17.7077H13.0001C16.8417 17.7077 18.2084 16.341 18.2084 12.4993V7.49935C18.2084 3.65768 16.8417 2.29102 13.0001 2.29102H8.00008Z"
        fill={color}
      />
    </svg>
  );
};

IconAnalytics.propTypes = {
  color: PropTypes.string,
};
