import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logo3 } from "../../icons/Logo3";
import { sendResetPasswordEmail } from "../../services/loginApi";
import "./style.css";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setShowModal(true);
    setModalMessage("Sending reset link...");

    try {
      await sendResetPasswordEmail(email);
      setModalMessage("Check your inbox. If that email matches an account, you’ll get a link to reset your password within a few minutes.");
    } catch (error) {
      setError("Failed to send reset email");
      setModalMessage("Failed to send reset email. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="forgot-password-page">
      <div className="frame-wrapper">
        <form className="frame-4" onSubmit={handleForgotPassword}>
          <div className="frame-5">
            <Logo3 className="logo-1" />
            <div className="text-wrapper-14">New Resilience</div>
          </div>
          <div className="forgot-password-header">
            <h2>Forgot password?</h2>
            <p>We’ll send you a link to reset your password.</p>
          </div>

          <div className="form-group input-2">
            <input
              type="email"
              id="input-email"
              placeholder=" "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="input-email">Email address</label>
          </div>

          <button className="button-primary" type="submit">
            Send reset link
          </button>
          {error && <div className="error-message">{error}</div>}
        </form>
        <p className="back-to-login" onClick={() => navigate("/login")}>
          Back to login
        </p>
      </div>

      {showModal && (
        <Modal isLoading={isLoading} message={modalMessage} onClose={() => setShowModal(false)} />
      )}
    </div>
  );
};

// Modal Component
const Modal = ({ isLoading, message, onClose }) => {
  return (
    <div className="modal-overlay-forgot-password">
      <div className="modal-content-forgot-password">
        {isLoading ? (
          <div className="spinner"></div>
        ) : (
          <div className="modal-message">{message}</div>
        )}
        {!isLoading && <button className="modal-close-button-forgot-password" onClick={onClose}>Close</button>}
      </div>
    </div>
  );
};
