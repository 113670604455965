import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { DetailPageFiles } from "../../components/DetailPageFiles";
import { OpportunityDocumentRow } from "../../components/OpportunityDocumentRow";
import { getDocuments } from "../../services/opportunityApi";
import "./style.css";

export const OpportunityDocuments = () => {
  const { opportunity, setOpportunity, isEditable } = useOutletContext();

  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const loadDocuments = async () => {
      try {
        const data = await getDocuments(opportunity.id);
        setDocuments(data.document);
      } catch (err) {
        setError("Error fetching documents.");
        console.error(err);
      }
    };
    loadDocuments();
  }, [opportunity.id]);

  function formatDate(dateString) {
  const date = new Date(dateString);

  return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(date);
  }

  return (
    <div className="scrollable-content-documents">
      <div className="content">
        <div className="left-column">
          <DetailPageFiles
            className="design-component-instance-node-2"
            columnClassName="detail-page-files-table-header"
          />
          {error && <div className="error-message">{error}</div>}
          {documents.map((doc) => (
            <OpportunityDocumentRow
              key={doc.id}
              className="design-component-instance-node-2"
              text={doc.file_name}
              text1={doc.file_description}
              text3={formatDate(doc.created_at)}
              text2={doc.uploaded_by}
              text4={doc.status}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
