import { axiosInstance, setAuthHeaders, handleResponse, handleError } from './apiUtils';
import { useNavigate } from 'react-router-dom';

export const getAccount = async () => {
    setAuthHeaders();
    const response = await axiosInstance.get('/api/account');
    return handleResponse(response);
};

export const updateAccount = async (updatedData) => {
  setAuthHeaders();
    var payload = {
        "account": updatedData,
      }
  const response = await axiosInstance.put('/api/account', payload);
  return response.data;
};