import { axiosInstance, setAuthHeaders, handleResponse, handleError } from './apiUtils';
import { useNavigate } from 'react-router-dom';

export const getCensusData = async (Date) => {

    setAuthHeaders();
    var payload = {
        "date": Date
    }

    const response = await axiosInstance.get('/api/census', { params: payload });
    return handleResponse(response);
};

export const getDetailedCensusData = async (date, metric) => {

    setAuthHeaders();
    var payload = {
        "date": date,
        "metric": metric
    }

    const response = await axiosInstance.get('/api/census_details', { params: payload });
    return handleResponse(response);
};

export const getCapacityData = async () => {

    setAuthHeaders();
    const response = await axiosInstance.get('/api/capacity');
    return handleResponse(response);
};