/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Checkbox } from "../Checkbox";
import "./style.css";

export const ReferralsContacts = ({ className, divClassName }) => {
  return (
    <div className="referrals-orgs">
      <div className="columns">
        <div className="text-wrapper-21">Name</div>
        <div className="text-wrapper-21">Organization</div>
        <div className="text-wrapper-21">Role</div>
        <div className="text-wrapper-21">Email</div>
        <div className="text-wrapper-61">Created Date</div>
        <div className="text-wrapper-61">Actions</div>
      </div>
      <div className="border-2" />
    </div>
  );
};
