import React, { useState, useEffect } from "react";
import { Button } from "../../components/Button";
import { CapacityTabs } from "../../components/CapacityTabs";
import { InfoAndFilters } from "../../components/InfoAndFilters";
import { Referrals } from "../../components/Referrals";
import { ReferralsOrgs } from "../../components/ReferralsOrgs";
import { SidebarNavigation } from "../../components/SidebarNavigation";
import { TopNavigationBar } from "../../components/TopNavigationBar";
import { ModalAddNewReferral } from "../../components/ModalAddNewReferral";
import { IconAdd } from "../../icons/IconAdd";
import { formatDateTimeToReadableDateTimeWithoutTime } from "../../Utils/utils";
import { getReferrals, searchReferrals, saveReferral as saveReferralApi } from "../../services/referralApi";
import "./style.css";

export const ReferralsList = () => {
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getReferrals();
        if (data.referral) {
          setReferrals(data.referral);
        } else {
          setError("No referral data!");
        }
      } catch (error) {
        console.error("There was an error loading the data!", error);
        setError("There was an error loading the data!");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSearch = async (searchTerm) => {
    try {
      setLoading(true);
      const data = await searchReferrals(searchTerm);
      setReferrals(data.referral);
    } catch (error) {
        setNotificationStatus("fail");
        setNotificationMessage(error.message);
        setShowNotification(true);
      } finally {
        setLoading(false);
      }
  };

  const saveReferral = async (newReferral) => {
    try {
      const response = await saveReferralApi(newReferral);
      setReferrals((prevReferrals) => [response.referral, ...prevReferrals]);
      setIsModalOpen(false);
    } catch (error) {
      console.error("There was an error saving the referral!", error);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
   <div className="referrals-list-super">
   <InfoAndFilters
    className="info-and-filters-instance"
    hasCreatedInFilter={false}
    hasDiv={false}
    text={referrals.length}
    text1="Organizations"
    onSearch={handleSearch}
  />
   <div className="scrollable-content">
   <div className="referrals-list">
            <ReferralsOrgs
              className="design-component-instance-node-3"
              divClassName="design-component-instance-node-4"
            />
            {loading ? (
              <div className="spinner"></div>
            ) : (
              referrals.map((r, index) => (
                <Referrals
                  key={index}
                  className="design-component-instance-node-3"
                  dateClassName="design-component-instance-node-4"
                  name={r.referral_name}
                  type={r.type}
                  services={r.services_offered}
                  specializations={r.specializations}
                  payment={r.payment_type}
                  date={formatDateTimeToReadableDateTimeWithoutTime(r.created_at)}
                  referralId={r.id}
                />
              ))
            )}

          </div>
        </div>
        </div>
  );
};
