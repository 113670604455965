/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const VuesaxOutlineElement4 = ({ color = "#292D32", className }) => {
  return (
    <svg
      className={`vuesax-outline-element-4 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16.975 11.4596H13.6083C11.9333 11.4596 11.125 10.6846 11.125 9.08463V3.41797C11.125 1.81797 11.9417 1.04297 13.6083 1.04297H16.975C18.65 1.04297 19.4583 1.81797 19.4583 3.41797V9.08463C19.4583 10.6846 18.6417 11.4596 16.975 11.4596ZM13.6083 2.29297C12.55 2.29297 12.375 2.5763 12.375 3.41797V9.08463C12.375 9.9263 12.55 10.2096 13.6083 10.2096H16.975C18.0333 10.2096 18.2083 9.9263 18.2083 9.08463V3.41797C18.2083 2.5763 18.0333 2.29297 16.975 2.29297H13.6083Z"
        fill={color}
      />
      <path
        className="path"
        d="M16.975 18.957H13.6083C11.9333 18.957 11.125 18.182 11.125 16.582V15.082C11.125 13.482 11.9417 12.707 13.6083 12.707H16.975C18.65 12.707 19.4583 13.482 19.4583 15.082V16.582C19.4583 18.182 18.6417 18.957 16.975 18.957ZM13.6083 13.957C12.55 13.957 12.375 14.2404 12.375 15.082V16.582C12.375 17.4237 12.55 17.707 13.6083 17.707H16.975C18.0333 17.707 18.2083 17.4237 18.2083 16.582V15.082C18.2083 14.2404 18.0333 13.957 16.975 13.957H13.6083Z"
        fill={color}
      />
      <path
        className="path"
        d="M7.3915 18.9596H4.02484C2.34984 18.9596 1.5415 18.1846 1.5415 16.5846V10.918C1.5415 9.31797 2.35817 8.54297 4.02484 8.54297H7.3915C9.0665 8.54297 9.87484 9.31797 9.87484 10.918V16.5846C9.87484 18.1846 9.05817 18.9596 7.3915 18.9596ZM4.02484 9.79297C2.9665 9.79297 2.7915 10.0763 2.7915 10.918V16.5846C2.7915 17.4263 2.9665 17.7096 4.02484 17.7096H7.3915C8.44984 17.7096 8.62484 17.4263 8.62484 16.5846V10.918C8.62484 10.0763 8.44984 9.79297 7.3915 9.79297H4.02484Z"
        fill={color}
      />
      <path
        className="path"
        d="M7.3915 7.29297H4.02484C2.34984 7.29297 1.5415 6.51797 1.5415 4.91797V3.41797C1.5415 1.81797 2.35817 1.04297 4.02484 1.04297H7.3915C9.0665 1.04297 9.87484 1.81797 9.87484 3.41797V4.91797C9.87484 6.51797 9.05817 7.29297 7.3915 7.29297ZM4.02484 2.29297C2.9665 2.29297 2.7915 2.5763 2.7915 3.41797V4.91797C2.7915 5.75964 2.9665 6.04297 4.02484 6.04297H7.3915C8.44984 6.04297 8.62484 5.75964 8.62484 4.91797V3.41797C8.62484 2.5763 8.44984 2.29297 7.3915 2.29297H4.02484Z"
        fill={color}
      />
    </svg>
  );
};

VuesaxOutlineElement4.propTypes = {
  color: PropTypes.string,
};
