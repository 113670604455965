/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const DetailPageFiles = ({ className, columnClassName }) => {
  return (
    <div className={`detail-page-files ${className}`}>
      <div className="columns">
        <div className="column">
          <div className="text-wrapper-4">File name</div>
        </div>
        <div className="div-wrapper">
          <div className="text-wrapper-4">Description</div>
        </div>
         <div className="column-2">
          <div className="text-wrapper-4">Status</div>
        </div>
        <div className="column-2">
          <div className="text-wrapper-4">Uploaded by</div>
        </div>
        <div className="column-2">
          <div className="text-wrapper-4">Created at</div>
        </div>
      </div>
      <div className="border" />
    </div>
  );
};
