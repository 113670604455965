/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Logo3 = ({ color = "#3EA66C", className }) => {
  return (
    <svg
      className={`logo-3 ${className}`}
      fill="none"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" fill={color} height="48" rx="24" width="48" />
      <path
        className="path"
        clipRule="evenodd"
        d="M33.85 32.3442C33.85 33.268 34.5989 34.0169 35.5227 34.0169C36.4465 34.0169 37.1954 33.268 37.1954 32.3442V22.7427C37.1954 15.4032 31.2463 9.4541 23.9068 9.4541C16.5673 9.4541 10.6182 15.4032 10.6182 22.7427V32.3442C10.6182 33.268 11.3671 34.0169 12.2909 34.0169C13.2147 34.0169 13.9636 33.268 13.9636 32.3442V22.7427C13.9636 17.2508 18.4149 12.7996 23.9068 12.7996C29.3987 12.7996 33.85 17.2508 33.85 22.7427V32.3442ZM17.0734 32.5288C17.0734 33.4526 17.8223 34.2015 18.7462 34.2015C19.67 34.2015 20.4189 33.4526 20.4189 32.5288V22.8922C20.4189 20.9663 21.9798 19.4053 23.9057 19.4053C25.8317 19.4053 27.3926 20.9663 27.3926 22.8922C27.3926 23.816 28.1415 24.5649 29.0653 24.5649C29.9891 24.5649 30.738 23.816 30.738 22.8922C30.738 19.1186 27.6793 16.0599 23.9057 16.0599C20.1322 16.0599 17.0734 19.1186 17.0734 22.8922L17.0734 32.5288Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};

Logo3.propTypes = {
  color: PropTypes.string,
};
