import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { VuesaxOutlineArrowDown } from "../../icons/VuesaxOutlineArrowDown";
import "./style.css";

export const SearchDropdown = ({
  className,
  label,
  placeholder = "Select contact",
  onSearch,
  onSelect,
  value = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(value || "");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (option) => {
    if (option === "N/A") {
      setSearchTerm("");
      onSelect("");
    } else {
      setSearchTerm(option);
      onSelect(option);
    }
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setIsOpen(true);
    setLoading(true); // Start loading

    // Fetch options dynamically based on search term
    if (onSearch) {
      const results = await onSearch(value);
      setOptions(results || []);
      setLoading(false); // Stop loading once results are available
    }
  };

  const handleFocus = async () => {
    setIsOpen(true);
    if (!searchTerm && onSearch) {
      setLoading(true); // Start loading
      const results = await onSearch("");
      setOptions(results || []);
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className={`dropdown ${className}`} ref={dropdownRef}>
      {label && <div className="label">{label}</div>}
      <div className="input-and-options">
        <div className="div-3">
          <input
            type="text"
            className="placeholder"
            placeholder={placeholder}
            value={searchTerm}
            onChange={handleInputChange}
            onFocus={handleFocus}
            style={{ border: "none", outline: "none", background: "transparent" }}
          />
          <VuesaxOutlineArrowDown className="icon-dropdown" color="#7E92A2" />
        </div>
        {isOpen && (
          <div className="options">
            {/* Show spinner while loading */}
            {loading ? (
              <div className="spinner" />
            ) : (
              <>
                {/* "N/A" option at the top */}
                <div
                  className="option-item"
                  onClick={() => handleSelect("N/A")}
                >
                  N/A
                </div>
                {/* Dynamic options */}
                {options.length > 0 ? (
                  options.map((option, index) => (
                    <div
                      key={index}
                      className="option-item"
                      onClick={() => handleSelect(option)}
                    >
                      {option}
                    </div>
                  ))
                ) : (
                  <div className="no-options">No matching options</div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

SearchDropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
};
