/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const IconFilterAdd3 = ({ color = "#7E92A2", className }) => {
  return (
    <svg
      className={`icon-filter-add-3 ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16.8799 20.25C14.9599 20.25 13.1499 19.23 12.1699 17.58C11.6499 16.74 11.3799 15.76 11.3799 14.75C11.3799 11.72 13.8499 9.25 16.8799 9.25C19.9099 9.25 22.3799 11.72 22.3799 14.75C22.3799 15.76 22.0999 16.74 21.5799 17.59C20.6099 19.23 18.8099 20.25 16.8799 20.25ZM16.8799 10.75C14.6699 10.75 12.8799 12.54 12.8799 14.75C12.8799 15.48 13.0799 16.19 13.4599 16.8C14.1799 18.01 15.4899 18.75 16.8799 18.75C18.2999 18.75 19.5799 18.03 20.2999 16.81C20.6799 16.19 20.8799 15.48 20.8799 14.75C20.8799 12.54 19.0899 10.75 16.8799 10.75Z"
        fill={color}
      />
      <path
        className="path"
        d="M18.6599 15.48H15.1099C14.6999 15.48 14.3599 15.14 14.3599 14.73C14.3599 14.32 14.6999 13.98 15.1099 13.98H18.6599C19.0699 13.98 19.4099 14.32 19.4099 14.73C19.4099 15.14 19.0699 15.48 18.6599 15.48Z"
        fill={color}
      />
      <path
        className="path"
        d="M16.8799 17.29C16.4699 17.29 16.1299 16.95 16.1299 16.54V13C16.1299 12.59 16.4699 12.25 16.8799 12.25C17.2899 12.25 17.6299 12.59 17.6299 13V16.55C17.6299 16.96 17.2999 17.29 16.8799 17.29Z"
        fill={color}
      />
      <path
        className="path"
        d="M10.9298 22.75C10.4498 22.75 9.96981 22.63 9.53981 22.39C8.64981 21.89 8.1198 20.99 8.1198 19.98V14.63C8.1198 14.13 7.78981 13.37 7.46981 12.98L3.6698 8.98999C3.0398 8.33999 2.5498 7.25001 2.5498 6.45001V4.12C2.5498 2.51 3.76981 1.25 5.31981 1.25H18.6598C20.1898 1.25 21.4298 2.49002 21.4298 4.02002V6.23999C21.4298 7.28999 20.8098 8.47003 20.2098 9.09003L18.4098 10.68C18.2298 10.84 17.9798 10.9 17.7398 10.85C17.4698 10.78 17.1798 10.75 16.8798 10.75C14.6698 10.75 12.8798 12.54 12.8798 14.75C12.8798 15.48 13.0798 16.19 13.4598 16.81C13.7798 17.34 14.2098 17.78 14.7098 18.09C14.9298 18.23 15.0698 18.47 15.0698 18.73V19.07C15.0698 19.86 14.5898 20.97 13.7898 21.44L12.4098 22.33C11.9598 22.61 11.4398 22.75 10.9298 22.75ZM5.32981 2.75C4.61981 2.75 4.05981 3.35 4.05981 4.12V6.45001C4.05981 6.81001 4.3598 7.55001 4.7598 7.95001L8.60981 12C9.11981 12.63 9.62981 13.7 9.62981 14.64V19.99C9.62981 20.65 10.0898 20.98 10.2798 21.09C10.7098 21.33 11.2198 21.32 11.6098 21.08L13.0098 20.18C13.2798 20.02 13.5498 19.51 13.5698 19.15C13.0198 18.74 12.5398 18.21 12.1798 17.61C11.6598 16.76 11.3798 15.78 11.3798 14.78C11.3798 11.75 13.8498 9.28003 16.8798 9.28003C17.1598 9.28003 17.4398 9.30003 17.6998 9.34003L19.1798 8.03003C19.5198 7.68003 19.9398 6.85001 19.9398 6.26001V4.03998C19.9398 3.33998 19.3698 2.77002 18.6698 2.77002H5.32981V2.75Z"
        fill={color}
      />
    </svg>
  );
};

IconFilterAdd3.propTypes = {
  color: PropTypes.string,
};
