import React from "react";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Close } from "../../icons/Close";

export const ListOfContacts = ({ onClose, data, org, loading }) => {
  return (
    <div className="list-of">
      <div className="opportunity-list">
        <div className="opp-name-and-status">
          <div className="street">Contacts at {org}</div>
          <Close
            className="icon-instance-node"
            color="#7E92A2"
            onClick={onClose}
          />
        </div>
      </div>

      <div className="street-wrapper">
        <p className="text-wrapper">See contacts per your selection. Click to see detail view.</p>
      </div>

      {loading ? (
        <div className="spinner" />
      ) : (
        data && data.length > 0 ? (
          <>
            <div className="opportunity-snapshot-2">
              <div className="opp-name-and-status-2">
                <div className="street-3"># of contacts:</div>
                <div className="city">{data.length}</div>
              </div>
            </div>
            {data.map((contact, index) => (
              <Link key={index} to={`/contact/${contact.id}`} className="deal-repeat-link">
                <div className="opportunity-snapshot-2">
                  <div className="opp-name-and-status-2">
                    <p className="street-2">{contact.firstname} {contact.lastname}</p>
                  </div>
                  <div className="info-2">
                    <div className="text-wrapper-2">{contact.title}</div>
                  </div>
                </div>
              </Link>
            ))}
          </>
        ) : (
          <div className="street-wrapper">
            <p className="text-wrapper">No contacts available.</p>
          </div>
        )
      )}
    </div>
  );
};

ListOfContacts.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
      title: PropTypes.string,
      email: PropTypes.string,
    })
  ),
  org: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};
