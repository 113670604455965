/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const VuesaxBoldBriefcase3 = ({ className }) => {
  return (
    <svg
      className={`vuesax-bold-briefcase-3 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M28.1199 9.30677C26.9866 8.05344 25.0932 7.42677 22.3466 7.42677H22.0266V7.37344C22.0266 5.13344 22.0266 2.36011 17.0132 2.36011H14.9866C9.97322 2.36011 9.97322 5.14677 9.97322 7.37344V7.44011H9.65322C6.89322 7.44011 5.01322 8.06677 3.87989 9.32011C2.55989 10.7868 2.59989 12.7601 2.73322 14.1068L2.74655 14.2001L2.84983 15.2845C2.86885 15.4842 2.9764 15.6646 3.14425 15.7744C3.46399 15.9836 3.99912 16.3286 4.31989 16.5068C4.50655 16.6268 4.70655 16.7334 4.90655 16.8401C7.18655 18.0934 9.69322 18.9334 12.2399 19.3468C12.3599 20.6001 12.9066 22.0668 15.8266 22.0668C18.7466 22.0668 19.3199 20.6134 19.4132 19.3201C22.1332 18.8801 24.7599 17.9334 27.1332 16.5468C27.2132 16.5068 27.2666 16.4668 27.3332 16.4268C27.8622 16.1278 28.411 15.7594 28.9112 15.3986C29.0619 15.2899 29.1582 15.1218 29.1787 14.9371L29.1999 14.7468L29.2666 14.1201C29.2799 14.0401 29.2799 13.9734 29.2932 13.8801C29.3999 12.5334 29.3732 10.6934 28.1199 9.30677ZM17.4532 18.4401C17.4532 19.8534 17.4532 20.0668 15.8132 20.0668C14.1732 20.0668 14.1732 19.8134 14.1732 18.4534V16.7734H17.4532V18.4401ZM11.8799 7.37344C11.8799 5.10677 11.8799 4.26677 14.9866 4.26677H17.0132C20.1199 4.26677 20.1199 5.12011 20.1199 7.37344V7.44011H11.8799V7.37344Z"
        fill="#FE8084"
      />
      <path
        className="path"
        d="M27.8312 18.3123C28.3026 18.088 28.8457 18.4617 28.7985 18.9817L28.3199 24.2534C28.0399 26.9201 26.9466 29.6401 21.0799 29.6401H10.9199C5.05322 29.6401 3.95989 26.9201 3.67989 24.2668L3.2256 19.2697C3.17888 18.7557 3.70949 18.3824 4.17967 18.5952C5.69889 19.2825 8.50308 20.502 10.2353 20.9557C10.4544 21.013 10.6316 21.1698 10.7275 21.375C11.5368 23.1058 13.292 24.0268 15.8266 24.0268C18.336 24.0268 20.1134 23.0703 20.9254 21.3353C21.0215 21.13 21.1988 20.9732 21.418 20.9156C23.2574 20.4315 26.2423 19.0684 27.8312 18.3123Z"
        fill="#FE8084"
      />
    </svg>
  );
};
