import { axiosInstance, setAuthHeaders, handleResponse, handleError } from './apiUtils';
import { useNavigate } from 'react-router-dom';

export const getTaskById = async (task_id) => {
  try {
    setAuthHeaders();
    var payload = {
        "task_id": task_id
      }

    const response = await axiosInstance.get('/api/task_details', { params: payload });
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const updateTask = async (taskId, task) => {
  setAuthHeaders();

   var payload = {
        "task_id": taskId,
        "task": task
      }

  const response = await axiosInstance.put('/api/task_details', payload);
  return handleResponse(response);
};

export const getTasks = async () => {

    setAuthHeaders();
    const response = await axiosInstance.get('/api/tasks');
    return handleResponse(response);
};

export const saveTask = async (task) => {
  setAuthHeaders();
  const response = await axiosInstance.post('/api/tasks', task);
  return handleResponse(response);
};

export const searchTasks = async (query) => {
    setAuthHeaders();
    var payload = {"query": query}
    const response = await axiosInstance.get('/api/tasks', { params: payload });
    return handleResponse(response);
};

export const getUsers = async () => {

    setAuthHeaders();
    const response = await axiosInstance.get('/api/search_users');
    return handleResponse(response);
};
