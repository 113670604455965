import React, { useEffect, useState } from "react";
import { Base } from "../../components/Base";
import { Outlet, NavLink, useParams } from "react-router-dom";
import { CapacityTabs } from "../../components/CapacityTabs";
import { CensusDataRow } from "../../components/CensusDataRow";
import { TopNavigationBar } from "../../components/TopNavigationBar";
import { SidebarNavigation } from "../../components/SidebarNavigation";
import { ListOf } from "../../components/ListOf";
import { VuesaxBoldArrowLeft } from "../../icons/VuesaxBoldArrowLeft";
import { VuesaxBoldArrowRight } from "../../icons/VuesaxBoldArrowRight";
import { getCensusData, getDetailedCensusData } from "../../services/censusApi";
import "./style.css";

export const CapacityDetail = () => {
  return (
    <div className="capacity-census-view">
      <div className="vertical-top-nav">
        <TopNavigationBar
          className="design-component-instance-node-2"
          text="Capacity"
          baseBaseClassName="top-navigation-bar-instance"
        />
        <div className="side-nav-and-body">
          <SidebarNavigation className="sidebar-navigation-v3" />
         <div className="body-view">
         <div className="scrollable-content-capacity">
          <div className="body">
            <div className="deals-view-results">
              <CapacityTabs
               className="design-component-instance-node-2"
               text={
                <NavLink
                  to={`/capacity/capacity-clinician`}
                  className={({ isActive }) => (isActive ? "detail-page-link active" : "detail-page-link")}
                >
                  Clinician
                </NavLink>
              }
              text1={
                <NavLink
                  to={`/capacity/capacity-facility`}
                  className={({ isActive }) => (isActive ? "detail-page-link active" : "detail-page-link")}
                >
                  Facility
                </NavLink>
              }
              text2={
                <NavLink
                  to={`/capacity/census`}
                  className={({ isActive }) => (isActive ? "detail-page-link active" : "detail-page-link")}
                >
                  Census
                </NavLink>
              }
            />
            <Outlet />
        </div>
        </div>
      </div>
      </div>
    </div>
   </div>
  </div>
  );
};
