import React, { useEffect, useState } from "react";
import { Outlet, NavLink, useParams } from "react-router-dom";
import { Base } from "../../components/Base";
import { DetailPageHeader } from "../../components/DetailPageHeader";
import { DetailPage } from "../../components/DetailPage";
import { SidebarNavigation } from "../../components/SidebarNavigation";
import { TopNavigationBar } from "../../components/TopNavigationBar";
import { ChangeStatus } from "../../components/ChangeStatus";
import { ActionModal } from "../../components/ActionModal";
import { RecordActivityArea } from "../../components/RecordActivityArea";
import { LogActivityArea } from "../../components/LogActivityArea";
import { IconCalendar1 } from "../../icons/IconCalendar1";
import { getOpportunityById, updateOpportunity, uploadDocument, updateFacility, getDocuments } from "../../services/opportunityApi";
import { sendRecordToEMR } from "../../services/sendToEMRApi";
import { MultiStepProcess } from "../../components/MultiStepProcess";
import { getActivityRecord, saveActivity, askQuestion } from "../../services/activityApi";
import { DocumentUpload } from "../../components/DocumentUpload";
import { NotificationPopUp } from "../../components/NotificationPopUp";
import "./style.css";

export const OpportunityDetail = () => {
  const { opportunityId } = useParams();
  const [opportunity, setOpportunity] = useState(null);
  const [activity, setActivity] = useState([]);
  const [processingDocument, setProcessingDocument] = useState(false);
  const [processingStatus, setProcessingStatus] = useState("Processing");
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [buttonText, setButtonText] = useState("Edit Details");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMultiStepModalOpen, setIsMultiStepModalOpen] = useState(false);
  const [isDocumentUploadModalOpen, setIsDocumentUploadModalOpen] = useState(false);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState(null);
  const [actionModalMessage, setActionModalMessage] = useState("");
  const [navBarKey, setNavBarKey] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");

  useEffect(() => {
    const fetchOpportunityData = async () => {
      try {
        const opportunityData = await getOpportunityById(opportunityId);
        setOpportunity(opportunityData.opportunity);

        const documentData = await getDocuments(opportunityId);
        setDocuments(documentData.document);

        if (documentData.document.some(doc => doc.status === "Processing")) {
          setProcessingDocument(true);
          setProcessingStatus("Processing");
        } else {
          updateProcessingStatus(documentData.document);
        }
      } catch (err) {
        setError("Error fetching opportunity or documents.");
      } finally {
        setLoading(false);
      }
    };
    fetchOpportunityData();
  }, [opportunityId]);

  // Polling for document status updates
  useEffect(() => {
    if (!processingDocument) return;

    const intervalId = setInterval(async () => {
      try {
        const documentData = await getDocuments(opportunityId);
        setDocuments(documentData.document);
        updateProcessingStatus(documentData.document);

        if (documentData.document.every(doc => doc.status !== "Processing")) {
          setProcessingDocument(false);
          clearInterval(intervalId);
        }
      } catch (err) {
        setError("Error updating document status.");
        clearInterval(intervalId);
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [processingDocument, opportunityId]);

  const updateProcessingStatus = (documents) => {
    if (documents.every(doc => doc.status === "Completed")) {
      setProcessingStatus("Completed");
    } else if (documents.some(doc => doc.status === "Failed")) {
      setProcessingStatus("Failed");
    } else {
      setProcessingStatus("Processing");
    }
  };

  const handleEditClick = () => {
    if (isEditable) {
      handleSave();
    } else {
      setIsEditable(true);
      setButtonText("Save Details");
    }
  };

  const handleSave = async () => {
    try {
      await updateOpportunity(opportunityId, opportunity);
      setIsEditable(false);
      setButtonText("Edit Details");
      setNotificationStatus("success");
      setNotificationMessage("Successfully saved the details!");
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage("Error saving opportunity details");
    }
    finally {
      setShowNotification(true);
    }
  };

  const handleSaveActivity = async (activityData) => {
    try {
      activityData["opportunity_id"] = opportunityId;

      const payload = await saveActivity(activityData);
      setActivity((prev) => [payload.activity, ...prev]);
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage("Error saving activity");
      setShowNotification(true);
    }
  };

  const handleAskedQuestion = async (questionData) => {
    try {
      questionData["opportunity_id"] = opportunityId;
      return await askQuestion(questionData);
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage("Error asking the question");
      setShowNotification(true);
    }
  };

  const handleCloseModal = async (newStatus) => {
    try {
        await updateOpportunity(opportunityId, newStatus);
        setOpportunity((prev) => ({
          ...prev,
          status: newStatus.status,
        }));
        setNotificationStatus("success");
        setNotificationMessage("Successfully updated the status!");
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage("Error updating the status");
    } finally {
      setIsModalOpen(false);
      setShowNotification(true);
    }
  };

  const handleFinishProcess = async (processData) => {
    try {
      processData["status"] = "SCHEDULED-ADMIT";
      await updateOpportunity(opportunityId, processData);
      setIsMultiStepModalOpen(false);
      setOpportunity((prev) => ({
        ...prev,
        status: "SCHEDULED-ADMIT",
      }));
      setNotificationStatus("success");
      setNotificationMessage("Successfully scheduled admit!");
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage("Error saving admit details");
    }
    finally {
      setShowNotification(true);
    }
  };

  const handleUpload = async (file, autofill, fileDescription) => {
    try {
      setIsUploading(true);
      const response = await uploadDocument(opportunityId, file, autofill, fileDescription);
      setNotificationStatus("success");
      setNotificationMessage("Successfully uploaded the document!");
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage("Error uploading the document");
    } finally {
      setIsDocumentUploadModalOpen(false);
      setIsUploading(false);
      setShowNotification(true);
    }
  };

  const handleOpenDocumentUploadModal = () => {
    setIsDocumentUploadModalOpen(true);
  };

  const handleCloseDocumentUploadModal = () => {
    setIsDocumentUploadModalOpen(false);
  };

  const handleStatusChange = (newStatus) => {
      if (newStatus === "SCHEDULED-ADMIT") {
        setIsMultiStepModalOpen(true);
      } else {
        setUpdatedStatus(newStatus);
        setIsModalOpen(true);
    }
  };

  const handleActionClick = (selectedOption) => {
      setIsActionModalOpen(true);
      setActionModalMessage(selectedOption);
      setNavBarKey(prevKey => prevKey + 1);
  };

  const handleFacilityTransition = async (updatedFacility) => {
    try {
        const payload = await updateFacility(opportunityId, updatedFacility);
        setNotificationStatus("success");
        setNotificationMessage("Successfully updated the facility!");
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage("Error updating facility");
    } finally {
      setIsActionModalOpen(false);
      setShowNotification(true);
    }
  };

  const handleSendToEMR = async () => {
    try {
        payload = {"opportunity_id": opportunityId};
        await sendRecordToEMR(payload);
        setNotificationStatus("success");
        setNotificationMessage("Successfully sent to EHR!");
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage("Error sending to EHR");
    } finally {
      setIsActionModalOpen(false);
      setShowNotification(true);
    }
  };

  const handleCloseModalWithoutSave = () => {
    setIsModalOpen(false);
    setIsActionModalOpen(false);
  }

  if (loading) return <div className="spinner"></div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="opportunity-detail-wrapper">
      <TopNavigationBar
        key={navBarKey} // Use navBarKey to force re-render
        className="top-navigation-bar-v3"
        text="Opportunity Detail"
        baseBaseClassName="top-navigation-bar-instance"
        dropdownText="Actions"
        callBack={handleActionClick}
        processing={processingDocument}
        processingStatus={processingStatus}
      />
      <div className="horizontal-body-side-2">
        <SidebarNavigation className="sidebar-navigation-v3" />
        <div className="body-deal-detail-2">
          <div className="left-area-2">
            <DetailPageHeader
              key={opportunity?.status}
              name={`${opportunity.opportunity_name}`}
              buttonTypeIconOnlyStyleClassName="detail-page-header-2"
              className="detail-page-header-instance"
              onEditClick={handleEditClick}
              onFileUploadClick={handleOpenDocumentUploadModal}
              onProfileClick={opportunity?.patient_id}
              buttonText={buttonText}
              status={opportunity.status.replace(/ /g, '-')}
              onStatusChange={handleStatusChange}
              closeDropdown={isModalOpen || isMultiStepModalOpen}
            />
            <DetailPage
              className="design-component-instance-node-3"
              emailFieldClassName="detail-page-navigation-tabs"
              hasDiv={true}
              hasEmailField={true}
              text={
                <NavLink
                  to={`/opportunity/${opportunityId}/current-concerns`}
                  className={({ isActive }) => (isActive ? "detail-page-link active" : "detail-page-link")}
                >
                  Current Concerns
                </NavLink>
              }
              text1={
                <NavLink
                  to={`/opportunity/${opportunityId}/history`}
                  className={({ isActive }) => (isActive ? "detail-page-link active" : "detail-page-link")}
                >
                  History
                </NavLink>
              }
              text2={
                <NavLink
                  to={`/opportunity/${opportunityId}/referral-details`}
                  className={({ isActive }) => (isActive ? "detail-page-link active" : "detail-page-link")}
                >
                  Referral Details
                </NavLink>
              }
              text3={
                <NavLink
                  to={`/opportunity/${opportunityId}/treatment-plan`}
                  className={({ isActive }) => (isActive ? "detail-page-link active" : "detail-page-link")}
                >
                  Treatment Plan
                </NavLink>
              }
              text4={
                <NavLink
                  to={`/opportunity/${opportunityId}/documents`}
                  className={({ isActive }) => (isActive ? "detail-page-link active" : "detail-page-link")}
                >
                  Documents
                </NavLink>
              }
            />
            <Outlet context={{ opportunity, setOpportunity, isEditable }} />
          </div>
        </div>
        <Base className="base-6" />
        <div className="right-area-2">
          <div className="scrollable-content-opportunity">
            <RecordActivityArea
              className="design-component-instance-node-5"
              inputIcon={<IconCalendar1 className="icon-instance-node-6" />}
              onSaveActivity={handleSaveActivity}
              onAskedQuestion={handleAskedQuestion}
            />
            <LogActivityArea
              key={activity.length}  // Force re-render by using activity length as key
              className="design-component-instance-node-5"
              activities={activity}
            />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-overlay-change-status">
          <ChangeStatus status={updatedStatus} onClose={handleCloseModalWithoutSave} onSave={handleCloseModal} />
        </div>
      )}
      {isActionModalOpen && (
        <div className="modal-overlay-change-status">
          <ActionModal message={actionModalMessage} action={handleSendToEMR} onClose={handleCloseModalWithoutSave} />
        </div>
      )}
      {isMultiStepModalOpen && (
        <div className="modal-overlay-change-status">
          <MultiStepProcess
            onClose={() => setIsMultiStepModalOpen(false)}
            onFinish={handleFinishProcess}
          />
        </div>
      )}
      {isDocumentUploadModalOpen && (
        <div className="modal-overlay-change-status">
          {isUploading ? (
            <div className="spinner"></div>
          ) : (
            <DocumentUpload onClose={handleCloseDocumentUploadModal} onUpload={handleUpload} />
          )}
        </div>
      )}
      {showNotification && (
          <NotificationPopUp
            message={notificationMessage}
            status={notificationStatus}
            iconColor="#2DC8A8"
            closeIconColor="white"
            duration={3000}  // Display for 3 seconds
            onClose={() => setShowNotification(false)}
          />
        )}
    </div>
  );
};
