/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconOpportunities = ({ className }) => {
  return (
    <svg
      className={`icon-opportunities ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M13.8332 18.9584H7.16653C3.31653 18.9584 2.59986 17.1667 2.41653 15.4251L1.79153 8.75008C1.69986 7.87508 1.67486 6.58341 2.54153 5.61675C3.29153 4.78341 4.5332 4.38341 6.3332 4.38341H14.6665C16.4749 4.38341 17.7165 4.79175 18.4582 5.61675C19.3249 6.58341 19.2999 7.87508 19.2082 8.75842L18.5832 15.4167C18.3999 17.1667 17.6832 18.9584 13.8332 18.9584ZM6.3332 5.62508C4.92486 5.62508 3.9582 5.90008 3.46653 6.45008C3.0582 6.90008 2.92486 7.59175 3.0332 8.62508L3.6582 15.3001C3.79986 16.6167 4.1582 17.7084 7.16653 17.7084H13.8332C16.8332 17.7084 17.1999 16.6167 17.3415 15.2917L17.9665 8.63341C18.0749 7.59175 17.9415 6.90008 17.5332 6.45008C17.0415 5.90008 16.0749 5.62508 14.6665 5.62508H6.3332Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M13.8332 5.62508C13.4915 5.62508 13.2082 5.34175 13.2082 5.00008V4.33341C13.2082 2.85008 13.2082 2.29175 11.1665 2.29175H9.8332C7.79153 2.29175 7.79153 2.85008 7.79153 4.33341V5.00008C7.79153 5.34175 7.5082 5.62508 7.16653 5.62508C6.82486 5.62508 6.54153 5.34175 6.54153 5.00008V4.33341C6.54153 2.86675 6.54153 1.04175 9.8332 1.04175H11.1665C14.4582 1.04175 14.4582 2.86675 14.4582 4.33341V5.00008C14.4582 5.34175 14.1749 5.62508 13.8332 5.62508Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M10.4999 13.9584C8.2082 13.9584 8.2082 12.5417 8.2082 11.6917V10.8334C8.2082 9.65841 8.49153 9.37508 9.66653 9.37508H11.3332C12.5082 9.37508 12.7915 9.65841 12.7915 10.8334V11.6667C12.7915 12.5334 12.7915 13.9584 10.4999 13.9584ZM9.4582 10.6251C9.4582 10.6917 9.4582 10.7667 9.4582 10.8334V11.6917C9.4582 12.5501 9.4582 12.7084 10.4999 12.7084C11.5415 12.7084 11.5415 12.5751 11.5415 11.6834V10.8334C11.5415 10.7667 11.5415 10.6917 11.5415 10.6251C11.4749 10.6251 11.3999 10.6251 11.3332 10.6251H9.66653C9.59986 10.6251 9.52486 10.6251 9.4582 10.6251Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M12.1665 12.3084C11.8582 12.3084 11.5832 12.0751 11.5499 11.7584C11.5082 11.4167 11.7499 11.1001 12.0915 11.0584C14.2915 10.7834 16.3999 9.95008 18.1749 8.65842C18.4499 8.45008 18.8415 8.51675 19.0499 8.80008C19.2499 9.07508 19.1915 9.46675 18.9082 9.67508C16.9582 11.0917 14.6582 12.0001 12.2415 12.3084C12.2165 12.3084 12.1915 12.3084 12.1665 12.3084Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M8.8332 12.3167C8.8082 12.3167 8.7832 12.3167 8.7582 12.3167C6.47486 12.0584 4.24986 11.2251 2.32486 9.90842C2.04153 9.71675 1.96653 9.32508 2.1582 9.04175C2.34986 8.75842 2.74153 8.68341 3.02486 8.87508C4.7832 10.0751 6.8082 10.8334 8.89153 11.0751C9.2332 11.1167 9.4832 11.4251 9.44153 11.7667C9.41653 12.0834 9.14986 12.3167 8.8332 12.3167Z"
        fill="#7E92A2"
      />
    </svg>
  );
};
