import React from "react";
import { useOutletContext } from "react-router-dom";
import { Input } from "../../components/Input";
import { DetailDataCell } from "../../components/DetailDataCell";
import { InputWithDatePicker } from "../../components/InputWithDatePicker";

import "./style.css";

export const OpportunityTreatmentPlan = () => {
  const { opportunity, setOpportunity, isEditable } = useOutletContext();

  const handleChange = (field, value) => {
    setOpportunity((prevOpportunity) => ({ ...prevOpportunity, [field]: value }));
  };

  return (
    <div className="scrollable-content-treatment-plan">
      <div className="content-6">
        <div className="left-column-6">
          <div className="row-4">
            {isEditable ? (
              <Input
                className="input-6"
                label="Facility"
                value={opportunity.facility || ""}
                onChange={(e) => handleChange("facility", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-2"
                label="Facility"
                value={opportunity.facility || "N/A"}
              />
            )}
            {isEditable ? (
             <InputWithDatePicker
                className="input-6"
                label="Admit Date"
                currentSelection={opportunity.scheduled_admit_date ? new Date(`${opportunity.scheduled_admit_date}T00:00:00`) : new Date()}
                onDateChange={(date) => handleChange("scheduled_admit_date", date.toISOString().split('T')[0])}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-2"
                label="Admit Date"
                value={opportunity.scheduled_admit_date || "N/A"}
              />
            )}
            {isEditable ? (
            <InputWithDatePicker
                className="input-6"
                label="Discharge Date"
                currentSelection={opportunity.scheduled_discharge_date ? new Date(`${opportunity.scheduled_discharge_date}T00:00:00`) : new Date()}
                onDateChange={(date) => handleChange("scheduled_discharge_date", date.toISOString().split('T')[0])}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-2"
                label="Discharge Date"
                value={opportunity.scheduled_discharge_date || "N/A"}
              />
            )}
          </div>
          {opportunity.facility_transition && opportunity.facility_transition.length > 0 &&
              opportunity.facility_transition.map((transition, index) => (
                <div className="row-4" key={index}>
                  {isEditable ? (
                    <Input
                      className="input-6"
                      label="Moved from facility"
                      value={transition.move_from || ""}
                    />
                  ) : (
                    <DetailDataCell
                      className="detail-data-cell-2"
                      label="Moved from facility"
                      value={transition.move_from || "N/A"}
                    />
                  )}

                  {isEditable ? (
                    <Input
                      className="input-6"
                      label="Moved to facility"
                      value={transition.move_to || ""}
                    />
                  ) : (
                    <DetailDataCell
                      className="detail-data-cell-2"
                      label="Moved to facility"
                      value={transition.move_to || "N/A"}
                    />
                  )}
                  {isEditable ? (
                    <Input
                      className="input-6"
                      label="Transition date"
                      value={transition.transition_at || ""}
                    />
                  ) : (
                    <DetailDataCell
                      className="detail-data-cell-2"
                      label="Transition date"
                      value={transition.transition_at || "N/A"}
                    />
                  )}
                </div>
              ))
            }

          <div className="row-4">
            {isEditable ? (
              <Input
                className="input-6"
                label="MD"
                value={opportunity.md || ""}
                onChange={(e) => handleChange("md", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-2"
                label="MD"
                value={opportunity.md || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Therapist"
                value={opportunity.therapist || ""}
                onChange={(e) => handleChange("therapist", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-2"
                label="Therapist"
                value={opportunity.therapist || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="CC"
                value={opportunity.cc || ""}
                onChange={(e) => handleChange("cc", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-2"
                label="CC"
                value={opportunity.cc || "N/A"}
              />
            )}
          </div>
          <div className="row-4">
            {isEditable ? (
              <Input
                className="input-6"
                label="Medication plan"
                value={opportunity.medication_plan || ""}
                onChange={(e) => handleChange("medication_plan", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-2"
                label="Medication plan"
                value={opportunity.medication_plan || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Last updated Date (discharge)"
                value={opportunity.updated_at || ""}
                onChange={(e) => handleChange("updated_at", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-2"
                label="Last updated Date (discharge)"
                value={opportunity.updated_at || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Priority"
                value={opportunity.priority || ""}
                onChange={(e) => handleChange("priority", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-2"
                label="Priority"
                value={opportunity.priority || "N/A"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
