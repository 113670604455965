import PropTypes from "prop-types";
import React from "react";
import { Icon8 } from "../../icons/Icon8";
import "./style.css";

export const Button = ({
  type,
  style,
  className,
  text = "Button",
  icon = <Icon8 className="icon" color="#ECECFE" />,
  override = <Icon8 className="icon-8" color="#7E92A2" />,
  hasDiv = false,
  divClassName,
  onClick,
  disabled = false,
}) => {
  return (
    <div
      className={`button ${style} ${type} ${className} ${disabled ? "disabled" : ""}`} // Add "disabled" class if disabled
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
      style={disabled ? { cursor: "not-allowed", opacity: 0.5 } : {}} // Add visual feedback for disabled state
    >
      {type === "icon-only" && <>{override}</>}

      {type === "left-icon" && <Icon8 className="icon" color={style === "primary" ? "#ECECFE" : "#7E92A2"} />}

      {type === "right-icon" && <div className="text-wrapper">{text}</div>}

      {["left-icon", "right-icon"].includes(type) && (
        <>
          <>
            {hasDiv && (
              <>
                <>
                  {type === "left-icon" && <div className="div">{text}</div>}

                  {type === "right-icon" && <>{icon}</>}
                </>
              </>
            )}
          </>
        </>
      )}

      {type === "default" && <div className={`text-wrapper-2 ${divClassName}`}>{text}</div>}
    </div>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["icon-only", "right-icon", "left-icon", "default"]),
  style: PropTypes.oneOf(["primary", "white"]),
  text: PropTypes.string,
  hasDiv: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
