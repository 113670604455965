/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ReferralsTable = ({ className, text = "Accepted" }) => {
  return (
    <div className={`referrals-table ${className}`}>
      <div className="columns-2">
        <div className="div-wrapper-2">
          <div className="text-wrapper-12">Mode</div>
        </div>
        <div className="div-wrapper-3">
          <div className="text-wrapper-12">Received</div>
        </div>
        <div className="div-wrapper-3">
          <div className="text-wrapper-12">{text}</div>
        </div>
        <div className="date-created-column">
          <div className="text-wrapper-13">Last Referral Date</div>
        </div>
        <div className="div-wrapper-2">
          <div className="text-wrapper-13">Avg Time to Accept</div>
        </div>
        <div className="priority-column">
          <div className="text-wrapper-12">Drop Rate</div>
        </div>
      </div>
      <div className="border-4" />
    </div>
  );
};

ReferralsTable.propTypes = {
  text: PropTypes.string,
};
