/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { VuesaxOutlineCopy } from "../../icons/VuesaxOutlineCopy";
import "./style.css";

export const Activity = ({ className, event = "", name = "", description = "", date }) => {
  return (
    <div className={`activity ${className}`}>
      <div className="frame">
        <div className="label">{event}</div>
        <p className="label-2">{description}</p>
      </div>
      <div className="frame-2">
        <div className="date-and-time">
          <div className="label-3">{name}</div>
          <div className="label-3">{date}</div>
          <VuesaxOutlineCopy className="vuesax-outline-copy" color="#7E92A2" />
        </div>
        <div className="div-5">
          <p className="label-3">
            <span className="span"></span>
            <span className="text-wrapper-15"></span>
          </p>
          <div className="div-5">
            <div className="label-3"></div>
            <div className="label-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

Activity.propTypes = {
  event: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string
};
