/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Check = ({ color = "#1D1B20", className }) => {
  return (
    <svg
      className={`check ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.36667 12.0001L2.56667 8.20013L3.51667 7.25013L6.36667 10.1001L12.4833 3.98346L13.4333 4.93346L6.36667 12.0001Z"
        fill={color}
      />
    </svg>
  );
};

Check.propTypes = {
  color: PropTypes.string,
};
