import React, { useState } from "react";
import PropTypes from "prop-types";
import { VuesaxOutlineAttachSquare } from "../../icons/VuesaxOutlineAttachSquare";
import { DetailDataCellModalOverview } from "../DetailDataCellModalOverview";
import "./style.css";

export const DetailDataCell = ({
  className,
  overlapGroupClassName,
  label = "Label",
  value = "",
  visible = false,
  appointmentDateClassName,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={`detail-data-cell ${className}`} onClick={handleOpenModal}>
        <div className={`overlap-group ${overlapGroupClassName}`}>
          <div className="label">{label}</div>
          <div className="frame">
            <div className={`appointment-date ${appointmentDateClassName}`}>{value}</div>
            {visible && <VuesaxOutlineAttachSquare className="icon-attachment" color="#526477" />}
          </div>
        </div>
      </div>

      <DetailDataCellModalOverview
        isOpen={isModalOpen}
        label={label}
        value={value}
        onClose={handleCloseModal}
      />
    </>
  );
};

DetailDataCell.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  visible: PropTypes.bool,
  className: PropTypes.string,
  overlapGroupClassName: PropTypes.string,
  appointmentDateClassName: PropTypes.string,
};
