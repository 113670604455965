/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Checkbox } from "../Checkbox";
import "./style.css";

export const ReferralsOrgs = ({ className, divClassName }) => {
  return (
    <div className={`referrals-orgs ${className}`}>
      <div className="columns">
        <div className="text-wrapper-7">Organization</div>
        <div className="text-wrapper-7">Type</div>
        <div className="text-wrapper-7">Payment type</div>
        <div className="text-wrapper-7">Specializations</div>
        <div className="text-wrapper-7">Last Activity Date</div>
      </div>
      <div className="border-2" />
    </div>
  );
};
