/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Check } from "../../icons/Check";
import { Icon12 } from "../../icons/Icon12";
import { Icon6 } from "../../icons/Icon6";
import { Icon7 } from "../../icons/Icon7";
import { Icon8 } from "../../icons/Icon8";
import "./style.css";

export const Switch = ({ selected, stateProp, icon, handleShapeClassName }) => {
  const [state, dispatch] = useReducer(reducer, {
    selected: selected || true,
    state: stateProp || "enabled",
    icon: icon || false,
  });

  return (
    <div className={`switch selected-${state.selected} ${state.state} icon-${state.icon}`}>
      <div className="handle">
        <div
          className="target"
          onMouseLeave={() => {
            dispatch("mouse_leave");
          }}
          onMouseEnter={() => {
            dispatch("mouse_enter");
          }}
        >
          <div className="state-layer">
            <div className={`handle-shape ${handleShapeClassName}`}>
              {((!state.icon && state.selected && state.state === "disabled") ||
                (!state.icon && state.state === "enabled") ||
                (!state.icon && state.state === "focused") ||
                (!state.icon && state.state === "hovered") ||
                (!state.icon && state.state === "pressed") ||
                (!state.selected && state.state === "disabled")) && <div className="container" />}

              {((state.icon && state.selected && state.state === "disabled") ||
                (state.icon && state.state === "enabled") ||
                (state.icon && state.state === "focused") ||
                (state.icon && state.state === "hovered") ||
                (state.icon && state.state === "pressed")) && <div className="container-2" />}

              {((state.icon && state.selected && state.state === "enabled") ||
                (state.icon && state.selected && state.state === "focused") ||
                (state.icon && state.selected && state.state === "hovered")) && (
                <Check className="instance-node" color="#21005D" />
              )}

              {state.selected && state.state === "pressed" && state.icon && <Icon6 className="icon-6" />}

              {state.selected && state.state === "disabled" && state.icon && <Icon7 className="instance-node" />}

              {((state.icon && !state.selected && state.state === "enabled") ||
                (state.icon && !state.selected && state.state === "focused") ||
                (state.icon && !state.selected && state.state === "hovered") ||
                (state.icon && !state.selected && state.state === "pressed")) && (
                <Icon8 className={`${state.state === "pressed" ? "icon-6" : "instance-node"}`} />
              )}

              {state.state === "disabled" && !state.selected && state.icon && <Icon12 className="instance-node" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hovered",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "enabled",
      };
  }

  return state;
}

Switch.propTypes = {
  selected: PropTypes.bool,
  stateProp: PropTypes.oneOf(["enabled", "pressed", "focused", "hovered", "disabled"]),
  icon: PropTypes.bool,
};
