import React from 'react';

export const Counter = ({ title, value, Icon, iconBackground }) => {
  return (
    <div className="counter-wrapper">
      <div className="counter-content">
        <div className="counter-title">{title}</div>
        <div className="counter-value">{value}</div>
        <div className="counter-icon-wrapper" style={{ background: iconBackground }}>
          <Icon className="counter-icon" />
        </div>
      </div>
    </div>
  );
};
