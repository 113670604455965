import React from "react";
import { useOutletContext } from "react-router-dom";
import { Input } from "../../components/Input";
import { DetailDataCell } from "../../components/DetailDataCell";
import "./style.css";

export const OpportunityCurrentConcerns = () => {
  const { opportunity, setOpportunity, isEditable } = useOutletContext();

  const handleChange = (field, value) => {
    setOpportunity((prevOpportunity) => ({ ...prevOpportunity, [field]: value }));
  };

  return (
  <div className="scrollable-content-opp-current-concerns">
    <div className="left-column-wrapper">
      <div className="left-column-2">
        <div className="div-9">
          {isEditable ? (
            <Input
              className="input-6"
              label="Primary Condition"
              value={opportunity.condition || ""}
              onChange={(e) => handleChange("condition", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Primary Condition"
              value={opportunity.condition || "N/A"}
            />
          )}
          {isEditable ? (
            <Input
              className="input-6"
              label="Created Date"
              value={opportunity.created_date || ""}
              onChange={(e) => handleChange("created_date", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Created Date"
              value={opportunity.created_date || "N/A"}
            />
          )}
          {isEditable ? (
            <Input
              className="input-6"
              label="Next Appointment"
              value={opportunity.next_appointment || ""}
              onChange={(e) => handleChange("next_appointment", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Next Appointment"
              value={opportunity.next_appointment || "N/A"}
            />
          )}
        </div>
        <div className="div-9">
          {isEditable ? (
            <Input
              className="input-6"
              label="Staff"
              value={opportunity.staff || ""}
              onChange={(e) => handleChange("staff", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Staff"
              value={opportunity.staff || "N/A"}
            />
          )}
          {isEditable ? (
            <Input
              className="input-6"
              label="Allergies"
              value={opportunity.allergies || ""}
              onChange={(e) => handleChange("allergies", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Allergies"
              value={opportunity.allergies || "N/A"}
            />
          )}
          {isEditable ? (
            <Input
              className="input-6"
              label="Medication"
              value={opportunity.medication || ""}
              onChange={(e) => handleChange("medication", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Medication"
              value={opportunity.medication || "N/A"}
            />
          )}
        </div>
        <div className="div-9">
          {isEditable ? (
            <Input
              className="input-6"
              label="Scheduled Admit Date"
              value={opportunity.scheduled_admit_date || ""}
              onChange={(e) => handleChange("scheduled_admit_date", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Scheduled Admit Date"
              value={opportunity.scheduled_admit_date || "N/A"}
            />
          )}
          {isEditable ? (
            <Input
              className="input-6"
              label="Transportation Required"
              value={opportunity.transportation_required || ""}
              onChange={(e) => handleChange("transportation_required", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Transportation Required"
              value={opportunity.transportation_required || "N/A"}
            />
          )}
          {isEditable ? (
            <Input
              className="input-6"
              label="Expected Discharge Date"
              value={opportunity.expected_discharge_date || ""}
              onChange={(e) => handleChange("expected_discharge_date", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Expected Discharge Date"
              value={opportunity.expected_discharge_date || "N/A"}
            />
          )}
        </div>
        <div className="div-9">
          {isEditable ? (
            <Input
              className="input-6"
              label="Contact Person"
              value={opportunity.contact_person || ""}
              onChange={(e) => handleChange("contact_person", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Contact Person"
              value={opportunity.contact_person || "N/A"}
            />
          )}
          {isEditable ? (
            <Input
              className="input-6"
              label="Contact Person Phone"
              value={opportunity.contact_person_phone || ""}
              onChange={(e) => handleChange("contact_person_phone", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Contact Person Phone"
              value={opportunity.contact_person_phone || "N/A"}
            />
          )}
          {isEditable ? (
            <Input
              className="input-6"
              label="Contact Person Email"
              value={opportunity.contact_person_email || ""}
              onChange={(e) => handleChange("contact_person_email", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Contact Person Email"
              value={opportunity.contact_person_email || "N/A"}
            />
          )}
        </div>
        <div className="div-9">
          {isEditable ? (
            <Input
              className="input-6"
              label="Current Psychiatrist Name"
              value={opportunity.current_psychiatrist_name || ""}
              onChange={(e) => handleChange("current_psychiatrist_name", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Current Psychiatrist Name"
              value={opportunity.current_psychiatrist_name || "N/A"}
            />
          )}
          {isEditable ? (
            <Input
              className="input-6"
              label="Current Psychiatrist Phone"
              value={opportunity.current_psychiatrist_phone || ""}
              onChange={(e) => handleChange("current_psychiatrist_phone", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Current Psychiatrist Phone"
              value={opportunity.current_psychiatrist_phone || "N/A"}
            />
          )}
          {isEditable ? (
            <Input
              className="input-6"
              label="Current Psychiatrist Email"
              value={opportunity.current_psychiatrist_email || ""}
              onChange={(e) => handleChange("current_psychiatrist_email", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Current Psychiatrist Email"
              value={opportunity.current_psychiatrist_email || "N/A"}
            />
          )}
        </div>
        <div className="div-9">
          {isEditable ? (
            <Input
              className="input-6"
              label="Current Therapist Name"
              value={opportunity.current_therapist_name || ""}
              onChange={(e) => handleChange("current_therapist_name", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Current Therapist Name"
              value={opportunity.current_therapist_name || "N/A"}
            />
          )}
          {isEditable ? (
            <Input
              className="input-6"
              label="Current Therapist Phone"
              value={opportunity.current_therapist_phone || ""}
              onChange={(e) => handleChange("current_therapist_phone", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Current Therapist Phone"
              value={opportunity.current_therapist_phone || "N/A"}
            />
          )}
          {isEditable ? (
            <Input
              className="input-6"
              label="Current Therapist Email"
              value={opportunity.current_therapist_email || ""}
              onChange={(e) => handleChange("current_therapist_email", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Current Therapist Email"
              value={opportunity.current_therapist_email || "N/A"}
            />
          )}
        </div>
        <div className="div-9">
          {isEditable ? (
            <Input
              className="input-6"
              label="Current PCP Name"
              value={opportunity.current_pcp_name || ""}
              onChange={(e) => handleChange("current_pcp_name", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Current PCP Name"
              value={opportunity.current_pcp_name || "N/A"}
            />
          )}
          {isEditable ? (
            <Input
              className="input-6"
              label="Current PCP Phone"
              value={opportunity.current_pcp_phone || ""}
              onChange={(e) => handleChange("current_pcp_phone", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Current PCP Phone"
              value={opportunity.current_pcp_phone || "N/A"}
            />
          )}
          {isEditable ? (
            <Input
              className="input-6"
              label="Current PCP Email"
              value={opportunity.current_pcp_email || ""}
              onChange={(e) => handleChange("current_pcp_email", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Current PCP Email"
              value={opportunity.current_pcp_email || "N/A"}
            />
          )}
        </div>
        <div className="div-9">
          {isEditable ? (
            <Input
              className="input-6"
              label="Why Considering Residential"
              value={opportunity.why_considering_residential || ""}
              onChange={(e) => handleChange("why_considering_residential", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Why Considering Residential"
              value={opportunity.why_considering_residential || "N/A"}
            />
          )}
        </div>
        <div className="div-9">
          {isEditable ? (
            <Input
              className="input-6"
              label="Additional Instructions"
              value={opportunity.additional_instructions || ""}
              onChange={(e) => handleChange("additional_instructions", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-instance"
              label="Additional Instructions"
              value={opportunity.additional_instructions || "N/A"}
            />
          )}
          {isEditable ? (
            <Input
              className="input-6"
              label="Additional Treatment or Support Needed"
              value={opportunity.additional_treatment_support_needed || ""}
              onChange={(e) => handleChange("additional_treatment_support_needed", e.target.value)}
            />
          ) : (
            <DetailDataCell
              className="detail-data-cell-v"
              label="Additional Treatment or Support Needed"
              value={opportunity.additional_treatment_support_needed || "N/A"}
            />
          )}
        </div>
      </div>
    </div>
   </div>
  );
};
