import React from "react";
import { useOutletContext } from "react-router-dom";
import { Input } from "../../components/Input";
import { DetailDataCell } from "../../components/DetailDataCell";
import "./style.css";

export const OpportunityHistory = () => {
  const { opportunity, setOpportunity, isEditable } = useOutletContext();

  const handleChange = (field, value) => {
    setOpportunity((prevOpportunity) => ({ ...prevOpportunity, [field]: value }));
  };

  return (
    <div className="scrollable-content-history">
      <div className="content-4">
        <div className="left-column-4">
          <div className="div-10">
            {isEditable ? (
              <Input
                className="input-6"
                label="Hospitalizations"
                value={opportunity.hospitalizations || ""}
                onChange={(e) => handleChange("hospitalizations", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Hospitalizations"
                value={opportunity.hospitalizations || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Residential programs"
                value={opportunity.residential_programs || ""}
                onChange={(e) => handleChange("residential_programs", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Residential programs"
                value={opportunity.residential_programs || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Other levels of care"
                value={opportunity.other_levels_of_care || ""}
                onChange={(e) => handleChange("other_levels_of_care", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Other levels of care"
                value={opportunity.other_levels_of_care || "N/A"}
              />
            )}
          </div>
          <div className="div-10">
            {isEditable ? (
              <Input
                className="input-6"
                label="Psych testing done"
                value={opportunity.psych_testing_done || ""}
                onChange={(e) => handleChange("psych_testing_done", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Psych testing done"
                value={opportunity.psych_testing_done || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="History of self harm"
                value={opportunity.history_of_self_harm || ""}
                onChange={(e) => handleChange("history_of_self_harm", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="History of self harm"
                value={opportunity.history_of_self_harm || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Eating disorder"
                value={opportunity.eating_disorder || ""}
                onChange={(e) => handleChange("eating_disorder", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Eating disorder"
                value={opportunity.eating_disorder || "N/A"}
              />
            )}
          </div>
          <div className="div-10">
            {isEditable ? (
              <Input
                className="input-6"
                label="Pending legal charges"
                value={opportunity.pending_legal_charges || ""}
                onChange={(e) => handleChange("pending_legal_charges", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Pending legal charges"
                value={opportunity.pending_legal_charges || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Probation"
                value={opportunity.probation || ""}
                onChange={(e) => handleChange("probation", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Probation"
                value={opportunity.probation || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Upcoming court date"
                value={opportunity.upcoming_court_date || ""}
                onChange={(e) => handleChange("upcoming_court_date", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Upcoming court date"
                value={opportunity.upcoming_court_date || "N/A"}
              />
            )}
          </div>
          <div className="div-10">
            {isEditable ? (
              <Input
                className="input-6"
                label="History of suicide attempts"
                value={opportunity.history_of_suicide_attempts || ""}
                onChange={(e) => handleChange("history_of_suicide_attempts", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="History of suicide attempts"
                value={opportunity.history_of_suicide_attempts || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Knowingly ingested allergen"
                value={opportunity.knowingly_ingested_allergen || ""}
                onChange={(e) => handleChange("knowingly_ingested_allergen", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Knowingly ingested allergen"
                value={opportunity.knowingly_ingested_allergen || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Recent substance abuse"
                value={opportunity.recent_substance_abuse || ""}
                onChange={(e) => handleChange("recent_substance_abuse", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Recent substance abuse"
                value={opportunity.recent_substance_abuse || "N/A"}
              />
            )}
          </div>
          <div className="div-10">
            {isEditable ? (
              <Input
                className="input-6"
                label="Aggression or property damage"
                value={opportunity.aggression_or_property_damage || ""}
                onChange={(e) => handleChange("aggression_or_property_damage", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Aggression or property damage"
                value={opportunity.aggression_or_property_damage || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Outpatient therapy"
                value={opportunity.outpatient_therapy || ""}
                onChange={(e) => handleChange("outpatient_therapy", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Outpatient therapy"
                value={opportunity.outpatient_therapy || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Upcoming court date"
                value={opportunity.upcoming_court_date || ""}
                onChange={(e) => handleChange("upcoming_court_date", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Upcoming court date"
                value={opportunity.upcoming_court_date || "N/A"}
              />
            )}
          </div>
          <div className="div-10">
            {isEditable ? (
              <Input
                className="input-6"
                label="History of seizures"
                value={opportunity.history_of_seizures || ""}
                onChange={(e) => handleChange("history_of_seizures", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="History of seizures"
                value={opportunity.history_of_seizures || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Completed genetic testing"
                value={opportunity.completed_genetic_testing || ""}
                onChange={(e) => handleChange("completed_genetic_testing", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Completed genetic testing"
                value={opportunity.completed_genetic_testing || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Epipen"
                value={opportunity.epipen || ""}
                onChange={(e) => handleChange("epipen", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Epipen"
                value={opportunity.epipen || "N/A"}
              />
            )}
          </div>
          <div className="div-10">
            {isEditable ? (
              <Input
                className="input-6"
                label="History of trauma"
                value={opportunity.history_of_trauma || ""}
                onChange={(e) => handleChange("history_of_trauma", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="History of trauma"
                value={opportunity.history_of_trauma || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Registered sex offender"
                value={opportunity.registered_sex_offender || ""}
                onChange={(e) => handleChange("registered_sex_offender", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Registered sex offender"
                value={opportunity.registered_sex_offender || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Sexually inappropriate behavior"
                value={opportunity.sexually_inappropriate_behavior || ""}
                onChange={(e) => handleChange("sexually_inappropriate_behavior", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Sexually inappropriate behavior"
                value={opportunity.sexually_inappropriate_behavior || "N/A"}
              />
            )}
          </div>
          <div className="div-10">
            {isEditable ? (
              <Input
                className="input-6"
                label="Concerns for withdrawal"
                value={opportunity.concerns_for_withdrawal || ""}
                onChange={(e) => handleChange("concerns_for_withdrawal", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Concerns for withdrawal"
                value={opportunity.concerns_for_withdrawal || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Parole granted"
                value={opportunity.parole_granted || ""}
                onChange={(e) => handleChange("parole_granted", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Parole granted"
                value={opportunity.parole_granted || "N/A"}
              />
            )}
          </div>
          <div className="div-10">
            {isEditable ? (
              <Input
                className="input-6"
                label="Please list history of TBI/concussions, cardiac and/or chronic pain management"
                value={opportunity.history_of_tbi_concussions || ""}
                onChange={(e) => handleChange("history_of_tbi_concussions", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Please list history of TBI/concussions, cardiac and/or chronic pain management"
                value={opportunity.history_of_tbi_concussions || "N/A"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
