import React, { useEffect, useState } from "react";
import { CapacityFacilityRow } from "../../components/CapacityFacilityRow";
import { FacilityTable } from "../../components/FacilityTable";
import "./style.css";
import { getCapacityData } from "../../services/censusApi";

export const CapacityFacility = () => {
  const [facilities, setFacility] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadfacilityData = async () => {
      try {
        const data = await getCapacityData();
        setFacility(data.capacity_facility);
      } catch (err) {
        setError("Error fetching facility data.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    loadfacilityData();
  }, []);

  return (
      <div className="clinician-list">
        <FacilityTable
          borderClassName="design-component-instance-node-4"
          className="design-component-instance-node-3"
          columnsClassName="design-component-instance-node-4"
        />
        {facilities.map((facility, index) => (
          <CapacityFacilityRow
            key={index}
            borderClassName="design-component-instance-node-4"
            cellsClassName="design-component-instance-node-4"
            className="design-component-instance-node-3"
            name={facility.name}
            type={facility.type}
            maxCapacity={facility.size}
            opportunities={facility.opportunities}
          />
        ))}
      </div>
  );
};
