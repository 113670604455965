import { axiosInstance, setAuthHeaders, handleResponse, handleError } from './apiUtils';
import { useNavigate } from 'react-router-dom';

export const getPatients = async () => {
    setAuthHeaders();
    const response = await axiosInstance.get('/api/patients');
    return handleResponse(response);
};

export const getPatientById = async (patientId) => {
  try {
    setAuthHeaders();
    var payload = {
        "patient_id": patientId
      }

    const response = await axiosInstance.get('/api/patient_details', { params: payload });
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const searchPatients = async (query) => {
    setAuthHeaders();

    var payload = {
        "query": query
      }

    const response = await axiosInstance.get('/api/patients', { params: payload });
    return handleResponse(response);
};

export const getOpportunitiesByPatientId = async (patientId) => {
  try {
    setAuthHeaders();
    var payload = {
        "patient_id": patientId
      }

    const response = await axiosInstance.get('/api/patient_opportunities', { params: payload });
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const savePatient = async (patient) => {
  setAuthHeaders();
  const response = await axiosInstance.post('/api/patients', patient);
  return handleResponse(response);
};

export const updatePatient = async (patientId, updatedData) => {
  setAuthHeaders();
    var payload = {
        "patient_id": patientId,
        "patient": updatedData
      }
  const response = await axiosInstance.put('/api/patient_details', payload);
  return response.data;
};