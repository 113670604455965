import PropTypes from "prop-types";
import React from "react";
import { VuesaxOutlineClipboardTick3 } from "../../icons/VuesaxOutlineClipboardTick3";
import "./style.css";

export const ProcessStep = ({ property1, className, text = "Basic requirements", onClick }) => {
  return (
    <div className={`process-step ${property1} ${className}`} onClick={onClick}>
      <VuesaxOutlineClipboardTick3 className="vuesax-outline" color={property1 === "clicked" ? "#526477" : "#7E92A2"} />
      <div className="basic-requirements">{text}</div>
    </div>
  );
};

ProcessStep.propTypes = {
  property1: PropTypes.oneOf(["clicked", "default"]),
  text: PropTypes.string,
  onClick: PropTypes.func,
};
