import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { VuesaxOutlineArrowDown } from "../../icons/VuesaxOutlineArrowDown";
import "./style.css";

export const Dropdown = ({ className, label, placeholder, options = [], onSelect, multiselect = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Initialize selected options from placeholder if it's a comma-separated string
    if (multiselect && placeholder) {
      const initialSelections = placeholder.split(", ").map(option => option.trim());
      setSelectedOptions(initialSelections);
    } else if (!multiselect && placeholder) {
      setSelectedOptions([placeholder.trim()]);
    }
  }, [placeholder, multiselect]);

  const handleToggle = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    let updatedSelections;

    if (multiselect) {
      // For multiselect, toggle the option in the selection
      if (selectedOptions.includes(option)) {
        updatedSelections = selectedOptions.filter(item => item !== option);
      } else {
        updatedSelections = [...selectedOptions, option];
      }
    } else {
      // For single select, replace the selection with the chosen option
      updatedSelections = [option];
    }

    setSelectedOptions(updatedSelections);

    // Convert array to comma-separated string
    const selectionsString = updatedSelections.join(", ");

    // Pass the comma-separated string to parent
    if (onSelect) {
      onSelect(selectionsString);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`dropdown ${className}`} ref={dropdownRef}>
      {label && <div className="label">{label}</div>}
      <div className="input-and-options" onClick={handleToggle}>
        <div className="div-3">
          <div className="placeholder">
            {selectedOptions.length > 0
              ? selectedOptions.join(", ")
              : placeholder}
          </div>
          <VuesaxOutlineArrowDown className="icon-dropdown" color="#7E92A2" />
        </div>
        {isOpen && (
          <div className="options">
            {options.map((option, index) => (
              <div
                key={index}
                className={`option-item ${selectedOptions.includes(option) ? 'selected' : ''}`}
                onClick={() => handleSelect(option)}
              >
                {selectedOptions.includes(option) && <span>✔ </span>}
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
  multiselect: PropTypes.bool
};
