import PropTypes from "prop-types";
import React from "react";
import { VuesaxOutlineStop2 } from "../../icons/VuesaxOutlineStop2";
import "./style.css";

export const Checkbox = ({ property1, className, onClick }) => {
  return (
    <div className={`checkbox ${property1} ${className}`} onClick={onClick}>
      {property1 === "ticked-box" && (
        <>
          <VuesaxOutlineStop2 className="vuesax-outline-stop" color="#7E92A2" />
          <img
            className="vector"
            alt="Vector"
            src="/img/vector.svg"
          />
        </>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  property1: PropTypes.oneOf(["empty-box", "ticked-box"]),
  onClick: PropTypes.func.isRequired,
};
