/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const VuesaxBoldArrowRight = ({ className }) => {
  return (
    <svg
      className={`vuesax-bold-arrow-right ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M15.2001 10.4899L13.2301 8.51993L10.0201 5.30993C9.34005 4.63993 8.18005 5.11993 8.18005 6.07993V12.3099V17.9199C8.18005 18.8799 9.34005 19.3599 10.0201 18.6799L15.2001 13.4999C16.0301 12.6799 16.0301 11.3199 15.2001 10.4899Z"
        fill="#292D32"
      />
    </svg>
  );
};
