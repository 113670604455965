import PropTypes from "prop-types";
import React from "react";

export const Close = ({ color = "#7E92A2", className, onClick }) => {
  return (
    <svg
      className={`close ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{ cursor: "pointer" }} // Optional: Cursor as pointer
    >
      <path
        className="path"
        d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
        fill={color}
      />
    </svg>
  );
};

Close.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
