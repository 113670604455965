import React from "react";

export const Icon12 = ({ className }) => {
  return (
    <svg
      className={`icon-12 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" opacity="0.38">
        <path
          className="path"
          d="M4.26665 12.6668L3.33331 11.7334L7.06665 8.0001L3.33331 4.26677L4.26665 3.33344L7.99998 7.06677L11.7333 3.33344L12.6666 4.26677L8.93331 8.0001L12.6666 11.7334L11.7333 12.6668L7.99998 8.93344L4.26665 12.6668Z"
          fill="#E6E0E9"
        />
      </g>
    </svg>
  );
};
