import React from "react";
import { useOutletContext } from "react-router-dom";
import { Input } from "../../components/Input";
import { DetailDataCell } from "../../components/DetailDataCell";
import "./style.css";

export const PatientDemographics = () => {
  const { patient, setPatient, isEditable } = useOutletContext();

  const handleChange = (field, value) => {
    setPatient((prevPatient) => ({ ...prevPatient, [field]: value }));
  };

  return (
  <div className="scrollable-content-demographics">
    <div className="form-for-2">
      <div className="div-7">
        {isEditable ? (
          <Input
            className="input-6"
            label="First Name"
            value={patient.firstname}
            onChange={(e) => handleChange("firstname", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="First Name"
            value={patient.firstname}
          />
        )}
        {isEditable ? (
          <Input
            className="input-6"
            label="Last Name"
            value={patient.lastname}
            onChange={(e) => handleChange("lastname", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Last Name"
            value={patient.lastname}
          />
        )}
      </div>
      <div className="div-7">
        {isEditable ? (
          <Input
            className="input-6"
            label="Email"
            value={patient.email_id}
            onChange={(e) => handleChange("email_id", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Email"
            value={patient.email_id}
          />
        )}
        {isEditable ? (
          <Input
            className="input-6"
            label="Phone"
            value={patient.phone_number || ""}
            onChange={(e) => handleChange("phone_number", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Phone"
            value={patient.phone_number || "N/A"}
          />
        )}
      </div>
      <div className="div-7">
        {isEditable ? (
          <Input
            className="input-6"
            label="Date Of Birth"
            value={patient.dob || ""}
            onChange={(e) => handleChange("dob", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Date Of Birth"
            value={patient.dob || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="input-6"
            label="Social Security Number"
            value={patient.ssn || ""}
            onChange={(e) => handleChange("ssn", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Social Security Number"
            value={patient.ssn || "N/A"}
          />
        )}
      </div>
      <div className="div-7">
        {isEditable ? (
          <Input
            className="input-6"
            label="Gender"
            value={patient.gender || ""}
            onChange={(e) => handleChange("gender", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Gender"
            value={patient.gender || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="input-6"
            label="Sex at birth"
            value={patient.sex_at_birth || ""}
            onChange={(e) => handleChange("sex_at_birth", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Sex at birth"
            value={patient.sex_at_birth || "N/A"}
          />
        )}
      </div>
      <div className="div-7">
      {isEditable ? (
        <Input
          className="design-component-instance-node-6"
          label="Street address"
          value={patient.address || ""}
          onChange={(e) => handleChange("address", e.target.value)}
        />
      ) : (
        <DetailDataCell
          className="detail-data-cell-instance"
          label="Street address"
          value={patient.address || "N/A"}
        />
      )}
      </div>
      <div className="div-7">
        {isEditable ? (
          <Input
            className="input-6"
            label="City"
            value={patient.city || ""}
            onChange={(e) => handleChange("city", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="City"
            value={patient.city || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="state-2"
            divClassName="input-7"
            label="State"
            value={patient.state || ""}
            onChange={(e) => handleChange("state", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="State"
            value={patient.state || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="zip-code-2"
            divClassName="input-7"
            label="Zip"
            value={patient.zip || ""}
            onChange={(e) => handleChange("zip", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Zip"
            value={patient.zip || "N/A"}
          />
        )}
      </div>
      <div className="div-7">
        {isEditable ? (
          <Input
            className="input-6"
            label="Marital status"
            value={patient.marital_status || ""}
            onChange={(e) => handleChange("marital_status", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Marital status"
            value={patient.marital_status || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="input-6"
            label="Living status"
            value={patient.living_status || ""}
            onChange={(e) => handleChange("living_status", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Living status"
            value={patient.living_status || "N/A"}
          />
        )}
      </div>
      <div className="div-7">
        {isEditable ? (
          <Input
            className="input-6"
            label="Employment status"
            value={patient.employment_status || ""}
            onChange={(e) => handleChange("employment_status", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Employment status"
            value={patient.employment_status || "N/A"}
          />
        )}
        {isEditable ? (
          <Input
            className="input-6"
            label="Employer"
            value={patient.employer || ""}
            onChange={(e) => handleChange("employer", e.target.value)}
          />
        ) : (
          <DetailDataCell
            className="detail-data-cell-instance"
            label="Employer"
            value={patient.employer || "N/A"}
          />
        )}
      </div>
    </div>
    </div>
  );
};
