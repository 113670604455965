import React, { useEffect, useState } from "react";
import { Base } from "../../components/Base";
import { CapacityTabs } from "../../components/CapacityTabs";
import { CensusDataRow } from "../../components/CensusDataRow";
import { TopNavigationBar } from "../../components/TopNavigationBar";
import { SidebarNavigation } from "../../components/SidebarNavigation";
import { ListOf } from "../../components/ListOf";
import { VuesaxBoldArrowLeft } from "../../icons/VuesaxBoldArrowLeft";
import { VuesaxBoldArrowRight } from "../../icons/VuesaxBoldArrowRight";
import { getCensusData, getDetailedCensusData } from "../../services/censusApi";
import "./style.css";

export const CapacityCensus = () => {
  const [censusData, setCensusData] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [detailedData, setDetailedData] = useState(null);

  const fetchCensusData = async (date) => {
    try {
      const data = await getCensusData(date);
      setCensusData(data.census);
    } catch (error) {
      console.error("Error fetching census data:", error);
    }
  };

  const fetchDetailedCensusData = async (date, metric) => {
    try {
      const data = await getDetailedCensusData(date, metric);
      data['metric'] = metric;
      data['date'] = formatDate(date, 0);
      setDetailedData(data);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching detailed census data:", error);
    }
  };

  useEffect(() => {
    fetchCensusData(currentDate);
  }, [currentDate]);

  const handleLeftArrowClick = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 7);
    setCurrentDate(newDate);
  };

  const handleRightArrowClick = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 7);
    setCurrentDate(newDate);
  };

  const handleNumberClick = (dateIndex, metric) => {
    const clickedDate = new Date(currentDate);
    clickedDate.setDate(currentDate.getDate() + dateIndex);

    setSelectedMetric(metric);
    setSelectedDate(clickedDate);
    fetchDetailedCensusData(clickedDate, metric);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setDetailedData(null);
  };

  const formatDate = (date, daysToAdd) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + daysToAdd);
    return newDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', weekday: 'short' });
  };

  return (
  <div className="scrollable-content">
    <div className="deal-list">
      <div className="table-header">
        <div className="columns">
          <div className="address-column">
            <div className="text-wrapper-12">Metric</div>
          </div>
          <div className="vuesax-bold-arrow-wrapper" onClick={handleLeftArrowClick}>
            <VuesaxBoldArrowLeft className="vuesax-bold-arrow" color="#D6E1E6" />
          </div>
          {[...Array(7)].map((_, index) => (
            <div className="area-column" key={index}>
              <div className="text-wrapper-12">{formatDate(currentDate, index)}</div>
            </div>
          ))}
          <div className="vuesax-bold-arrow-wrapper" onClick={handleRightArrowClick}>
            <VuesaxBoldArrowRight className="vuesax-bold-arrow" color="#7E92A2" />
          </div>
        </div>
        <div className="border-2" />
      </div>
      {censusData.map((row, rowIndex) => (
        <CensusDataRow
          key={rowIndex}
          className="deal-row-repeat-link"
          imageClassName="census-data-row-instance"
          days={[row.day1, row.day2, row.day3, row.day4, row.day5, row.day6, row.day7]}
          metric={row.metric}
          onNumberClick={handleNumberClick}
        />
      ))}
    </div>
      {isModalOpen && (
        <div className="modal-overlay">
          <ListOf
            onClose={closeModal}
            data={detailedData}
            facilityCounts={detailedData?.facility_counts || {}}
          />
        </div>
      )}
    </div>
  );
};
