/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icon10 = ({ className }) => {
  return (
    <svg
      className={`icon-10 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7 18.6018C6.76667 18.6018 6.52499 18.5435 6.30832 18.4268C5.83332 18.1768 5.54167 17.6768 5.54167 17.1434V15.9602C3.025 15.7018 1.375 13.8518 1.375 11.2018V6.20182C1.375 3.33516 3.3 1.41016 6.16667 1.41016H14.5C17.3667 1.41016 19.2917 3.33516 19.2917 6.20182V11.2018C19.2917 14.0684 17.3667 15.9934 14.5 15.9934H11.3583L7.80831 18.3602C7.56664 18.5185 7.28333 18.6018 7 18.6018ZM6.16667 2.65181C4.01667 2.65181 2.625 4.04348 2.625 6.19348V11.1935C2.625 13.3435 4.01667 14.7352 6.16667 14.7352C6.50833 14.7352 6.79167 15.0185 6.79167 15.3602V17.1352C6.79167 17.2435 6.85834 17.2935 6.9 17.3185C6.94167 17.3435 7.02501 17.3685 7.11668 17.3102L10.825 14.8435C10.925 14.7769 11.05 14.7352 11.175 14.7352H14.5083C16.6583 14.7352 18.05 13.3435 18.05 11.1935V6.19348C18.05 4.04348 16.6583 2.65181 14.5083 2.65181H6.16667Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M10.3335 10.0898C9.99184 10.0898 9.70851 9.80651 9.70851 9.46484V9.28988C9.70851 8.32321 10.4168 7.8482 10.6835 7.66487C10.9918 7.45653 11.0918 7.31487 11.0918 7.09821C11.0918 6.68154 10.7502 6.33984 10.3335 6.33984C9.91684 6.33984 9.5752 6.68154 9.5752 7.09821C9.5752 7.43987 9.29186 7.72321 8.9502 7.72321C8.60853 7.72321 8.3252 7.43987 8.3252 7.09821C8.3252 5.98987 9.22517 5.08984 10.3335 5.08984C11.4418 5.08984 12.3418 5.98987 12.3418 7.09821C12.3418 8.04821 11.6418 8.52319 11.3835 8.69819C11.0585 8.91486 10.9585 9.05654 10.9585 9.28988V9.46484C10.9585 9.81484 10.6752 10.0898 10.3335 10.0898Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M10.3335 12.168C9.9835 12.168 9.7085 11.8846 9.7085 11.543C9.7085 11.2013 9.99183 10.918 10.3335 10.918C10.6752 10.918 10.9585 11.2013 10.9585 11.543C10.9585 11.8846 10.6835 12.168 10.3335 12.168Z"
        fill="#7E92A2"
      />
    </svg>
  );
};
