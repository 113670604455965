import React, { useState, useEffect } from "react";
import { Button } from "../../components/Button";
import { DetailPageHeader } from "../../components/DetailPageHeader";
import { useParams } from "react-router-dom";
import { SidebarNavigation } from "../../components/SidebarNavigation";
import { TopNavigationBar } from "../../components/TopNavigationBar";
import { SearchDropdown } from "../../components/SearchDropdown";
import { Input } from "../../components/Input";
import { DetailDataCell } from "../../components/DetailDataCell";
import { NotificationPopUp } from "../../components/NotificationPopUp";
import { getContactById, updateContact, searchReferrals } from "../../services/referralApi";
import "./style.css";

export const ContactDetail = () => {
  const { contactId } = useParams();
  const [contact, setContact] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [buttonText, setButtonText] = useState("Edit Details");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContact = async () => {
      try {
        const data = await getContactById(contactId);
        setContact(data.contact);
      } catch (error) {
        setNotificationStatus("fail");
        setNotificationMessage("Error fetching contact details");
        setShowNotification(true);
      } finally {
        setLoading(false);
      }
    };
    fetchContact();
  }, [contactId]);

  const handleEditClick = () => {
    if (isEditable) {
      handleSave();
    } else {
      setIsEditable(true);
      setButtonText("Save Details");
    }
  };

  const handleSave = async () => {
    try {
      await updateContact(contactId, contact);
      setIsEditable(false);
      setButtonText("Edit Details");
      setNotificationStatus("success");
      setNotificationMessage("Successfully saved contact details!");
    } catch (error) {
      setNotificationStatus("fail");
      setNotificationMessage("Error saving contact details");
    } finally {
      setShowNotification(true);
    }
  };

  const handleChange = (field, value) => {
    setContact((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleOrganizationSearch = async (searchTerm) => {
    try {
      const data = await searchReferrals(searchTerm);
      return data.referral.map(referral => `${referral.referral_name}`);
    } catch (error) {
      console.error("Error fetching organizations:", error);
      return [];
    }
  };

  if (loading) return <div className="spinner"></div>;

  return (
    <div className="contact-detail">
      <TopNavigationBar
        className="top-navigation-bar-v3"
        text="Contact Detail"
        baseBaseClassName="top-navigation-bar-instance"
      />
      <div className="horizontal-body-side-2">
        <SidebarNavigation className="sidebar-navigation-v3" />
        <div className="body-deal-detail-2">
          <div className="left-area-2">
            <DetailPageHeader
              name={contact?.firstname || "N/A"}
              buttonTypeIconOnlyStyleClassName="detail-page-header-2"
              className="detail-page-header-instance"
              onEditClick={handleEditClick}
              buttonText={buttonText}
            />
            <div className="scrollable-content-contact-detail">
              <div className="content-4">
                <div className="left-column-4">
                  <div className="div-10">
                    {isEditable ? (
                      <SearchDropdown
                        className="input-6"
                        label="Organization"
                        placeholder={contact?.organization_name ? contact.organization_name : "Select organization"}
                        onSearch={handleOrganizationSearch}
                        onSelect={(selectedOption) => handleChange("organization_name", selectedOption)}
                        value={contact.organization_name || ""}
                      />
                    ) : (
                      <DetailDataCell
                        className="detail-data-cell-v-instance"
                        label="Organization"
                        value={contact?.organization_name || "N/A"}
                      />
                    )}
                    {isEditable ? (
                      <Input
                        className="input-6"
                        label="Role"
                        value={contact?.title || ""}
                        onChange={(e) =>
                          handleChange("title", e.target.value)
                        }
                      />
                    ) : (
                      <DetailDataCell
                        className="detail-data-cell-v-instance"
                        label="Role"
                        value={contact?.title || "N/A"}
                      />
                    )}
                  </div>
                  <div className="div-10">
                    {isEditable ? (
                      <Input
                        className="input-6"
                        label="Firstname"
                        value={contact?.firstname || ""}
                        onChange={(e) =>
                          handleChange("firstname", e.target.value)
                        }
                      />
                    ) : (
                      <DetailDataCell
                        className="detail-data-cell-v-instance"
                        label="Firstname"
                        value={contact?.firstname || "N/A"}
                      />
                    )}
                    {isEditable ? (
                      <Input
                        className="input-6"
                        label="Lastname"
                        value={contact?.lastname || ""}
                        onChange={(e) =>
                          handleChange("lastname", e.target.value)
                        }
                      />
                    ) : (
                      <DetailDataCell
                        className="detail-data-cell-v-instance"
                        label="Lastname"
                        value={contact?.lastname || "N/A"}
                      />
                    )}
                  </div>
                  <div className="div-10">
                    {isEditable ? (
                      <Input
                        className="input-6"
                        label="Email"
                        value={contact?.email || ""}
                        onChange={(e) =>
                          handleChange("email", e.target.value)
                        }
                      />
                    ) : (
                      <DetailDataCell
                        className="detail-data-cell-v-instance"
                        label="Email"
                        value={contact?.email || "N/A"}
                      />
                    )}
                    {isEditable ? (
                      <Input
                        className="input-6"
                        label="Phone Number"
                        value={contact?.phone_number || ""}
                        onChange={(e) =>
                          handleChange("phone_number", e.target.value)
                        }
                      />
                    ) : (
                      <DetailDataCell
                        className="detail-data-cell-v-instance"
                        label="Phone Number"
                        value={contact?.phone_number || "N/A"}
                      />
                    )}
                  </div>
                  <div className="div-10">
                    {isEditable ? (
                      <Input
                        className="input-6"
                        label="Addition Information"
                        value={contact?.additional_info || ""}
                        onChange={(e) =>
                          handleChange("additional_info", e.target.value)
                        }
                      />
                    ) : (
                      <DetailDataCell
                        className="detail-data-cell-v-instance"
                        label="Addition Information"
                        value={contact?.additional_info || "N/A"}
                      />
                    )}
                  </div>
                  <div className="div-10">
                    {isEditable ? (
                      <Input
                        className="input-6"
                        label="Special Notes"
                        value={contact?.special_notes || ""}
                        onChange={(e) =>
                          handleChange("special_notes", e.target.value)
                        }
                      />
                    ) : (
                      <DetailDataCell
                        className="detail-data-cell-v-instance"
                        label="Special Notes"
                        value={contact?.special_notes || "N/A"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Notification Popup */}
      {showNotification && (
        <NotificationPopUp
          message={notificationMessage}
          status={notificationStatus}
          onClose={() => setShowNotification(false)}
        />
      )}
    </div>
  );
};
