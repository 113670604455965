/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const VuesaxOutlineNoteText = ({ color = "#292D32", className }) => {
  return (
    <svg
      className={`vuesax-outline-note-text ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7.16669 4.79163C6.82502 4.79163 6.54169 4.50829 6.54169 4.16663V1.66663C6.54169 1.32496 6.82502 1.04163 7.16669 1.04163C7.50835 1.04163 7.79169 1.32496 7.79169 1.66663V4.16663C7.79169 4.50829 7.50835 4.79163 7.16669 4.79163Z"
        fill={color}
      />
      <path
        className="path"
        d="M13.8333 4.79163C13.4916 4.79163 13.2083 4.50829 13.2083 4.16663V1.66663C13.2083 1.32496 13.4916 1.04163 13.8333 1.04163C14.175 1.04163 14.4583 1.32496 14.4583 1.66663V4.16663C14.4583 4.50829 14.175 4.79163 13.8333 4.79163Z"
        fill={color}
      />
      <path
        className="path"
        d="M13.8333 18.9583H7.16667C4.125 18.9583 2.375 17.2083 2.375 14.1666V7.08329C2.375 4.04163 4.125 2.29163 7.16667 2.29163H13.8333C16.875 2.29163 18.625 4.04163 18.625 7.08329V14.1666C18.625 17.2083 16.875 18.9583 13.8333 18.9583ZM7.16667 3.54163C4.78333 3.54163 3.625 4.69996 3.625 7.08329V14.1666C3.625 16.55 4.78333 17.7083 7.16667 17.7083H13.8333C16.2167 17.7083 17.375 16.55 17.375 14.1666V7.08329C17.375 4.69996 16.2167 3.54163 13.8333 3.54163H7.16667Z"
        fill={color}
      />
      <path
        className="path"
        d="M13.8334 9.79163H7.16669C6.82502 9.79163 6.54169 9.50829 6.54169 9.16663C6.54169 8.82496 6.82502 8.54163 7.16669 8.54163H13.8334C14.175 8.54163 14.4584 8.82496 14.4584 9.16663C14.4584 9.50829 14.175 9.79163 13.8334 9.79163Z"
        fill={color}
      />
      <path
        className="path"
        d="M10.5 13.9584H7.16669C6.82502 13.9584 6.54169 13.675 6.54169 13.3334C6.54169 12.9917 6.82502 12.7084 7.16669 12.7084H10.5C10.8417 12.7084 11.125 12.9917 11.125 13.3334C11.125 13.675 10.8417 13.9584 10.5 13.9584Z"
        fill={color}
      />
    </svg>
  );
};

VuesaxOutlineNoteText.propTypes = {
  color: PropTypes.string,
};
