import { axiosInstance, setAuthHeaders, handleResponse, handleError } from './apiUtils';
import { useNavigate } from 'react-router-dom';

export const getReferrals = async () => {
    setAuthHeaders();
    const response = await axiosInstance.get('/api/referrals');
    return handleResponse(response);
};

export const searchReferrals = async (query) => {
    setAuthHeaders();
    var payload = {"query": query}
    const response = await axiosInstance.get('/api/referrals', { params: payload });
    return handleResponse(response);
};

export const getContacts = async () => {
    setAuthHeaders();
    var payload = {"page": 1, "per_page": 10000}
    const response = await axiosInstance.get('/api/referral_contacts', { params: payload });
    return handleResponse(response);
};

export const getContactsViewAll = async (query) => {
    setAuthHeaders();
    var payload = {"query": query}
    const response = await axiosInstance.get('/api/referral_contact_view_all', { params: payload });
    return handleResponse(response);
};

export const searchContacts = async (query) => {
    setAuthHeaders();
    var payload = {"query": query}
    const response = await axiosInstance.get('/api/referral_contacts', { params: payload });
    return handleResponse(response);
};

export const getContactById = async (contact_id) => {
  try {
    setAuthHeaders();
    var payload = {
        "contact_id": contact_id
      }

    const response = await axiosInstance.get('/api/contact_details', { params: payload });
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const updateContact = async (contactId, contact) => {
  setAuthHeaders();

   var payload = {
        "contact_id": contactId,
        "contact": contact
      }

  const response = await axiosInstance.put('/api/contact_details', payload);
  return handleResponse(response);
};

export const saveReferral = async (referral) => {
  setAuthHeaders();
  const response = await axiosInstance.post('/api/referrals', referral);
  return handleResponse(response);
};

export const getReferralById = async (referral_id) => {
  try {
    setAuthHeaders();
    var payload = {
        "referral_id": referral_id
      }

    const response = await axiosInstance.get('/api/referral_details', { params: payload });
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const getReferralAnalytics = async (referral_id) => {
  try {
    setAuthHeaders();
    var payload = {
        "referral_id": referral_id
      }

    const response = await axiosInstance.get('/api/referral_analytics', { params: payload });
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const getReferralAnalyticsDetails = async (referral_id, metric) => {
  try {
    setAuthHeaders();
    var payload = {
        "referral_id": referral_id,
        "metric": metric
      }

    const response = await axiosInstance.get('/api/referral_analytics_details', { params: payload });
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const updateReferral = async (referralId, referral) => {
  setAuthHeaders();

   var payload = {
        "referral_id": referralId,
        "referral": referral
      }

  const response = await axiosInstance.put('/api/referral_details', payload);
  return handleResponse(response);
};

export const getActivityById = async (referral_id) => {
  try {
    setAuthHeaders();
    var payload = {
        "referral_id": referral_id
      }

    const response = await axiosInstance.get('/api/referral_activity', { params: payload });
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const saveActivityById = async (referral_id, payload) => {
  setAuthHeaders();
  payload["referral_id"] = referral_id;
  const response = await axiosInstance.post('/api/referral_activity', payload);
  return handleResponse(response);
};
