import React, { useEffect, useState } from "react";
import { TopNavigationBar } from "../../components/TopNavigationBar";
import { SidebarNavigation } from "../../components/SidebarNavigation";
import { VuesaxBoldProfile2User2 } from "../../icons/VuesaxBoldProfile2User2";
import { VuesaxBoldBriefcase3 } from "../../icons/VuesaxBoldBriefcase3";
import { Icon16 } from "../../icons/Icon16";
import { Counter } from "./Counter";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import "./style.css";
import { getAnalytics } from "../../services/analyticsApi";

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const options = {
  indexAxis: 'y', // This makes the bars horizontal
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 1,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 2, // Adjust this to space out the grid lines
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'left',
      labels: {
        usePointStyle: true,
        padding: 20,
        boxWidth: 15,
      },
    },
    tooltip: {
      enabled: true,
    },
  },
};

export const Analytics = () => {
  const [referralOrgsCount, setReferralOrgsCount] = useState(null);
  const [distinctOpportunityCount, setDistinctOpportunityCount] = useState(null);
  const [distinctReferralOutCount, setDistinctReferralOutCount] = useState(null);
  const [referringOrgsDetails, setReferringOrgsDetails] = useState([]);
  const [referringOutOrgsDetails, setReferringOutOrgsDetails] = useState([]);
  const [referringInMarketing, setReferringInMarketing] = useState([]);
  const [droppedReasons, setDroppedReasons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAnalytics();
        if (data) {
          setReferralOrgsCount(data.referral_orgs_count);
          setDistinctOpportunityCount(data.distinct_opportunity_count);
          setDistinctReferralOutCount(data.distinct_referral_out_count);
          setReferringOrgsDetails(data.referring_orgs_details);
          setReferringOutOrgsDetails(data.referring_out_orgs_details);
          setReferringInMarketing(data.marketing_details);
          setDroppedReasons(data.drop_reasons);
        } else {
          setError("No analytics data!");
        }
      } catch (error) {
        console.error("There was an error loading the data!", error);
        setError("There was an error loading the data!");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const data1 = {
    labels: referringOrgsDetails.map(item => item.referral_org),
    datasets: [
      {
        label: 'Received',
        data: referringOrgsDetails.map(item => item.total_opportunity_count),
        backgroundColor: '#ffc357',
        barThickness: 15,
      },
      {
        label: 'Admitted',
        data: referringOrgsDetails.map(item => item.opportunity_count_with_admit_date),
        backgroundColor: '#2dc8a8',
        barThickness: 15,
      },
    ],
  };

  const data2 = {
    labels: referringOutOrgsDetails.map(item => item.referred_out_org || 'Unknown'),
    datasets: [
      {
        label: 'Sent',
        data: referringOutOrgsDetails.map(item => item.total_opportunity_count),
        backgroundColor: '#008080',
        barThickness: 15,
      }
    ],
  };

  const data3 = {
    labels: referringInMarketing.map(item => item.marketing_contact),
    datasets: [
      {
        label: 'Received',
        data: referringInMarketing.map(item => item.total_opportunity_count),
        backgroundColor: '#ffc357',
        barThickness: 15,
      },
      {
        label: 'Admitted',
        data: referringInMarketing.map(item => item.admit_date_opportunity_count),
        backgroundColor: '#2dc8a8',
        barThickness: 15,
      },
    ],
  };

  const data4 = {
    labels: droppedReasons.map(item => item.drop_reason || 'Unknown'),
    datasets: [
      {
        label: 'Dropped',
        data: droppedReasons.map(item => item.total_opportunity_count),
        backgroundColor: '#ff2c2c',
        barThickness: 15,
      }
    ],
  };

  return (
    <div className="analytics-screen">
      <TopNavigationBar
        className="design-component-instance-node-2"
        text="Analytics"
        baseBaseClassName="top-navigation-bar-instance"
      />
      <div className="horizontal-body-side-2">
        <SidebarNavigation className="sidebar-navigation-v3" />
        <div className="body-view">
        <div className="scrollable-content-analytics">
        <div className="body-all-graphs">
          <div className="counters-row">
            <Counter title="Referring orgs" value={referralOrgsCount} Icon={VuesaxBoldProfile2User2} iconBackground="linear-gradient(180deg, rgba(45, 200, 168, 0.5) 0%, rgba(255, 255, 255, 0) 100%)" />
            <Counter title="Incoming cases admitted" value={distinctOpportunityCount} Icon={VuesaxBoldBriefcase3} iconBackground="linear-gradient(180deg, rgba(254, 128, 132, 0.5) 0%, rgba(255, 255, 255, 0) 100%)" />
            <Counter title="Referrals out" value={distinctReferralOutCount} Icon={VuesaxBoldProfile2User2} iconBackground="linear-gradient(180deg, rgba(45, 200, 168, 0.5) 0%, rgba(255, 255, 255, 0) 100%)" />
          </div>

          <div className="graphs-row">
            <div className="graph-wrapper">
              <div className="counter-content">
                <div className="graph-title">Referrals in by organizations</div>
                <div className="graph-value">
                  <Bar data={data1} options={options} />
                </div>
              </div>
            </div>

            <div className="graph-wrapper">
              <div className="counter-content">
                <div className="graph-title">Referrals out by organizations</div>
                <div className="graph-value">
                  <Bar data={data2} options={options} />
                </div>
              </div>
            </div>
           </div>
            <div className="graphs-row">
            <div className="graph-wrapper">
              <div className="counter-content">
                <div className="graph-title">Referrals in by marketing</div>
                <div className="graph-value">
                  <Bar data={data3} options={options} />
                </div>
              </div>
            </div>
            <div className="graph-wrapper">
              <div className="counter-content">
                <div className="graph-title">Drop opportunities reasons</div>
                <div className="graph-value">
                  <Bar data={data4} options={options} />
                </div>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
   </div>
   </div>
  );
};
