import React, { useEffect, useState } from "react";
import { CapacityClinicianRow } from "../../components/CapacityClinicianRow";
import { ClinicianTable } from "../../components/ClinicianTable";
import "./style.css";
import { getCapacityData } from "../../services/censusApi";

export const CapacityClinician = () => {
  const [clinicians, setClinicians] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadClinicians = async () => {
      try {
        const data = await getCapacityData();
        setClinicians(data.capacity_clinician);
      } catch (err) {
        setError("Error fetching clinician data.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    loadClinicians();
  }, []);

  return (
      <div className="clinician-list">
        <ClinicianTable
          borderClassName="design-component-instance-node-4"
          className="design-component-instance-node-3"
          columnsClassName="design-component-instance-node-4"
        />
        {clinicians.map((clinician, index) => (
          <CapacityClinicianRow
            key={index}
            borderClassName="design-component-instance-node-4"
            cellsClassName="design-component-instance-node-4"
            className="design-component-instance-node-3"
            name={`${clinician.firstname} ${clinician.lastname}`}
            type={clinician.type}
            maxCaseload={clinician.max_caseload}
            opportunities={clinician.opportunities}
          />
        ))}
      </div>
  );
};
