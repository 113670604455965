/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Checkbox } from "../Checkbox";
import "./style.css";

export const TasksHeader = ({ className, text = "Assignee" }) => {
  return (
    <div className={`tasks-header ${className}`}>
      <div className="columns">
        <div className="organization">Title</div>

        <div className="text-wrapper-3">Org Associated</div>

        <div className="text-wrapper-3">Contact Associated</div>

        <div className="assignee">Assigned To</div>

        <div className="assignee">Due Date</div>

        <div className="last-activity-date">Status</div>
      </div>

      <div className="border" />
    </div>
  );
};

TasksHeader.propTypes = {
  text: PropTypes.string,
};
