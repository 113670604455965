import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { Input } from "../../components/Input";
import { Dropdown } from "../../components/Dropdown";
import { ListOfContacts } from "../../components/ListOfContacts";
import { DetailDataCell } from "../../components/DetailDataCell";
import { getContactsViewAll } from "../../services/referralApi";
import "./style.css";

export const ReferralInfo = () => {
  const { referral, setReferral, isEditable } = useOutletContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contactData, setContactData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = (field, value) => {
    setReferral((prevReferral) => ({ ...prevReferral, [field]: value }));
  };

  const fetchContactData = async () => {
    setLoading(true);
    try {
      const data = await getContactsViewAll(referral.referral_name);
      setContactData(data.contacts);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewAllClick = () => {
    fetchContactData();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="scrollable-content-history">
      <div className="content-4">
        <div className="left-column-4">
          <div className="div-10">
            {isEditable ? (
              <Input
                className="input-6"
                label="Name"
                value={referral.referral_name || ""}
                onChange={(e) => handleChange("referral_name", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Name"
                value={referral.referral_name || "N/A"}
              />
            )}
            {isEditable ? (
              <Dropdown
                className="design-component-instance-node"
                label="Type"
                placeholder={referral.type}
                options={["Treatment center", "Hospital", "Interventionist", "Social worker", "Education consultant", "Other"]}
                onSelect={(option) => handleChange("type", option)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Type"
                value={referral.type || "N/A"}
              />
            )}
            {isEditable ? (
              <Dropdown
                className="design-component-instance-node"
                label="Accepted payment type"
                placeholder={referral.payment_type}
                options={["Private pay", "Insurance", "Medicare"]}
                onSelect={(option) => handleChange("payment_type", option)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Accepted payment type"
                value={referral.payment_type || "N/A"}
              />
            )}
          </div>
          <div className="div-10">
            {isEditable ? (
              <Dropdown
                className="design-component-instance-node"
                label="Specialization(s)"
                placeholder={referral.specializations}
                options={["OCD", "SUD", "Eating disorder", "Personality disorder", "Trauma"]}
                onSelect={(option) => handleChange("specializations", option)}
                multiselect={true}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Specialization(s)"
                value={referral.specializations || "N/A"}
              />
            )}
          </div>
          <div className="div-10">
            {isEditable ? (
              <Input
                className="input-6"
                label="Rule outs"
                value={referral.rule_out || ""}
                onChange={(e) => handleChange("rule_out", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Rule outs"
                value={referral.rule_out || "N/A"}
              />
            )}
            {isEditable ? (
              <Input
                className="input-6"
                label="Special notes"
                value={referral.special_notes || ""}
                onChange={(e) => handleChange("special_notes", e.target.value)}
              />
            ) : (
              <DetailDataCell
                className="detail-data-cell-v-instance"
                label="Special notes"
                value={referral.special_notes || "N/A"}
              />
            )}
          </div>

          <div className="div-10 info-field detail-data-cell">
            <div className="label">List of contacts</div>
            <span onClick={handleViewAllClick} className="view-all-link">
              View all
            </span>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <ListOfContacts
            onClose={closeModal}
            data={contactData}
            org={referral.referral_name}
            loading={loading}
          />
        </div>
      )}
    </div>
  );
};
