/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const VuesaxBoldProfile2User2 = ({ className }) => {
  return (
    <svg
      className={`vuesax-bold-profile-2user-2 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M11.9999 2.66675C8.50661 2.66675 5.66661 5.50675 5.66661 9.00008C5.66661 12.4267 8.34661 15.2001 11.8399 15.3201C11.9466 15.3067 12.0533 15.3067 12.1333 15.3201C12.1599 15.3201 12.1733 15.3201 12.1999 15.3201C12.2133 15.3201 12.2133 15.3201 12.2266 15.3201C15.6399 15.2001 18.3199 12.4267 18.3333 9.00008C18.3333 5.50675 15.4933 2.66675 11.9999 2.66675Z"
        fill="#2DC8A8"
      />
      <path
        className="path"
        d="M18.7733 18.8667C15.0533 16.3867 8.98661 16.3867 5.23995 18.8667C3.54661 20.0001 2.61328 21.5334 2.61328 23.1734C2.61328 24.8134 3.54661 26.3334 5.22661 27.4534C7.09328 28.7067 9.54661 29.3334 11.9999 29.3334C14.4533 29.3334 16.9066 28.7067 18.7733 27.4534C20.4533 26.3201 21.3866 24.8001 21.3866 23.1467C21.3733 21.5068 20.4533 19.9867 18.7733 18.8667Z"
        fill="#2DC8A8"
      />
      <path
        className="path"
        d="M26.6533 9.78675C26.8666 12.3734 25.0266 14.6401 22.4799 14.9467C22.4666 14.9467 22.4666 14.9467 22.4533 14.9467H22.4133C22.3333 14.9467 22.2533 14.9467 22.1866 14.9734C20.8933 15.0401 19.7066 14.6267 18.8133 13.8667C20.1866 12.6401 20.9733 10.8001 20.8133 8.80008C20.7199 7.72008 20.3466 6.73342 19.7866 5.89341C20.2933 5.64008 20.8799 5.48008 21.4799 5.42675C24.0933 5.20008 26.4266 7.14675 26.6533 9.78675Z"
        fill="#2DC8A8"
      />
      <path
        className="path"
        d="M29.3199 22.1201C29.2133 23.4134 28.3866 24.5334 27 25.2934C25.6666 26.0267 23.9866 26.3734 22.3199 26.3334C23.2799 25.4667 23.8399 24.3867 23.9466 23.2401C24.0799 21.5867 23.2933 20.0001 21.7199 18.7334C20.8266 18.0267 19.7866 17.4667 18.6533 17.0534C21.5999 16.2001 25.3066 16.7734 27.5866 18.6134C28.8133 19.6001 29.4399 20.8401 29.3199 22.1201Z"
        fill="#2DC8A8"
      />
    </svg>
  );
};
