import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LoginPage } from "./screens/LoginPage";
import { ResetPassword } from "./screens/ResetPassword";
import { ForgotPassword } from "./screens/ForgotPassword";
import { TasksList } from "./screens/TasksList";
import { TaskDetail } from "./screens/TaskDetail";
import { PatientsList } from "./screens/PatientsList";
import { PatientDetail } from "./screens/PatientDetail";
import { PatientDemographics } from "./screens/PatientDemographics";
import { OpportunitiesList } from "./screens/OpportunitiesList";
import { PatientPointOfContact } from "./screens/PatientPointOfContact";
import { PatientInsurancePayment } from "./screens/PatientInsurancePayment";
import { OpportunityDetail } from "./screens/OpportunityDetail";
import { OpportunityCurrentConcerns } from "./screens/OpportunityCurrentConcerns";
import { OpportunityHistory } from "./screens/OpportunityHistory";
import { OpportunityDocuments } from "./screens/OpportunityDocuments";
import { OpportunityReferralDetails } from "./screens/OpportunityReferralDetails";
import { OpportunityTreatmentPlan } from "./screens/OpportunityTreatmentPlan";
import { CapacityClinician } from "./screens/CapacityClinician";
import { CapacityFacility } from "./screens/CapacityFacility";
import { CapacityCensus } from "./screens/CapacityCensus";
import { ReferralsContactsList } from "./screens/ReferralsContactsList";
import { ReferralsList } from "./screens/ReferralsList";
import { ContactsList } from "./screens/ContactsList";
import { ContactDetail } from "./screens/ContactDetail";
import { ReferralDetail } from "./screens/ReferralDetail";
import { ReferralInfo } from "./screens/ReferralInfo";
import { ReferralActivity } from "./screens/ReferralActivity";
import { ReferralAnalytics } from "./screens/ReferralAnalytics";
import { CapacityDetail } from "./screens/CapacityDetail";
import { Analytics } from "./screens/Analytics";
import { Configurations } from "./screens/Configurations";
import { Dashboard } from "./screens/Dashboard";
import { Settings } from "./screens/Settings";
import { SettingsAccount } from "./screens/SettingsAccount";
import { testAuth } from "./services/loginApi";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset_password",
    element: <ResetPassword />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/tasks",
    element: <TasksList />,
  },
  {
    path: "/task/:taskId",
    element: <TaskDetail />,
    children: [
      {
        path: "details",
        element: <TaskDetail />,
      },
      {
        path: "",
        element: <TaskDetail />,
      },
    ],
  },
  {
    path: "/patients",
    element: <PatientsList />,
  },
  {
    path: "/patient/:patientId",
    element: <PatientDetail />,
    children: [
      {
        path: "demographics",
        element: <PatientDemographics />,
      },
      {
        path: "point-of-contact",
        element: <PatientPointOfContact />,
      },
      {
        path: "insurance-payment",
        element: <PatientInsurancePayment />,
      },
      {
        path: "",
        element: <PatientDemographics />, // Default route
      },
    ],
  },
  {
    path: "/opportunities",
    element: <OpportunitiesList />,
  },
  {
    path: "/opportunity/:opportunityId",
    element: <OpportunityDetail />,
    children: [
      {
        path: "current-concerns",
        element: <OpportunityCurrentConcerns />,
      },
      {
        path: "history",
        element: <OpportunityHistory />,
      },
      {
        path: "referral-details",
        element: <OpportunityReferralDetails />,
      },
      {
        path: "treatment-plan",
        element: <OpportunityTreatmentPlan />,
      },
      {
        path: "documents",
        element: <OpportunityDocuments />,
      },
      {
        path: "",
        element: <OpportunityCurrentConcerns />,
      },
    ],
  },
  {
    path: "/capacity",
    element: <CapacityDetail />,
    children: [
      {
        path: "census",
        element: <CapacityCensus />,
      },
      {
        path: "capacity-clinician",
        element: <CapacityClinician />,
      },
      {
        path: "capacity-facility",
        element: <CapacityFacility />,
      },
      {
        path: "",
        element: <CapacityCensus />,
      },
    ],
  },
  {
    path: "/outreach",
    element: <ReferralsContactsList />,
    children: [
      {
        path: "organizations",
        element: <ReferralsList />,
      },
      {
        path: "contacts",
        element: <ContactsList />,
      },
      {
        path: "",
        element: <ReferralsList />,
      },
    ],
  },
  {
    path: "/outreach/:referralId",
    element: <ReferralDetail />,
    children: [
      {
        path: "details",
        element: <ReferralInfo />,
      },
      {
        path: "activities",
        element: <ReferralActivity />,
      },
      {
        path: "analytics",
        element: <ReferralAnalytics />,
      },
      {
        path: "",
        element: <ReferralInfo />,
      },
    ],
  },
  {
    path: "/contact/:contactId",
    element: <ContactDetail />,
  },
  {
    path: "/analytics",
    element: <Analytics />,
  },
  {
    path: "/configurations",
    element: <Configurations />,
  },
  {
    path: "/settings",
    element: <Settings />,
    children: [
      {
        path: "account",
        element: <SettingsAccount />,
      },
      {
        path: "",
        element: <SettingsAccount />,
      },
    ],
  },
]);

export const App = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        testAuth();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return <RouterProvider router={router} />;
};
