import React, { useState, useEffect } from "react";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Dropdown } from "../../components/Dropdown";
import { StatusTag } from "../../components/StatusTag";
import { InputWithDatePicker } from "../../components/InputWithDatePicker";
import { VuesaxBoldCloseCircle } from "../../icons/VuesaxBoldCloseCircle";

import "./style.css";

export const ActionModal = ({ message, action, onClose }) => {
  const [moveFrom, setMoveFrom] = useState("");
  const [moveTo, setMoveTo] = useState("");
  const [transitionDate, setTransitionDate] = useState("");
  const [facilities, setFacility] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  return (
    <div className="change-status">
      <div className="title-2">
        <div className="text-wrapper-17">{message}</div>
        <Button
          className="button-2"
          override={<VuesaxBoldCloseCircle className="vuesax-bold-close-circle" color="#7E92A2" />}
          style="white"
          type="icon-only"
          onClick={() => onClose()}
        />
      </div>
      <div className="action-wrapper">
        <div className="action-2">
          <Button
            className="button-7"
            style="primary"
            text="Confirm"
            type="default"
            onClick={() => action()}
          />
        </div>
      </div>
    </div>
  );
};
