/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconPatients = ({ className }) => {
  return (
    <svg
      className={`icon-patients ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7.63301 9.68268C7.60801 9.68268 7.59134 9.68268 7.56634 9.68268C7.52467 9.67435 7.46634 9.67435 7.41634 9.68268C4.99967 9.60768 3.17467 7.70768 3.17467 5.36602C3.17467 2.98268 5.11634 1.04102 7.49968 1.04102C9.88301 1.04102 11.8247 2.98268 11.8247 5.36602C11.8163 7.70768 9.98301 9.60768 7.65801 9.68268C7.64967 9.68268 7.64134 9.68268 7.63301 9.68268ZM7.49968 2.29102C5.80801 2.29102 4.42467 3.67435 4.42467 5.36602C4.42467 7.03268 5.72467 8.37435 7.38301 8.43268C7.43301 8.42435 7.54134 8.42435 7.64968 8.43268C9.28301 8.35768 10.5663 7.01602 10.5747 5.36602C10.5747 3.67435 9.19134 2.29102 7.49968 2.29102Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M13.783 9.79102C13.758 9.79102 13.733 9.79102 13.708 9.78268C13.3663 9.81602 13.0163 9.57435 12.983 9.23268C12.9497 8.89102 13.158 8.58268 13.4997 8.54102C13.5997 8.53268 13.708 8.53268 13.7997 8.53268C15.0163 8.46602 15.9663 7.46602 15.9663 6.24102C15.9663 4.97435 14.9413 3.94935 13.6747 3.94935C13.333 3.95768 13.0497 3.67435 13.0497 3.33268C13.0497 2.99102 13.333 2.70768 13.6747 2.70768C15.6247 2.70768 17.2163 4.29935 17.2163 6.24935C17.2163 8.16602 15.7163 9.71602 13.808 9.79102C13.7997 9.79102 13.7913 9.79102 13.783 9.79102Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M7.64134 18.791C6.00801 18.791 4.36634 18.3743 3.12467 17.541C1.96634 16.7743 1.33301 15.7243 1.33301 14.5827C1.33301 13.441 1.96634 12.3827 3.12467 11.6077C5.62468 9.94935 9.67468 9.94935 12.158 11.6077C13.308 12.3743 13.9497 13.4243 13.9497 14.566C13.9497 15.7077 13.3163 16.766 12.158 17.541C10.908 18.3743 9.27468 18.791 7.64134 18.791ZM3.81634 12.6577C3.01634 13.191 2.58301 13.8743 2.58301 14.591C2.58301 15.2993 3.02467 15.9827 3.81634 16.5077C5.89134 17.8993 9.39134 17.8993 11.4663 16.5077C12.2663 15.9743 12.6997 15.291 12.6997 14.5743C12.6997 13.866 12.258 13.1827 11.4663 12.6577C9.39134 11.2743 5.89134 11.2743 3.81634 12.6577Z"
        fill="#7E92A2"
      />
      <path
        className="path"
        d="M15.283 17.291C14.9913 17.291 14.733 17.091 14.6747 16.791C14.608 16.4493 14.8247 16.1243 15.158 16.0493C15.683 15.941 16.1663 15.7327 16.5413 15.441C17.0163 15.0827 17.2747 14.6327 17.2747 14.1577C17.2747 13.6827 17.0163 13.2327 16.5497 12.8827C16.183 12.5993 15.7247 12.3993 15.183 12.2743C14.8497 12.1993 14.633 11.866 14.708 11.5243C14.783 11.191 15.1163 10.9743 15.458 11.0493C16.1747 11.2077 16.7997 11.491 17.308 11.8827C18.083 12.466 18.5247 13.291 18.5247 14.1577C18.5247 15.0243 18.0747 15.8493 17.2997 16.441C16.783 16.841 16.133 17.1327 15.4163 17.2743C15.3663 17.291 15.3247 17.291 15.283 17.291Z"
        fill="#7E92A2"
      />
    </svg>
  );
};
