import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";
import { Button } from "../Button";
import { IconFilterAdd } from "../../icons/IconFilterAdd";
import { VuesaxOutlineArrowDown } from "../../icons/VuesaxOutlineArrowDown";
import { IconFilterAdd3 } from "../../icons/IconFilterAdd3";
import { VuesaxOutlineArrowRight } from "../../icons/VuesaxOutlineArrowRight";
import "./style.css";

// Debounce helper function
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

export const InfoAndFilters = ({
  className,
  text,
  text1 = "opportunities",
  hasCreatedInFilter = true,
  hasDiv = true,
  onFilterClick,
  onSearch,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const debouncedSearch = useCallback(
    debounce((term) => {
      onSearch(term);
    }, 300), [onSearch]
  );

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  return (
    <div className={`info-and-filters ${className}`}>
      <div className="total-deals">
        <div className="text-wrapper-2">Total:</div>
        <div className="text-wrapper-2">{text}</div>
        <div className="text-wrapper-2">{text1}</div>
      </div>
      <div className="filter-group">
        <div className="filter search-filter">
          <IconFilterAdd className="icon-filter-add" color="#7E92A2" />
          <input
            type="text"
            className="search-input"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleInputChange}
            style={{ border: 'none', outline: 'none', background: 'transparent' }}
          />
        </div>

        {false && (
        <div className="filter-container">
          <Button
            className="button-instance"
            override={<IconFilterAdd3 className="icon-2" color="#7E92A2" />}
            style="white"
            text="Filter"
            type="right-icon"
            onClick={toggleFilterDropdown}
          />

          {isFilterOpen && (
            <div className="filter-menu-opened">
              <div className="dropdown-items">
                {/* Status with Sub-Menu */}
                <div className="sort-by has-submenu">
                  <div className="text-wrapper-9">Status</div>
                  <VuesaxOutlineArrowRight className="icon-instance-node-4" color="#7E92A2" />
                  <div className="submenu">
                    <div className="submenu-item">Pending</div>
                    <div className="submenu-item">Admin</div>
                    <div className="submenu-item">Scheduled-Admit</div>
                    <div className="submenu-item">Admitted</div>
                    <div className="submenu-item">Dropped</div>
                    <div className="submenu-item">Discharged</div>
                  </div>
                </div>

                {/* Other Options */}
                <div className="sort-by has-submenu">
                  <div className="text-wrapper-9">Facility</div>
                  <VuesaxOutlineArrowRight className="icon-instance-node-4" color="#7E92A2" />
                  <div className="submenu">
                    <div className="submenu-item">William</div>
                    <div className="submenu-item">Sibcy</div>
                  </div>
                </div>
                <div className="sort-by has-submenu">
                  <div className="text-wrapper-9">Staff</div>
                  <VuesaxOutlineArrowRight className="icon-instance-node-4" color="#7E92A2" />
                   <div className="submenu">
                    <div className="submenu-item">Reigin</div>
                    <div className="submenu-item">James</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        )}
        {false && (
        <Button
          className="button-instance"
          override={<VuesaxOutlineArrowDown className="icon-2" color="#7E92A2" />}
          style="white"
          text="Sort by: Date Created"
          type="right-icon"
        />
        )}
      </div>
    </div>
  );
};

InfoAndFilters.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  hasCreatedInFilter: PropTypes.bool,
  hasDiv: PropTypes.bool,
  onFilterClick: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
};
